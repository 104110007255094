/* eslint-disable no-underscore-dangle */
import client from '../../../api';

export default {
  sites: (config) => client.get('/roi_reports/site_list', config),
  regenerateReport: (site, config) =>
    client.post(`/instances/${site.instance_id}/sites/${site.id}/roi_report/regenerate`, {}, config),
  regenerateAll: () => client.post('/roi_reports/regenerate_all'),
  send: (params) => client.post('/roi_reports/send_all', params),
  pullFromMYOB: (site) => client.post(`/instances/${site.instance_id}/sites/${site.id}/resync_myob`),
};
