import SectorApi from 'dashboard/src/api/sector';
import { displaySuccess } from '../../../../../Utils';

export const addSectorAction = (name, bgColor, id, newSectorData, instanceId) => async () => {
  const sendParams = {
    instance_id: instanceId,
    label: name,
    background: bgColor,
    template_id: id,
  };
  const response = await SectorApi.sectorCreate(sendParams);
  if (undefined !== response) {
    const { data } = response;
    displaySuccess(data.messages);
    newSectorData(data.data);
  }
};

export const updateSectorAction = (params) => async () => {
  const response = await SectorApi.sectorUpdate(params);
  if (undefined !== response) {
    const { data } = response;
    displaySuccess(data.messages);
  }
};

export const deleteSectorAction = (id, updatedSectorData, instanceId) => async () => {
  const sendParams = {
    id,
    instance_id: instanceId,
  };
  const response = await SectorApi.sectorDelete(sendParams);
  if (undefined !== response) {
    const { data } = response;
    updatedSectorData(id);
    displaySuccess(data.messages);
  }
};

export const sectorPositionUpdate = (params) => async () => {
  const response = await SectorApi.sectorPositionUpdate(params);
  if (undefined !== response) {
    const { messages } = response.data;
    displaySuccess(messages);
  }
};
