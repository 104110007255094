import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { isEmpty, get } from 'lodash-es';
import { DateTime } from 'luxon';
import { getCTALogs } from '../../redux/ctaReports/actions';
import TimeFrameSelector from '../../components/common/timeFrameSelector';
import withRouter from '../../helpers/withRouter';
import CTATable from './ctaTable';
import { useInstance, useUser } from '../../hooks';
// import './index.scss';

function CtaReport(props) {
  const {
    ctaType,
    typeName,
    orderBy,
    orderDirection,
    dataTableColumns,
    subRow,
  } = props;
  const dispatch = useDispatch();
  const [pageSize, setPageSize] = useState(10);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [siteId, setSiteId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  // const [getPagination, setPagination] = useState([]);
  const [includeSpam, setIncludeSpam] = useState(false);

  const instance = useInstance();
  const instanceId = instance?.id || null;

  const {
    isDeleted,
    CTAReportChatData,
    CTAReportChatPagination,
    CTAReportFormData,
    CTAReportFormPagination,
    CTAReportReviewData,
    CTAReportReviewPagination,
    CTALogIncomingCallData,
    CTALogIncomingCallPagination,
    CTAReportNegativeReviewData,
    CTAReportNegativeReviewPagination,
  } = useSelector((state) => state.adminStore.CTAReportsReducer);

  const user = useUser();

  const timeZone = get(user, 'time_zone', 'Pacific/Auckland');

  const onHandleTimeFrameChange = (dates) => {
    setStartDate(dates.startDt);
    setEndDate(dates.endDt);
  };

  useEffect(() => {
    if (!instanceId) {
      return;
    }

    (async () => {
      if (!isEmpty(startDate) && !isEmpty(endDate)) {
        let sendParams = {
          instance_id: instanceId,
          types: [ctaType],
          start_date: DateTime.fromISO(startDate, { zone: timeZone }),
          end_date: endDate,
          site_id: siteId,
          page: currentPage,
          page_size: pageSize,
          order_by: orderBy,
          order_direction: orderDirection,
        };

        if (['CTALogFormSubmission'].includes(ctaType)) {
          sendParams = { ...sendParams, include_spam: includeSpam };
        }

        await dispatch(getCTALogs(sendParams));
      }
    })();
  }, [instanceId, startDate, endDate, siteId, orderBy, orderDirection, pageSize, currentPage, includeSpam, isDeleted]);

  const onHandleSiteChange = (e) => {
    setSiteId(e ? e.value : e);
  };

  const onChangePage = (e) => {
    setCurrentPage(e);
  };

  const changePageSize = (size) => {
    setPageSize(size);
  };

  const onHandleIncludeSpam = () => {
    setIncludeSpam(!includeSpam);
  };

  const getCTALogData = () => {
    switch (ctaType) {
      case 'CTALogChat':
        return CTAReportChatData;
      case 'CTALogReview':
        return CTAReportReviewData;
      case 'CTALogNegativeReview':
        return CTAReportNegativeReviewData;
      case 'CTALogIncomingCall':
        return CTALogIncomingCallData;
      default: return CTAReportFormData;
    }
  };

  const getCTAPagination = () => {
    switch (ctaType) {
      case 'CTALogChat':
        return CTAReportChatPagination;
      case 'CTALogReview':
        return CTAReportReviewPagination;
      case 'CTALogNegativeReview':
        return CTAReportNegativeReviewPagination;
      case 'CTALogIncomingCall':
        return CTALogIncomingCallPagination;
      default: return CTAReportFormPagination;
    }
  };

  return (
    <>
      <TimeFrameSelector
        ctaType={ctaType}
        typeName={typeName}
        CTALogs={getCTALogData()}
        pageSize={pageSize}
        currentPage={currentPage}
        includeSpam={includeSpam}
        onChangePage={onChangePage}
        getPagination={getCTAPagination() || {}}
        changePageSize={changePageSize}
        onHandleSiteChange={onHandleSiteChange}
        onHandleSubmit={onHandleTimeFrameChange}
        onHandleIncludeSpam={onHandleIncludeSpam}
      />
      <CTATable
        subRow={subRow}
        data={getCTALogData()}
        showColumnListTh
        columns={dataTableColumns}
      />
    </>
  );
}

export default withRouter(CtaReport);
