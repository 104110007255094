import React, { useState } from 'react';
import {
  Card, CardBody, Input, Button, FormGroup,
} from 'reactstrap';
import client from 'api';
import UserFeaturePage from 'dashboard/src/components/common/user-feature-page';
import HeaderComponent from '../../components/common/header-component';
import { displaySuccess, displayError } from 'Utils';

function UnsubscribeFromAll() {
  const [email, setEmail] = useState('');

  return (
    <UserFeaturePage feature="unsubscribe_from_all_tool">
      <HeaderComponent setPath={{
        headingName: 'Unsubscribe From All',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Back',
        showBreadcrumb: false,
      }}
      />
      <Card>
        <CardBody>
          <FormGroup>
            <Input
              type="email"
              placeholder="Email address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </FormGroup>
          <Button
            color="primary"
            onClick={async () => {
              const result = await client.post(
                '/unsubscribe_from_all',
                { email },
              );

              const { data: { is_success: isSuccess, data: count, messages } } = result;

              if (isSuccess && count > 0) {
                displaySuccess(messages);
              } else if (isSuccess && count === 0) {
                displayError('Member not found.');
              } else {
                displayError('Error, please try again.');
              }
            }}
          >
            Unsubscribe
          </Button>
        </CardBody>
      </Card>
    </UserFeaturePage>
  );
}

export default UnsubscribeFromAll;
