import { get } from 'lodash-es';
import React from 'react';
import { Input, Label, FormGroup } from 'reactstrap';

function SiteDisplayName({params, setParams}) {
  return (
    <FormGroup>
      <Label for="display_name">Display Name</Label>
      <Input
        type="text"
        name="display_name"
        id="display_name"
        value={get(params, 'display_name', '')}
        onChange={(e) => setParams({ ...params, display_name: e.target.value })}
      />
    </FormGroup>
  );
}

export default SiteDisplayName;
