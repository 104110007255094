/* eslint-disable react/prefer-stateless-function */
import React, { useState, useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import withRouter from '../helpers/withRouter';
import TopNav from '../containers/navs/Topnav';
import Sidebar from '../containers/navs/Sidebar';
import BigNav from '../containers/navs/BigNav';

function AppLayout(props) {
  const { history, children } = props;
  const [mainShowtemporary, setMainShowTemporary] = useState(false);
  const [newVersion, setNewVersion] = useState(false);
  const { menuHidden } = useSelector((state) => state.adminStore.sidebarReducer);
  const searchText = useSelector((s) => s.adminStore.searchTextReducer.text);
  const menuRef = useRef('');

  useEffect(() => {
    if (!window.wg?.jsChunks) {
      return;
    }

    // Check for a new version of the dashboard on the server and display an alert if there is one. This relies on the
    // path and format of the compiled JS bundle file.
    const checkForNewVersion = () => {
      window.wg.jsChunks.forEach((c) => {
        fetch(c).then((response) => {
          if (response.status === 404) {
            setNewVersion(true);
          }
        });
      });
    };

    clearInterval(window.checkForNewVersionInterval);
    window.checkForNewVersionInterval = setInterval(checkForNewVersion, 15000);
  }, []);

  return (
    <div
      id="app-container"
      className={`
        app-layout
        ${menuHidden ? 'menu-hidden' : ''}
        ${mainShowtemporary ? 'main-show-temporary' : ''}
        ${searchText ? 'search-box-open' : ''}
      `}
    >
      {newVersion && (
        <div style={{
          position: 'fixed',
          zIndex: 1040,
          top: 0,
          width: '100%',
          padding: '12px',
          backgroundColor: '#cc3333',
          fontWeight: 700,
          color: 'white',
        }}>
          There is a new version of the dashboard available —
          {' '}
          <a
            href="#"
            style={{ color: 'white' }}
            onClick={() => window.location.reload()}
          >
            Reload now
          </a>
        </div>
      )}
      <TopNav
        history={history}
        setMainShowTemporary={setMainShowTemporary}
        menuRef={menuRef}
        props={props}
      />
      <Sidebar
        setMainShowTemporary={setMainShowTemporary}
        menuRef={menuRef}
        mainShowtemporary={mainShowtemporary}
      />
      {searchText && (
        <BigNav
          props={props}
        />
      )}
      <main className="container-spinner">
        <div className="container-fluid">
          {children}
        </div>
      </main>
    </div>
  );
}

export default withRouter(AppLayout);
