import React, { useState } from 'react';
import {
  UncontrolledDropdown,
  DropdownItem,
  DropdownToggle,
  DropdownMenu,
  Alert,
  InputGroup,
  Input,
  InputGroupText,
} from 'reactstrap';
import { NavLink } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  settingRoutes, mainRoutes, replacePathParams, userRoutes,
} from '../../constants/routes';
import { isEmpty } from 'lodash-es';
import hideSideBar from '../../redux/sideBar/actions';
import setSearchText from '../../redux/searchText/actions';
import withRouter from '../../helpers/withRouter';
import { useLogoutUserMutation } from '../../api/apiSlice';
import { searchPath } from '../../constants/defaultValues';
import TopnavDarkSwitch from './Topnav.DarkSwitch';
import InstanceSelection from './InstanceSelection';
import SiteSelection from './SiteSelection';
import UserPresence from './UserPresence';
import './index.scss';
import CustomTooltip from '../../components/common/CustomTooltip';
import { useUser } from '../../hooks';

const isInFullScreen = () => {
  if ((document.fullscreenElement && document.fullscreenElement !== null)
  || (document.webkitFullscreenElement
    && document.webkitFullscreenElement !== null)
  || (document.mozFullScreenElement
    && document.mozFullScreenElement !== null)
  || (document.msFullscreenElement && document.msFullscreenElement !== null)) {
    return true;
  }
  return false;
};

const TopNav = (props) => {
  const {
    menuRef,
    setMainShowTemporary,
  } = props;

  const user = useUser();

  const [logoutUser] = useLogoutUserMutation();

  const [fullScreenMode, setFullScreenMode] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState(false);

  const { menuHidden } = useSelector((state) => state.adminStore.sidebarReducer);
  const { instance } = useSelector((state) => state.adminStore.instanceReducer);
  const { containerClassnames, menuClickCount } = useSelector((state) => state.adminStore.menu);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const searchText = useSelector((s) => s.adminStore.searchTextReducer.text);

  const removeEventsSearch = () => {
    document.removeEventListener('click', handleDocumentClickSearch, true);
  };

  const handleDocumentClickSearch = (e) => {
    let isSearchClick = false;
    if (
      e.target
      && e.target.classList
      && (e.target.classList.contains('navbar')
        || e.target.classList.contains('fal fa-search'))
    ) {
      isSearchClick = true;
      if (e.target.classList.contains('fal fa-search')) {
        search();
      }
    } else if (
      e.target.parentElement
      && e.target.parentElement.classList
      && e.target.parentElement.classList.contains('search')
    ) {
      isSearchClick = true;
    }

    if (!isSearchClick) {
      const input = document.querySelector('.mobile-view');
      if (input && input.classList) input.classList.remove('mobile-view');
      removeEventsSearch();
      setSearchKeyword('');
    }
  };

  const search = () => {
    navigate(`${searchPath}/${searchKeyword}`);
    setSearchKeyword('');
  };

  const toggleFullScreen = () => {
    const docElm = document.documentElement;
    if (!isInFullScreen()) {
      if (docElm.requestFullscreen) {
        docElm.requestFullscreen();
      } else if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      } else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      } else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
    setFullScreenMode(!fullScreenMode);
  };

  const handleLogout = async () => {
    const scriptTag = document.getElementById('google-signin-script');
    if (scriptTag) {
      document.head.removeChild(scriptTag);
    }
    await logoutUser();
    window.location.href = '/';
  };

  const menuButtonClick = (e) => {
    e.preventDefault();
    dispatch(hideSideBar(!menuHidden));
  };

  const mobileMenuButtonClick = (e) => {
    e.preventDefault();
    const sidebarContainer = document.querySelector('.sidebar');
    if (sidebarContainer.classList.contains('menu-mobile')) {
      setMainShowTemporary((prev) => !prev);
    }
  };

  const redirectTo = (path) => {
    navigate(replacePathParams(path, [], props));
  };

  return (
    <>
      <nav className="navbar fixed-top">
        <div className="d-flex align-items-center navbar-left">
          <div className="me-3 me-md-0" ref={menuRef}>
            <NavLink
              to="#"
              className="menu-button d-none d-md-block"
              onClick={(e) => menuButtonClick(e, menuClickCount, containerClassnames)}
            >
              <i style={{ fontSize: '1.5em' }} className="fal fa-bars" />
            </NavLink>
            <NavLink
              to="#"
              className="menu-button-mobile d-xs-block d-sm-block d-md-none"
              onClick={(e) => mobileMenuButtonClick(e, containerClassnames)}
            >
              <i className="fal fa-bars" />
            </NavLink>
          </div>

          <a
            className="navbar-logo"
            href={replacePathParams(settingRoutes.dashboard, [], props)}
          >
            <div className="logo" />
          </a>

          <div className="instance-selection-cont ms-5">
            <InstanceSelection />
            <SiteSelection />
          </div>
        </div>

        <div className="navbar-right">
          <UserPresence />
          {!isEmpty(instance) && (
            <>
              {instance.platform_status === 'old' && (
                <Alert color="danger" className="d-inline-block me-3 mb-0">
                  <span className="d-none d-xxl-inline-block">
                    ON LEGACY PLATFORM
                  </span>
                  <span className="d-xxl-none">
                    <i className="fal fa-tombstone" />
                  </span>
                </Alert>
              )}
              {instance.platform_status === 'transitioning' && (
                <Alert color="warning" className="d-inline-block me-3 mb-0">
                  <span className="d-none d-xxl-inline-block">
                    TRANSITIONING TO NEW PLATFORM
                  </span>
                  <span className="d-xxl-none">
                    <i className="fal fa-arrow-progress" />
                  </span>
                </Alert>
              )}
            </>
          )}
          <div className="d-inline-block me-3">
            <InputGroup>
              <Input
                value={searchText}
                onChange={(e) => {
                  dispatch(setSearchText(e.target.value));
                }}
              />
              <InputGroupText>
                <i className="fal fa-magnifying-glass" />
              </InputGroupText>
            </InputGroup>
          </div>
          <TopnavDarkSwitch />
          <CustomTooltip name="TopNavFullScreen">
            <button
              className="header-icon btn btn-empty d-sm-inline-block"
              type="button"
              id="fullScreenButton"
              onClick={toggleFullScreen}
            >
              {fullScreenMode ? (
                <i className="fal fa-compress-arrows-alt d-block" />
              ) : (
                <i className="fal fa-arrows-alt d-block" />
              )}
            </button>
          </CustomTooltip>
          <button
            type="button"
            className="header-icon btn btn-empty d-sm-inline-block me-3"
            onClick={() => redirectTo(mainRoutes.setting)}
          >
            <i className="fal fa-cogs" />
          </button>
          <div className="user d-inline-block align-middle">
            <UncontrolledDropdown className="dropdown-menu-right ">
              <DropdownToggle className="p-0 header-icon" color="empty">
                <i className="fal fa-user-circle" />
              </DropdownToggle>
              <DropdownMenu className="mt-3" end>
                <DropdownItem
                  tag="a"
                  href={replacePathParams(userRoutes.userProfile, [
                    { key: 'userId', value: user?.id },
                  ], props)}
                >
                  Account
                  {' '}
                  <span>{user && `(${user.full_name})`}</span>
                </DropdownItem>
                <DropdownItem
                  tag="a"
                  href={replacePathParams(mainRoutes.qrCode, [], props)}
                >
                  Log in with another device
                </DropdownItem>
                <DropdownItem divider className="d-block" />
                <DropdownItem className="d-block" onClick={handleLogout}>
                  Log out
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
      </nav>
    </>
  );
};

export default withRouter(TopNav);
