import React from 'react';
import Select from 'react-select';
import {
  getDataFieldOptions, getSelectedOption, getSpecialFieldIdByName, selectColorStyles
} from '../../../../../Utils';
import { isEmpty } from 'lodash-es';
import PropTypes from 'prop-types';
import { useGetDatabaseFieldsQuery } from '../../../api/apiSlice';

function DataFieldSelectBox({
  onChange,
  className,
  instanceId,
  selectedId,
  placeholder,
  defaultValue,
}) {
  const { data: dataFieldSelectOptions } = useGetDatabaseFieldsQuery({ instanceId }, { skip: isEmpty(instanceId) });

  return (
    dataFieldSelectOptions
    && (
      <Select
        value={
          getSelectedOption(
            getDataFieldOptions(dataFieldSelectOptions),
            defaultValue && isEmpty(selectedId) ? getSpecialFieldIdByName(
              getDataFieldOptions(dataFieldSelectOptions),
              'Join Date',
            ) : selectedId,
          )
        }
        placeholder={placeholder}
        options={getDataFieldOptions(dataFieldSelectOptions)}
        onChange={onChange}
        className={className}
        styles={selectColorStyles}
      />
    )
  );
}

DataFieldSelectBox.propTypes = {
  defaultValue: PropTypes.bool,
  className: PropTypes.string,
  selectedId: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  instanceId: PropTypes.string.isRequired,
};

DataFieldSelectBox.defaultProps = {
  className: '',
  selectedId: null,
  placeholder: 'Select',
  defaultValue: false,
};

export default DataFieldSelectBox;
