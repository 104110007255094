import React from 'react';
import HeaderComponent from '../../components/common/header-component';
import { replacePathParams, settingRoutes, deliveryCenterRoutes } from '../../constants/routes';
import withRouter from '../../helpers/withRouter';
import { Fragment, useEffect, useState } from 'react';
import { Card, CardBody, Button, Form, Row, Col, Label, Input, Badge } from 'reactstrap';
import { Wizard, Steps, Step } from 'react-albus';
import WizardTopNavigation from '../../components/common/wizardNavigations/topNavigation';
import CustomSelect from '../../components/common/custom-select';
import { displayError, getSelectedOption, selectBoxOptions, validateEmail } from '../../../../Utils';
import { useDispatch, useSelector } from 'react-redux';
import { savedSearchesGet } from '../../redux/dataCenter/actions';
import { get, isEmpty } from 'lodash-es';
import RadioButton from '../../components/common/radioButton';
import DatePicker from 'react-datepicker';
import confirm from '../../components/common/confirm';
import BulkEmailAPI from '../../api/deliveryCenter';
import MemberSearchApi from '../../api/member_search';
import { useUser } from '../../hooks';
import { useGetEmailsQuery } from '../../api/apiSlice';
// import './BulkEmail.scss';

const initialState = {
  subject: '',
  delay: null,
  from_name: '',
  send_now: true,
  from_email: '',
  canceled: false,
  search_id: null,
  page_email_id: null,
};

function BulkEmail(props) {
  const { params: { instanceId }, navigate} = props;
  const dispatch = useDispatch();
  const [currentStep, setStep] = useState(null);
  const [memberCount, setMemberCount] = useState(null);
  const [params, setParams] = useState(initialState);
  const { site } = useSelector((state) => state.adminStore.sitesReducer);
  const { instance } = useSelector((state) => state.adminStore.instanceReducer);
  const { savedSearchesData } = useSelector((state) => state.adminStore.dataCenterReducer);
  const { data: emailPages = [] } = useGetEmailsQuery({ instanceId }, { skip: isEmpty(instanceId)});

  const user = useUser();

  const displayName = get(site, 'display_name', '');
  const companyName = get(instance, 'company_name', '');
  const emailPageId = get(params, 'page_email_id', null);
  const subject = get(params, 'subject', '');
  const fromName = get(params, 'from_name', '');
  const fromEmail = get(params, 'from_email', '');
  const searchId = get(params, 'search_id', null);
  const sendLaterDate = get(params, 'delay', null);
  const sendNowOrLater = get(params, 'send_now', true);
  const getDefaultSubject = emailPages.filter((page) => page.id === emailPageId);

  useEffect(() => {
    dispatch(savedSearchesGet(instanceId));
  }, [dispatch, instanceId]);

  useEffect(() => {
    if (displayName || companyName || !isEmpty(emailPages) || !isEmpty(user) ) {
      setParams({
        ...params,
        from_name: displayName || companyName,
        subject: !isEmpty(getDefaultSubject) ?
          getDefaultSubject[0].page_version.default_subject || getDefaultSubject[0].page_version.name : '',
        from_email: user?.email,
      });
    }
  }, [displayName, companyName, emailPages, user, emailPageId]);

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    {
      name: 'Delivery Centre',
      url: replacePathParams(deliveryCenterRoutes.deliveryCenter, [], props),
    },
    { name: 'Bulk Email', url: '' },
  ];

  const onHandleSubmit = async (goToNext, steps, step) => {
    switch (step.id) {
    case 'recipients':
      if (memberCount === 0) {
        displayError('There are no members in this saved search.');
      } else {
        step.isDone = true;
        if (steps.length - 1 <= steps.indexOf(step)) {
          return;
        }
        goToNext();
      }
      break;
    case 'message':
      if (!emailPageId) {
        displayError('Please select the message to send.');
      } else {
        step.isDone = true;
        if (steps.length - 1 <= steps.indexOf(step)) {
          return;
        }
        goToNext();
      }
      break;
    case 'message-details':
      if (fromName === '') {
        displayError('Please enter from name.');
      } else if (fromEmail === '' || !validateEmail(fromEmail)) {
        displayError('Please enter a valid from email address.');
      } else if (subject === '') {
        displayError('Please enter a subject.');
      } else {
        step.isDone = true;
        if (steps.length - 1 <= steps.indexOf(step)) {
          return;
        }
        goToNext();
      }
      break;
    default:
      step.isDone = true;
      if (steps.length - 1 <= steps.indexOf(step)) {
        return;
      }
      goToNext();
      break;
    }
  };

  const onHandleConfirm = async (push) => {
    if (!sendNowOrLater && !sendLaterDate) {
      displayError('Please select a date.');
    } else {
      const result = await confirm({
        title: <b>Confirm!</b>,
        message: `Are you sure you want to send the email ${getSelectedOption(
          selectBoxOptions(emailPages, 'name', 'id'),
          emailPageId)?.label} to ${memberCount} members?`,
        confirmText: 'Confirm',
        confirmColor: 'danger',
        cancelColor: 'success',
        cancelText: 'No',
      });

      if (result) {
        const response = await BulkEmailAPI.bulkEmail({...params, instance_id: instanceId });
        if (undefined !== response) {
          setStep(null);
          setMemberCount(null);
          setParams(initialState);
          push('recipients');
          navigate(replacePathParams(settingRoutes.backgroundJobs, [
            { key: 'pageNo', value: '1' },
          ], props));
        }
      }
    }
  };

  const topNavClick = (stepItem, push) => {
    push(stepItem.id);
  };

  const onClickPrev = (goToPrev, steps, step) => {
    if (steps.indexOf(step) <= 0) {
      return;
    }
    setStep(step.id - 1);
    goToPrev();
  };

  const backButton = (previous, step, steps) => {
    if (currentStep >= '2') {
      return '';
    }

    return (
      <Button
        color="primary"
        className="me-1"
        disabled={steps.indexOf(step) <= 0}
        onClick={() => { onClickPrev(previous, steps, step); }}
      >
        Back
      </Button>
    );
  };

  const nextButton = (next, step, steps, push) => {
    const lastStep = steps.indexOf(step) >= steps.length - 1;
    return (
      <Button
        color="primary"
        onClick={() => lastStep ? onHandleConfirm(push) : onHandleSubmit(next, steps, step)}
      >
        {lastStep ? 'Submit' : 'Next'}
      </Button>
    );
  };

  const bottomNavigation = (previous, next, step, steps, push) => (
    <div className="text-center mt-4">
      {backButton(previous, step, steps, push)}
      {nextButton(next, step, steps, push)}
    </div>
  );

  const onHandleRecipientsChange = async (e) => {
    const { value } = e;
    setParams({ ...params, search_id: value });
    const response = await MemberSearchApi.memberSearchShow({ instance_id: instanceId, id: value });
    if (undefined !== response) {
      const { data: { data: { pagination: { count }}} } = response;
      setMemberCount(count);
    }
  };

  const savedSearchesWithAllMembers = [{label: 'All members', value: null}].concat(savedSearchesData);
  const today = new Date();
  const sendLaterDateIsToday = sendLaterDate && sendLaterDate.getFullYear() == today.getFullYear() && sendLaterDate.getMonth() === today.getMonth() && sendLaterDate.getDate() === today.getDate();

  return (
    <Fragment>
      <HeaderComponent setPath={{
        addNewPath: '',
        addNewBtnName: '',
        staticBreadcrumbData,
        showBreadcrumb: false,
        backBtnName: 'Dashboard',
        headingName: 'Bulk Email',
        backToPath: replacePathParams(deliveryCenterRoutes.deliveryCenter, [], props),
      }}
      />
      <Card className="BulkEmail mb-4">
        <CardBody className="Wizard">
          <Wizard>
            <WizardTopNavigation disableNav topNavClick={topNavClick} className="justify-content-center" />
            <Steps>
              <Step
                id="recipients"
                name="Select recipients"
                render={({
                  next, previous, step, steps, push,
                }) => (
                  <>
                    <Form>
                      <Row className="mb-3">
                        <Col xxl="4">
                          <Label className="w-100">
                            Select the saved search to send the email to:
                            <CustomSelect
                              value={getSelectedOption(savedSearchesWithAllMembers, searchId)}
                              onChange={onHandleRecipientsChange}
                              options={savedSearchesWithAllMembers}
                            />
                          </Label>
                        </Col>
                      </Row>
                    </Form>
                    {bottomNavigation(previous, next, step, steps, push)}
                  </>
                )}
              />
              <Step
                id="message"
                name="Select the message to send"
                render={({
                  next, previous, step, steps, push,
                }) => (
                  <>
                    <Form>
                      <Row className="mb-3">
                        <Col xxl="4">
                          <Label className="w-100">
                            Select the email to send:
                            <CustomSelect
                              value={getSelectedOption(selectBoxOptions(emailPages, 'name', 'id'), emailPageId)}
                              onChange={(e) => setParams({ ...params, page_email_id: e.value }) }
                              options={selectBoxOptions(emailPages, 'name', 'id')}
                            />
                          </Label>
                        </Col>
                      </Row>
                    </Form>
                    {bottomNavigation(previous, next, step, steps, push)}
                  </>
                )}
              />
              <Step
                id="message-details"
                name="Message details"
                render={({
                  next, previous, step, steps, push,
                }) => (
                  <>
                    <Form>
                      <Row className="mb-3">
                        <Col xxl="4">
                          <Label className="w-100">
                            From name
                            <Input
                              type="text"
                              name="from_name"
                              value={fromName}
                              onChange={(e) => setParams({...params, from_name: e.target.value})}
                            />
                          </Label>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col xxl="4">
                          <Label className="w-100">
                            From email address
                            <Input
                              type="text"
                              name="from_email"
                              value={fromEmail}
                              onChange={(e) => setParams({...params, from_email: e.target.value})}
                            />
                          </Label>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col xxl="4">
                          <Label className="w-100">
                            Subject
                            <Input
                              type="text"
                              name="subject"
                              value={subject}
                              onChange={(e) => setParams({...params, subject: e.target.value})}
                            />
                          </Label>
                        </Col>
                      </Row>
                    </Form>
                    {bottomNavigation(previous, next, step, steps, push)}
                  </>
                )}
              />
              <Step
                id="send"
                name="Send now or later"
                render={({
                  next, previous, step, steps, push,
                }) => (
                  <>
                    <Row className="mb-3">
                      <Col xxl="4">
                        <Label className="w-100">
                          Send now, or send later?
                        </Label>

                        <RadioButton
                          display="block"
                          title="Send now"
                          checked={sendNowOrLater}
                          onHandleChange={() => setParams({...params, send_now: true, delay: null})}
                        />
                        <RadioButton
                          display="block"
                          title="Send later"
                          checked={!sendNowOrLater}
                          onHandleChange={() => setParams({...params, send_now: false})}
                        />
                        {!sendNowOrLater&& (
                          <>
                            <DatePicker.default
                              minDate={new Date()}
                              minTime={(sendLaterDateIsToday || !sendLaterDate) ? new Date() : new Date(2000, 0, 1, 0, 0, 0)}
                              maxTime={new Date(10000, 0, 1, 23, 59, 59)}
                              showYearDropdown
                              showTimeSelect
                              dropdownMode="scroll"
                              scrollableYearDropdown
                              placeholderText="Select date"
                              dateFormat="MMMM d, yyyy h:mm aa"
                              selected={sendLaterDate}
                              onChange={(e) => setParams({...params, delay: e})}
                            />
                            <Badge color='warning' className="text-danger font-size-15px mt-4">
                              Note: While every effort will be made to send this email at the specified time, if the system is under heavy load it may be delayed.
                            </Badge>
                          </>
                        )}
                      </Col>
                    </Row>
                    {bottomNavigation(previous, next, step, steps, push)}
                  </>
                )}
              />
            </Steps>
          </Wizard>
        </CardBody>
      </Card>
    </Fragment>
  );
}

export default withRouter(BulkEmail);
