import React, { useState } from 'react';
import { isEmpty } from 'lodash-es';
import ContentEditor from '../ContentType';
import ColumnType from '../ColumnType';
import FormSection from '../Form';
import ImageGallery from '../ImageGallery';
import MirrorSection from '../Mirror';
import Component from '../Component';
import ReviewList from '../ReviewList';
import withRouter from '../../../../helpers/withRouter';

export const waitForSave = async () => new Promise((resolve) => {
  (function wait() {
    if (window.sectionSaved) {
      return resolve();
    }
    setTimeout(wait, 100);
    return null;
  }());
});

function SectionEdit(props) {
  const { section, params: { pageType }, refetchPageData } = props;

  let submitCounter, _setSubmitCounter;
  [submitCounter, _setSubmitCounter] = useState(0);

  if (typeof props.submitCounter !== 'undefined') {
    submitCounter = props.submitCounter;
  }

  const sectionType = section?.type;

  const isEmailPage = pageType === 'email';

  if (isEmpty(sectionType)) {
    return <></>;
  }

  const postSave = () => {
    window.sectionSaved = true;
  };

  return (
    <>
      {sectionType === 'SectionContent' && (
        <ContentEditor
          {...props}
          isEmailPage={isEmailPage}
          submitCounter={submitCounter}
          postSave={postSave}
          refetchPageData={refetchPageData}
        />
      )}
      {sectionType === 'SectionColumn' && (
        <ColumnType
          {...props}
          isEmailPage={isEmailPage}
          submitCounter={submitCounter}
          postSave={postSave}
          refetchPageData={refetchPageData}
        />
      )}
      {sectionType === 'SectionForm' && (
        <FormSection
          {...props}
          isEmailPage={isEmailPage}
          submitCounter={submitCounter}
          postSave={postSave}
          refetchPageData={refetchPageData}
        />
      )}
      {sectionType === 'SectionImageGallery' && (
        <ImageGallery
          {...props}
          isEmailPage={isEmailPage}
          submitCounter={submitCounter}
          postSave={postSave}
          refetchPageData={refetchPageData}
        />
      )}
      {sectionType === 'SectionComponent' && (
        <Component
          {...props}
          isEmailPage={isEmailPage}
          submitCounter={submitCounter}
          postSave={postSave}
          refetchPageData={refetchPageData}
        />
      )}
      {sectionType === 'SectionMirror' && (
        <MirrorSection
          {...props}
          isEmailPage={isEmailPage}
          submitCounter={submitCounter}
          postSave={postSave}
          refetchPageData={refetchPageData}
        />
      )}
      {sectionType === 'SectionReviewList' && (
        <ReviewList
          {...props}
          isEmailPage={isEmailPage}
          submitCounter={submitCounter}
          postSave={postSave}
          refetchPageData={refetchPageData}
        />
      )}
    </>
  );
}

export default withRouter(SectionEdit);
