import React, { useState, useEffect } from 'react';
import { Card, CardBody } from 'reactstrap';
import withRouter from '../../helpers/withRouter';
import { createStore } from '../../../../rootStore';
import './index.scss';

function Test(props) {
  const {
    params: { siteId },
  } = props;

  const [texts, setTexts] = useState([]);

  useEffect(() => {
    (async () => {
      // const response = await fetch('/api/v1/component_textblocks');
      const response = await fetch(`/api/v1/all_textblocks?site_id=${siteId}`);
      const data = await response.json();
      setTexts(data.data);
    })();
  }, []);

  const store = createStore();
  const { pageData: { admin_domain } } = store.getState();

  texts.forEach((text) => {
    const siteId = text[6];
    const pageId = text[5];
    const pageVersionId = text[4];
    const adminDomain = admin_domain ? `https://${admin_domain}/api/v1` : '/api/v1';
    const url = `${adminDomain}/sites/${siteId}/pages/${pageId}/page_versions/${pageVersionId}/stylesheet?prefix=true`;

    if (document.querySelector(`link[href="${url}"]`)) {
      return;
    }

    const el = document.createElement('link');
    el.id = 'dashboard-style-link';
    el.rel = 'stylesheet';
    el.href = url;

    document.head.appendChild(el);
  });

  return (
    <div className="Test">
      {texts.filter((t) => t[0] && t[0].match(/tbi/) && t[0].match(/style-[a-f0-9]{24}/)).map((t) => (
        <Card className="mb-4">
          <CardBody className="style-prefix-container">
            <div dangerouslySetInnerHTML={{ __html: t[0] }} />
          </CardBody>
        </Card>
      ))}
    </div>
  );
}

export default withRouter(Test);
