import { HIDE_SIDEBAR } from '../actions';

const hideSideBar = (menu) => (
  {
    type: HIDE_SIDEBAR,
    payload: menu,
  }
);

export default hideSideBar;
