import client from '../../../api';

export default {
  getGoogleAnalyticsAccountList: (params) => client.get(
    `/instances/${params.instanceId}/sites/${params.siteId}/analytics_accounts`,
  ),
  getAnalyticsDataStreams: (params) => client.get(
    `/instances/${params.instanceId}/sites/${params.siteId}/analytics_data_streams?service_account_number=${params.serviceAccountNumber}&account_id=${params.accountId}&property_id=${params.propertyId}`,
  ),
  createAnalyticsProperty: (params) => client.post(
    `/instances/${params.instanceId}/sites/${params.id}/create_analytics_property`,
    params,
  ),
};
