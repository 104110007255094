import { XERO_CONNECTION } from 'dashboard/src/redux/actions.js';
import XeroAPI from 'dashboard/src/api/xero';
import { displaySuccess, getInstanceByIdFromListing } from '../../../../../Utils';
import { updateInstance } from '../../../api/apiSlice';

export const actionType = (payload, type) => ({
  type,
  payload,
});

export const xeroCallBackAction = (params) => async (dispatch) => {
  try {
    const response = await XeroAPI.xeroCode(params);
    const { data } = response.data;
    if (typeof data === 'object') {
      await dispatch(updateInstance({ id: params.instance_id, data }));
      return {};
    }
    return data;
  } catch (err) {
    return {};
  }
};

export const checkXeroConnectivity = (params) => async (dispatch) => {
  try {
    const response = await XeroAPI.xeroConnection(params);
    const { is_success } = response.data;
    dispatch(actionType(is_success, XERO_CONNECTION));
  } catch (err) {
    dispatch(actionType(false, XERO_CONNECTION));
  }
};

export const disconnectXero = (params) => async (dispatch) => {
  try {
    await XeroAPI.delete(params);
    await dispatch(updateInstance({ id: params.instance_id, data: { ...getInstanceByIdFromListing(params.instance_id), oauth2_details: {} } }));
  } catch (err) {
    dispatch(updateInstance({ id: params.instance_id, data: getInstanceByIdFromListing(params.instance_id) }));
  }
};

export const connectTenantToInstance = (params) => async (dispatch) => {
  try {
    const response = await XeroAPI.update(params);
    const { data } = response.data;
    dispatch(updateInstance({ id: params.instance_id, data }));
    displaySuccess(response.data.messages);
    return true;
  } catch (err) {
    dispatch(updateInstance({ id: params.instance_id, data: getInstanceByIdFromListing(params.instance_id) }));
    return false;
  }
};
