import React, { useState } from 'react';
import withRouter from '../../../../helpers/withRouter';
import { Modal, ModalBody, ModalFooter, Button } from 'reactstrap';
import SaveAndContinue from '../../../../components/common/saveAndContinue';
import SectionEdit, { waitForSave } from '../SectionEdit';

function SectionEditModal(props) {
  const {
    section,
    onClose,
    params,
    params: { pageType },
    refetchPageData,
  } = props;

  const isSectionFromRails = 'sectionId' in params;

  const [submitCounter, setSubmitCounter] = useState(0);

  const isEmailPage = pageType === 'email';

  const onHandleClose = () => {
    if (isSectionFromRails) {
      window.parent.postMessage('close');
    } else {
      onClose();
    }
  };

  const fullscreenEditModal = ['SectionComponent', 'SectionColumn'].indexOf(section?.type) > -1;

  const style = {
    maxWidth: '100%',
    margin: '0',
  };

  return (
    <Modal
      container={window.modalRef}
      className="edit-section-modal theme-light"
      isOpen={true}
      size={fullscreenEditModal ? 'lg' : 'xl'}
      fullscreen={fullscreenEditModal && !isEmailPage}
      style={isSectionFromRails ? style : {}}
    >
      <ModalBody>
        <SectionEdit
          {...props}
          // section={section}
          submitCounter={submitCounter}
          setSubmitCounter={setSubmitCounter}
          refetchPageData={refetchPageData}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          type="submit"
          color="danger"
          onClick={onHandleClose}
          className="fw-bold"
        >
          CANCEL
        </Button>
        <SaveAndContinue
          onSave={async () => {
            window.sectionSaved = false;
            setSubmitCounter(submitCounter + 1);
            await waitForSave();
            return true;
          }}
          onContinue={onHandleClose}
        />
      </ModalFooter>
    </Modal>
  );
}

export default withRouter(SectionEditModal);
