import React, { useState } from 'react';
import { Button, Badge } from 'reactstrap';
import { get } from 'lodash-es';
import { DateTime } from 'luxon';
import DateTimeFormat from '../../components/common/DateTimeFormat';
import withRouter from '../../helpers/withRouter';
import CtaReport from './index';
import { redirectToAnotherPage } from '../site-centre/PageEdit/PageEditOption';
import confirm from '../../components/common/confirm';
import { useDispatch } from 'react-redux';
import { deleteCTALog } from '../../redux/ctaReports/actions';

function CtaReportReview(props) {
  const { instanceId } = props;
  const dispatch = useDispatch();

  const [orderBy, setOrderBy] = useState('created_at');
  const [orderDirection, setOrderDirection] = useState('desc');

  const onHandleDelete = async (id) => {
    const result = await confirm({
      title: <b>Confirm!</b>,
      message: 'Are you sure?',
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });

    if (result) {
      const params = {
        id,
        instance_id: instanceId,
      };
      await dispatch(deleteCTALog(params, 'CTALogReview'));
    }
  };

  const platformIcon = (platform) => {
    if (platform === 'nocowboys') {
      return <img src="/assets/no-cowboys.png" style={{ width: '30px', filter: 'grayscale(100%)' }} />;
    }

    return <i className={`fab fa-2x fa-${platform === 'facebook' ? 'facebook-square' : 'google'}`} />;
  };

  const dataTableColumns = [
    {
      Header: (
        <Button
          onClick={() => {
            setOrderBy('created_at');
            setOrderDirection(orderDirection === 'desc' ? 'asc' : 'desc');
          }}
          className="custom-simple-txt-btn fw-bold"
        >
          <span>
            {'Date '}
            {orderBy === 'created_at' ? (
              <i
                className={` ${orderDirection === 'desc' ? 'fa fa-chevron-circle-up' : 'fa fa-chevron-circle-down'}`}
              />
            ) : ''}
          </span>
        </Button>
      ),
      id: 'Date',
      className: 'text-nowrap',
      disableSortBy: true,
      accessor: (d) => (
        <DateTimeFormat
          datetime={get(d, 'attributes.created_at', '')}
          localeString={DateTime.DATE_SHORT}
        />
      ),
    },
    {
      Header: 'Name',
      id: 'Name',
      disableSortBy: true,
      accessor: (d) => get(d, 'attributes.reviewer_name', ''),
    },
    {
      Header: 'Review',
      id: 'Review',
      disableSortBy: true,
      accessor: (d) => get(d, 'attributes.text', ''),
    },
    {
      Header: (
        <Button
          onClick={() => {
            setOrderBy('rating');
            setOrderDirection(orderDirection === 'desc' ? 'asc' : 'desc');
          }}
          className="custom-simple-txt-btn fw-bold"
        >
          <span>
            {'Rating '}
            {orderBy === 'rating' ? (
              <i className={` ${orderDirection === 'desc' ? 'fa fa-chevron-circle-up' : 'fa fa-chevron-circle-down'}`} />
            ) : ''}
          </span>
        </Button>
      ),
      id: 'Rating',
      className: 'text-center align-middle text-nowrap',
      rowSpan: (cell) => (cell.row.original.attributes.response ? 2 : 1),
      disableSortBy: true,
      accessor: (d) => {
        const r = get(d, 'attributes.rating', '');
        const color = {
          1: '#dd776e',
          2: '#e9a268',
          3: '#f5ce62',
          4: '#a4c073',
          5: '#57bb8a',
        }[r] || '#';

        return (
          <Badge
            style={{ backgroundColor: color }}
            color=""
            className="fs-6"
            pill
          >
            {r}
          </Badge>
        );
      },
    },
    {
      Header: 'Platform',
      id: 'Platform',
      className: 'text-center align-middle',
      rowSpan: (cell) => (cell.row.original.attributes.response ? 2 : 1),
      disableSortBy: true,
      accessor: (d) => (
        <Button
          // For No Cowboys, we use text fragment links, and they require "noopener" for security reasons.
          // https://wicg.github.io/scroll-to-text-fragment/#security-and-privacy
          onClick={() => redirectToAnotherPage(get(d, 'attributes.url', ''), '_blank', 'noopener')}
          className="custom-simple-txt-btn fw-bold"
        >
          <span>
            {platformIcon(get(d, 'attributes.platform', ''))}
          </span>
        </Button>
      ),
    },
    {
      Header: 'Link',
      id: 'Link',
      className: 'text-center align-middle',
      rowSpan: (cell) => (cell.row.original.attributes.response ? 2 : 1),
      disableSortBy: true,
      accessor: (d) => (
        <Button
          // For No Cowboys, we use text fragment links, and they require "noopener" for security reasons.
          // https://wicg.github.io/scroll-to-text-fragment/#security-and-privacy
          onClick={() => redirectToAnotherPage(get(d, 'attributes.url', ''), '_blank', 'noopener')}
          className="custom-simple-txt-btn fw-bold"
        >
          <span>
            <i className="fas fa-link" />
          </span>
        </Button>
      ),
    },
    {
      Header: 'Action',
      id: 'action',
      className: 'text-center align-middle',
      disableSortBy: true,
      accessor: (d) => (
        <Button className="custom-simple-icon" color="danger" size="xs" onClick={() => onHandleDelete(d.id)}>
          <i className="fal fa-trash" />
        </Button>
      ),
    },
  ];

  return (
    <CtaReport
      instanceId={instanceId}
      dataTableColumns={dataTableColumns}
      orderBy={orderBy}
      orderDirection={orderDirection}
      ctaType="CTALogReview"
      typeName={['review', 'reviews']}
      subRow={(_columns, row) => (row.original.attributes.response ? (
        <tr>
          <td colSpan={2} style={{ border: 'none' }} />
          <td style={{ paddingLeft: '36px', border: 'none' }}>
            <span style={{ color: '#999' }}>
              <i className="fa-solid fa-turn-down-right" />
              {' '}
              <i>Response:</i>
            </span>
            {' '}
            {row.original.attributes.response}
          </td>
        </tr>
      ) : '')}
    />
  );
}

export default withRouter(CtaReportReview);
