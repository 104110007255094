import React from 'react';
import { Label, FormGroup, Input, InputGroup, InputGroupText } from 'reactstrap';
import CustomSelect from '../../../components/common/custom-select';
import { get } from 'lodash-es';
import { getSelectedOption } from '../../../../../Utils';

const triggers = [
  {label: 'Delay', value: 'delay'},
  {label: 'Exit intent', value: 'exit_intent'},
  {label: 'Scroll percentage', value: 'scroll_percentage'},
];

function PopupTrigger(props) {
  const { params, setParams } = props;

  return (
    <>
      <FormGroup>
        <Label>Popup Trigger</Label>
        <CustomSelect
          value={getSelectedOption(triggers, get(params, 'popup_options.trigger', '') || 'delay')}
          onChange={(e) => setParams({ ...params, popup_options: { ...params.popup_options, trigger: e.value }})}
          options={triggers}
        />
      </FormGroup>
      {params.popup_options.trigger !== 'exit_intent' && (
        <FormGroup>
          <>
            <Label>
              {(params.popup_options.trigger === 'delay' || typeof params.popup_options.trigger === 'undefined') && 'Delay (in seconds)'}
              {params.popup_options.trigger === 'scroll_percentage' && 'Percentage of the page scrolled'}
            </Label>
            <InputGroup>
              <Input
                min={0}
                type="number"
                value={get(params, 'popup_options.trigger_value', 0)}
                onChange={(e) => setParams({
                  ...params, popup_options: { ...params.popup_options, trigger_value: e.target.value,
                }})}
              />
              <InputGroupText>{params.popup_options.trigger === 'delay' ? 'sec' : '%'}</InputGroupText>
            </InputGroup>
          </>
        </FormGroup>
      )}
    </>
  );
}

export default PopupTrigger;
