import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from '../../hooks';
import userApi from '../../api/user';
import './user-presence.scss';

function UserPresence() {
  const user = useUser();
  const { instanceId } = useParams();
  const [otherUsers, setOtherUsers] = useState([]);

  // This list of colours was selected from the CSS colour list and filtered so that we only have colours that have
  // good contrast with white text.
  const colors = ('gray maroon red purple fuchsia green olive blue teal blueviolet brown cadetblue chocolate ' +
    'cornflowerblue crimson darkcyan darkgoldenrod darkgreen darkmagenta darkolivegreen darkorchid darkred ' +
    'darkslateblue darkslategray darkviolet deeppink dimgray dodgerblue firebrick forestgreen grey hotpink ' +
    'indianred lightcoral lightseagreen lightslategray magenta mediumorchid mediumpurple mediumseagreen ' +
    'mediumslateblue mediumvioletred olivedrab orangered orchid palevioletred peru rosybrown royalblue saddlebrown ' +
    'seagreen sienna slateblue slategray steelblue tomato rebeccapurple').split(' ');

  if (!instanceId || !user) {
    return <></>;
  }

  const sendPresence = async () => {
    const response = await userApi.sendPresence({ id: user.id, instance_id: instanceId }, { disableSpinner: true });
    if (!response) {
      // Possibly no internet connection.
      return;
    }

    const { data: { data } } = response;
    if (data) {
      setOtherUsers(data);
    }
  };

  clearInterval(window.presenceInterval);
  window.presenceInterval = setInterval(sendPresence, 5000);

  return (
    <div className="user-presence d-inline-block align-middle">
      {otherUsers.map((user) => {
        const parts = user.full_name.toUpperCase().split(/\s/);
        const initials = `${parts[0][0]}${parts[1] ? parts[1][0] : ''}`;

        // Getting the colour from the other user's ID so that it is always the same for each user.
        const backgroundColor = colors[parseInt(user.id, 16) % colors.length];

        if (user.avatar_url) {
          return <img key={user.id} src={user.avatar_url} alt={user.full_name} title={user.full_name} />;
        }

        return (
          <div key={user.id} style={{ backgroundColor }} title={user.full_name}>
            {initials}
          </div>
        );
      })}
    </div>
  );
}

export default UserPresence;
