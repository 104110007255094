import React from 'react';
import { useEffect, useRef } from 'react';

const useScript = (url, onload) => {
  useEffect(() => {
    let scriptTag = document.getElementById('google-signin-script');
    if (url) {
      if (scriptTag == null) {
        scriptTag = document.createElement('script');
        scriptTag.id = 'google-signin-script';
      }
      scriptTag.type = 'text/javascript';
      scriptTag.src = url;
      document.head.appendChild(scriptTag);
      scriptTag.onload = onload;
    }
  }, [url, onload]);
};

export default function GoogleLogin({ onGoogleSignIn }) {

  const googleSignInButton = useRef(null);

  useScript('https://accounts.google.com/gsi/client', () => {
    window.google.accounts.id.initialize({
      client_id: '190603057562-fej71cval6vd8e13io7vcrk29s7a4k0b.apps.googleusercontent.com',
      callback: onGoogleSignIn,
    });
    window.google.accounts.id.renderButton(
      googleSignInButton.current,
      { theme: 'outline', size: 'large', width: 250 } // customization attributes
    );
  });

  return <div className="google-login-btn" ref={googleSignInButton}></div>;
}
