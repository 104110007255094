import React, { useEffect, useState } from 'react';
import { setPageTitle } from '../../../../../../Utils';
import HeaderComponent from '../../../../components/common/header-component';
import { mainRoutes, settingRoutes, replacePathParams } from '../../../../constants/routes';
import TableComponent from '../../../../components/common/Table';
import { Button, Card, CardBody, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import withRouter from '../../../../helpers/withRouter';
import { createComponentCategory, deleteComponentCategory, getComponentCategoryListing } from '../../../../redux/designOptions/componentCategories/actions';
import { useDispatch, useSelector } from 'react-redux';
import BottomActionToolbar from '../../../../components/common/toolbar';
import { get } from 'lodash-es';
import confirm from '../../../../components/common/confirm';
import UserFeaturePage from '../../../../components/common/user-feature-page';

function ComponentCategoryList(props) {
  const { navigate, params: { instanceId } } = props;
  const dispatch = useDispatch();
  const [showModal, setModal] = useState(false);
  const [params, setParams] = useState({name: ''});
  const { componentCategories } = useSelector((state) => state.adminStore.componentCategoriesReducer);

  useEffect(() => {
    setPageTitle('Component Categories');
    dispatch(getComponentCategoryListing());
  }, [dispatch]);

  const onEditAction = (cat) => {
    navigate(replacePathParams(
      settingRoutes.componentList,
      [
        { key: 'categoryId', value: cat.id },
      ], props
    ));
  };

  const onHandleToggle = () => {
    setModal(!showModal);
  };

  const addNewCategory = () => {
    dispatch(createComponentCategory(params));
    setModal(false);
    setParams({name: ''});
  };

  const onDelete = async (id) => {
    const result = await confirm({
      title: <b>Confirm!</b>,
      message: 'Are you sure?',
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });
    if (result) {
      dispatch(deleteComponentCategory({id, instance_id: instanceId}));
    }
  };

  const dataTableColumns = [
    {
      Header: 'Name',
      id: 'name',
      accessor: (c) => c.name,
    },
    {
      Header: 'Action',
      id: 'action',
      accessor: (c) => (
        <>
          <Button className="custom-simple-icon" color="primary" size="xs" onClick={() => onEditAction(c)}>
            <i className="fal fa-pen" />
          </Button>
          {' '}
          <Button className="custom-simple-icon" color="danger" size="xs" onClick={() => onDelete(c.id)}>
            <i className="fal fa-trash" />
          </Button>
        </>
      ),
    },
  ];

  const staticBreadcrumbData = [
    { name: 'Dashboard', url: replacePathParams(settingRoutes.dashboard, [], props) },
    { name: 'Settings', url: replacePathParams(mainRoutes.setting, [], props) },
    { name: 'Component Categories', url: '' },
  ];

  return (
    <>
      <HeaderComponent
        setPath={{
          backBtnName: 'Back',
          staticBreadcrumbData,
          showBreadcrumb: false,
          headingName: 'Component Categories',
          addNewBtnName: '',
          addNewPath: '',
        }}
      />
      <UserFeaturePage feature="manage_components">
        <Card className="mb-4">
          <CardBody>
            <TableComponent
              columns={dataTableColumns}
              data={componentCategories}
            />
          </CardBody>
        </Card>
        {showModal && (
          <Modal isOpen={showModal} toggle={onHandleToggle} container={window.modalRef}>
            <ModalHeader toggle={onHandleToggle}>Category</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label>Name</Label>
                <Input
                  type="text"
                  value={get(params, 'name', '')}
                  onChange={(e) => setParams({...params, name: e.target.value})}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="success" onClick={() => addNewCategory()}>SAVE</Button>
            </ModalFooter>
          </Modal>
        )}
        <BottomActionToolbar
          component={(
            <Button color="info" onClick={() => onHandleToggle()}>NEW CATEGORY</Button>
          )}
        />
      </UserFeaturePage>
    </>
  );
}

export default withRouter(ComponentCategoryList);
