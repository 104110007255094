import ComponentApi from '../../../api/component';
import { replacePathParams, settingRoutes } from '../../../constants/routes';
import { displaySuccess } from '../../../../../Utils';
import { SET_COMPONENTS, SET_COMPONENT, UPDATE_COMPONENT, DELETE_COMPONENT, DUPLICATE_COMPONENT } from '../../actions';

const actionType = (payload, type) => ({
  type,
  payload,
});

export const getComponentListing = (params) => async (dispatch) => {
  try {
    const response = await ComponentApi.index(params);
    dispatch(actionType(response.data.data, SET_COMPONENTS));
  } catch (err) {
    dispatch(actionType([], SET_COMPONENTS));
  }
};

export const getComponent = (id) => async (dispatch) => {
  try {
    const response = await ComponentApi.show(id);
    dispatch(actionType(response.data.data, SET_COMPONENT));
  } catch (err) {
    dispatch(actionType({}, SET_COMPONENT));
  }
};

export const createComponent = (params, props) => async () => {
  const response = await ComponentApi.create(params);
  if (undefined !== response) {
    props.navigate(replacePathParams(
      settingRoutes.componentList,
      [
        { key: 'categoryId', value: params.component_category_id },
      ], props
    ));
  }
};

export const updateComponent = (params) => async (dispatch) => {
  try {
    const response = await ComponentApi.update(params);
    dispatch(actionType(response.data.data, UPDATE_COMPONENT));
  } catch (err) {
    dispatch(actionType([], UPDATE_COMPONENT));
  }
};

export const removeComponentData = () => async (dispatch) => {
  dispatch(actionType({}, UPDATE_COMPONENT));
};

export const deleteComponent = (params) => async (dispatch) => {
  try {
    const response = await ComponentApi.delete(params);
    dispatch(actionType(params?.id, DELETE_COMPONENT));
    displaySuccess(response.data.messages);
  } catch (err) {
    dispatch(actionType(null, DELETE_COMPONENT));
  }
};


export const duplicateComponent = (params) => async (dispatch) => {
  try {
    const response = await ComponentApi.duplicate(params);
    if (undefined !== response) {
      dispatch(actionType(response?.data?.data, DUPLICATE_COMPONENT));
      displaySuccess(response.data.messages);
    }
  } catch (err) {
    dispatch(actionType(null, DUPLICATE_COMPONENT));
  }
};