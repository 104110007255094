import React, { useState, useRef, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
// import HoverImage from 'react-hover-image';
import {
  Row, Col, Button, Card, CardBody, Label, Alert,
} from 'reactstrap';
import Select from 'react-select';
import { filter, get, isEmpty } from 'lodash-es';
import LinkToolBox from '../views/site-centre/Section/LinkToolBox';
import MediaToolBox from '../views/site-centre/Section/MediaToolBox';
import { baseDomain } from '../../../api';
import TextBlockApi from '../api/text_block';
import MediaToolApi from '../api/media_tool';
import { inline as buttonInline } from '../api/button';
import { convertNegativeToHex } from './common/breakpoint';
import confirm from './common/confirm';
import {
  displayError,
  displaySuccess,
  getSelectedOption,
  selectColorStyles,
  getFontSize,
  getContrast,
  getOppositeColor,
} from '../../../Utils';
import withRouter from '../helpers/withRouter';
import TextStyleListing from './common/textStyles';
import SectionHistory from './SectionHistory';
import AIPromptWriter from './AIPromptWriter';
import CustomTooltip from './common/CustomTooltip';
import { useGetTextStylesQuery } from '../api/apiSlice';
import { useUser, useSite } from '../hooks';

function RichTextEditor(props) {
  const {
    index,
    style,
    editorId,
    features,
    onDragOver,
    editorType,
    onDragEnter,
    isEmailPage,
    textBlockObj,
    onHandleMove,
    onHandleRestore,
    countTextBlocks,
    onUpdateTextBlock,
    onHandleDeleteEditor,
  } = props;

  const rootElement = (window.adminRoot?.shadowRoot || document);

  let { instanceId, siteId } = useParams();

  const instance = useSelector((s) => s.pageData.instance);
  if (!instanceId && instance?.id) {
    instanceId = instance.id;
  }

  const site = useSite();

  if (!siteId && site?.id) {
    siteId = site.id;
  }

  const user = useUser();

  const [initialText, setInitialText] = useState(textBlockObj.block_text);

  const { data: textStyleList } = useGetTextStylesQuery(siteId, { skip: isEmpty(siteId) });

  const editorRef = useRef(null);

  const [linkHelper, setLinkHelper] = useState({ show: false, position: [] });
  const [toolData, setToolData] = useState({});
  const [linkId, setLinkId] = useState(null);
  const [modal, setModal] = useState(false);
  const [ai, setAI] = useState(false);
  const [showSEOWarning, setShowSEOWarning] = useState(false);

  useEffect(() => {
    if (ai) {
      // Was using refs but it wasn't working...
      setTimeout(() => rootElement.querySelector(`#aiInput${index}`).focus(), 200);
    }
  }, [ai]);

  // getSelection within shadow DOM is a bit funny: https://stackoverflow.com/a/70523247
  const getSelection = () => {
    const selection = window.getSelection();
    const adminRoot = document.getElementById('adminRoot');
    if (selection?.anchorNode instanceof HTMLBodyElement && adminRoot?.shadowRoot) {
      return adminRoot.shadowRoot.getSelection();
    }
    return selection;
  };

  const [selectedTextData, setSelectedTextData] = useState(null);

  const sourceMode = textBlockObj?.sourceMode;

  const lineBreaksOptions = [
    { label: 'No break', value: 'no_break' },
    { label: 'Single line break', value: 'single_line' },
    { label: 'Double line break', value: 'double_line' },
    { label: 'Clear left', value: 'clear_left' },
    { label: 'Clear right', value: 'clear_right' },
  ];

  const hasFeature = (f) => features.indexOf(f) > -1;

  const getText = (data) => {
    const html = data.replace(
      /<(h1|\/h1|h2|\/h2|h3|\/h3|h4|\/h4|h5|\/h5|h6|\/h6|span|\/span|p|\/p)[^>]{0,}>/g,
      '',
    );

    // Some operations (for example adding a button link) wrap the textblock in a <div>. If there is only one <div> and
    // it has no styles, we don't need it, and it can cause extra line breaks... so we unwrap the <div>.
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const body = doc.querySelector('body');
    const children = body.childNodes;
    if (children.length === 1 && children[0] instanceof HTMLDivElement && children[0].style.length === 0) {
      const div = children[0];
      while (div.firstChild) {
        body.insertBefore(div.firstChild, div);
      }
      div.remove();
      return body.innerHTML;
    }

    return html;
  };

  const onHandleInputChange = (e) => {
    cleanup();

    let text;
    if (editorType === 'raw' || (editorType === 'normal' && sourceMode)) {
      text = e.target.value;
    } else {
      const { target: { innerHTML } } = e;
      text = getText(innerHTML);
    }
    onUpdateTextBlock({ ...textBlockObj, block_text: text });
  };

  const onCopy = (e) => {
    e.preventDefault();
    const range = getSelection().getRangeAt(0);
    const container = range.commonAncestorContainer.parentNode.cloneNode(false);
    container.appendChild(range.cloneContents());

    // We normally want innerHTML, but if the entire copied contents is just a link (<a>), the innerHTML is just the
    // link text without the link tag. We could change to outerHTML but then when copying just a link, we get the
    // editor container. I can't figure out a more elegant way to do this, but this works.
    const html = container.outerHTML.match(/editor-container/) ? container.innerHTML : container.outerHTML;

    // First, we set the text/plain version to the original HTML. This is so that we can paste content into anything
    // that is not the WG textblock editor, like pasting an email address from a textblock into the link tool.
    e.clipboardData.setData('text/plain', html);

    // Then, we add a small comment so that we know that the data came from our CMS, this is checked later in the
    // onPasteEditor function.
    e.clipboardData.setData('text/html', `<!--FromWG-->${html}`);
  };

  const onPaste = (e) => {
    e.preventDefault();
    const event = e.originalEvent || e;
    const html = event.clipboardData.getData('text/html');
    // Check if the content came from our CMS. If it didn't, we can't trust it and we need to convert it to plain text.
    if (html && html.includes('<!--FromWG-->')) {
      window.document.execCommand('insertHTML', false, html.replace('<!--FromWG-->', ''));
    } else {
      const text = event.clipboardData.getData('text/plain');
      if (text) {
        window.document.execCommand('insertText', false, text);
      }
    }

    removeStyleAttributes();

    cleanup();
  };

  const onChangeTextStyle = (e) => {
    onUpdateTextBlock({ ...textBlockObj, text_style_id: e.value });
  };

  const textStyles = () => {
    const textStyle = filter(textStyleList, (item) => item.id === textBlockObj?.text_style_id)[0];

    if (!textStyle) {
      return {};
    }

    const fontWeightValue = textStyle.font_weight === 'regular' ? 'normal' : textStyle.font_weight;

    return {
      color: `${textStyle.color}`,
      textAlign: `${textStyle.text_align}`,
      fontFamily: `${textStyle.fonts}`,
      fontWeight: `${fontWeightValue}`,
      fontStyle: `${textStyle.font_style}`,
      textDecoration: `${textStyle.text_decoration}`,
      fontSize: isEmailPage ? `${textStyle.font_size}px` : getFontSize(textStyle),
    };
  };

  const addRemoveTag = (windowSelection, tag) => {
    const selectedString = windowSelection.toString();
    const tagErrors = {
      bold: 'bold',
      italic: 'italic',
      justifyLeft: 'align left',
      justifyRight: 'align right',
      justifyCenter: 'align center',
    };
    if (!selectedString && typeof tagErrors[tag] !== 'undefined') {
      displayError(`
      Please select the text to ${tagErrors[tag]} before clicking on the ${tagErrors[tag]} tool.
    `);
    } else {
      document.execCommand(tag);
    }
  };

  const onHandleDivider = () => {
    document.execCommand('insertHorizontalRule');
  };

  const showLinkHelper = (anchor) => {
    // The helper goes below the link.
    // If the link is a button, we need to position the helper at the bottom of the button.
    let guideNode;
    if (anchor.childNodes[0] && anchor.childNodes[0] instanceof HTMLButtonElement) {
      [guideNode] = anchor.childNodes;
    } else {
      guideNode = anchor;
    }

    const editorElement = anchor.closest('.editor-value');
    if (!editorElement) {
      return;
    }

    setLinkHelper({
      show: true,
      anchor,
      position: [guideNode.offsetLeft, guideNode.offsetTop + guideNode.offsetHeight - editorElement.scrollTop],
    });
  };

  const onClickEditLink = async () => {
    setToolData({ showModal: true });
  };

  const hideLinkHelper = () => {
    setLinkHelper({ ...linkHelper, show: false });
  };

  const linkHelperElement = () => {
    const {
      anchor,
      position,
    } = linkHelper;

    return (
      <div
        className="link-helper"
        style={{ left: `${position[0]}px`, top: `${position[1]}px` }}
      >
        <span
          role="button"
          tabIndex={0}
          onKeyDown={() => { }}
          onClick={() => {
            const linkElement = linkHelper.anchor.dataset;
            setLinkId(linkElement.id);
            onClickEditLink();
            hideLinkHelper();
          }}
        >
          <i className="fal fa-pen cursor-pointer ps-2 pe-2" />
        </span>
        &nbsp;&nbsp;
        <i className="fal fa-pipe" />
        &nbsp;&nbsp;
        <span
          role="button"
          tabIndex={0}
          onKeyDown={() => { }}
          onClick={() => {
            // TODO: currently if there is markup inside the link,
            // like <a href=#>This is <b>my</b> link</a>, the markup is stripped out.
            // TODO: unlinking is currently not undoable.
            anchor.replaceWith(anchor.innerText);
            onHandleInputChange({ target: editorRef.current });
            hideLinkHelper();
          }}
        >
          <i className="fal fa-unlink cursor-pointer ps-2 pe-2" />
        </span>
        {[...anchor.childNodes].some((n) => n instanceof HTMLButtonElement) ? (
          <>
            &nbsp;&nbsp;
            <i className="fal fa-pipe" />
            &nbsp;&nbsp;
            <span
              role="button"
              tabIndex={0}
              onKeyDown={() => { }}
              onClick={() => {
                anchor.parentNode.style.textAlign = 'left';
                onHandleInputChange({ target: editorRef.current });
                hideLinkHelper();
              }}
            >
              <i className="fal fa-align-left cursor-pointer ps-2 pe-2" />
            </span>
            <span
              role="button"
              tabIndex={0}
              onKeyDown={() => { }}
              onClick={() => {
                anchor.parentNode.style.textAlign = 'center';
                onHandleInputChange({ target: editorRef.current });
                hideLinkHelper();
              }}
            >
              <i className="fal fa-align-center cursor-pointer ps-2 pe-2" />
            </span>
            <span
              role="button"
              tabIndex={0}
              onKeyDown={() => { }}
              onClick={() => {
                anchor.parentNode.style.textAlign = 'right';
                onHandleInputChange({ target: editorRef.current });
                hideLinkHelper();
              }}
            >
              <i className="fal fa-align-right cursor-pointer ps-2 pe-2" />
            </span>
          </>
        ) : ''}
      </div>
    );
  };

  let linkHelperTimeout;

  const checkForLinkHelper = () => {
    const selection = getSelection();
    const node = selection.anchorNode;
    if (!node) {
      return;
    }
    let { parentElement } = node;
    let anchor;
    /* eslint no-constant-condition: ["error", { "checkLoops": false }] */
    while (true) {
      if (parentElement instanceof HTMLAnchorElement && parentElement.dataset.id) {
        anchor = parentElement;
        break;
      }
      if (parentElement && parentElement.className.match(/editor-container/)) {
        break;
      }
      if (!parentElement) {
        break;
      }

      parentElement = parentElement.parentElement;
    }
    if (!anchor) {
      hideLinkHelper();
      return;
    }
    showLinkHelper(anchor);
  };

  const cleanup = () => {
    if (editorType !== 'normal') {
      return;
    }

    if (!editorRef?.current?.querySelectorAll) {
      return;
    }

    // Remove any class attributes from the editor
    editorRef?.current?.querySelectorAll('[class]')?.forEach((el) => {
      // We need classes for button links, don't want to remove these.
      if (el.closest('button')) {
        return;
      }

      el.removeAttribute('class');
    });
  };

  // Remove all style properties other than what can be set by editor buttons.
  const removeStyleAttributes = () => {
    const allowedStyles = [
      'text-align',
      'font-weight',
      'font-style',
    ];
    editorRef.current.querySelectorAll('[style]').forEach((node) => {
      // We need styles for button links, don't want to remove these.
      if (node.closest('button')) {
        return;
      }

      [...node.style].forEach((style) => {
        if (allowedStyles.indexOf(style) === -1) {
          node.style.removeProperty(style);
        }
      });
    });
  };

  const handleOnClickEditor = (e) => {
    if (textStyleList && textBlockObj?.text_style_id) {
      const currentStyle = textStyleList.find((style) => style.id === textBlockObj.text_style_id);
      if (currentStyle?.tag?.match(/h[1-6]/)) {
        setShowSEOWarning(true);
      }
    }

    if (e.keyCode === 9) {
      // Allow pressing the tab key
      e.preventDefault();
      document.execCommand('indent', false, null);
    } else {
      clearTimeout(linkHelperTimeout);
      linkHelperTimeout = setTimeout(checkForLinkHelper, 20);
    }

    removeStyleAttributes();

    cleanup();
  };

  // Currently using the onClick on onKeyDown handler to check for the link helper,
  // and we can use the same method for both.
  const handleOnKeyDownEditor = (e) => handleOnClickEditor(e);

  const onHandleEditorTool = (type) => {
    const windowSelection = getSelection();
    addRemoveTag(windowSelection, type);
    hideLinkHelper();
  };

  // This method is the local one which sets the textblock variables to indicate that it has been deleted, and then we
  // call onHandleDeleteEditor, which comes from the parent component and actually removes the interface element.
  const onHandleDeleteEditorChild = async () => {
    try {
      const result = await confirm({
        title: <b>Confirm!</b>,
        message: 'Are you sure?',
        confirmText: 'Yes',
        confirmColor: 'success',
        cancelColor: 'btn btn-danger',
        cancelText: 'No',
      });

      if (result) {
        const sendParams = {
          instance_id: instanceId,
          section_id: textBlockObj?.section_content_id,
          id: textBlockObj?.id,
        };
        const response = await TextBlockApi.textBlockDelete(sendParams);
        if (undefined !== response) {
          onHandleDeleteEditor(textBlockObj);
          displaySuccess(response.data.messages);
        }
      }
    } catch (error) {
      displayError(`${error?.name}: ${error?.message}`);
    }
  };

  const toggleModal = () => {
    const { showModal } = toolData;
    setToolData({ showModal: !showModal });
  };

  const displayMedia = (media) => {
    let mediaTag = '';
    if (media === null) {
      mediaTag = (
        <div>
          No media selected.
          <br />
          <br />
          <Button color="success" className="button-sm common-success-button">Add Media</Button>
        </div>
      );
    } else if (undefined !== media?.hover_media_url && media?.hover_media_url) {
      // mediaTag = (
      //   <HoverImage
      //     src={baseDomain + media?.media_url}
      //     hoverSrc={baseDomain + media?.hover_media_url}
      //     className=""
      //     alt=""
      //   />
      // );
      mediaTag = '';
    } else {
      mediaTag = <img src={baseDomain + media?.media_url} alt="" />;
    }
    return mediaTag;
  };

  const onClickMedia = async (media) => {
    if (media && media?.id) {
      const sendParams = {
        id: media?.id,
        instance_id: instanceId,
      };
      const response = await MediaToolApi.getMediaData(sendParams);
      if (undefined !== response) {
        if (media?.link_id) {
          setLinkId(media.link_id);
        }

        setToolData({
          toolType: 'media',
          isEditAction: true,
          mediaToolOnText: true,
          showModal: true,
          selectedMediaToolData: response.data.data,
        });
      }
    } else {
      setToolData({
        toolType: 'media',
        isEditAction: false,
        mediaToolOnText: true,
        selectedMediaToolData: {},
        showModal: true,
      });
    }
  };

  const updateTextBlockData = (data) => {
    onUpdateTextBlock({ ...textBlockObj, media: data });
  };

  const onMediaBoxSaveButton = (mediaData) => {
    updateTextBlockData(mediaData);
    setToolData({ showModal: false });
  };

  const onHandleDeleteMedia = async () => {
    try {
      const result = await confirm({
        title: <b>Confirm!</b>,
        message: 'Are you sure?',
        confirmText: 'Yes',
        confirmColor: 'success',
        cancelColor: 'btn btn-danger',
        cancelText: 'No',
      });

      if (result) {
        const sendParams = {
          id: textBlockObj?.media?.id,
          instance_id: instanceId,
        };
        const response = await MediaToolApi.mediaToolDelete(sendParams);
        if (undefined !== response) {
          onUpdateTextBlock({ ...textBlockObj, media: null });
        }
      }
    } catch (error) {
      displayError(`${error?.name}: ${error?.message}`);
    }
  };

  const onClickLink = async () => {
    const validTags = ['B', 'I', 'STRONG'];
    const selectedString = getSelection().toString();

    if (!selectedString) {
      displayError('Please select the text to link before clicking on the link tool.');
      return false;
    }

    const selectedRange = getSelection().getRangeAt(0);

    setSelectedTextData(selectedRange);

    if (getSelection().anchorNode.parentElement.tagName === 'A') {
      const linkElement = getSelection().anchorNode.parentElement.dataset;
      setLinkId(linkElement.id);
      onClickEditLink();
    } else if (validTags.includes(getSelection().anchorNode.parentElement.tagName)) {
      const linkElement = getSelection().anchorNode.parentElement.parentElement.dataset;
      setLinkId(linkElement.id);
      if (typeof linkId === 'undefined') {
        setToolData({ showModal: true });
      } else {
        onClickEditLink();
      }
    } else {
      setToolData({ showModal: true });
    }

    return false;
  };

  const onLinkBoxSaveButton = (link) => {
    const complexSpan1 = rootElement.querySelector(
      `#${editorId} .editor-outer .editor-container .editor-value`,
    );
    let a;

    if (!linkId) {
      a = document.createElement('a');
      a.setAttribute('href', link.url);
      a.setAttribute('id', `link${link.id}`);
      a.setAttribute('data-id', link.id);
      // a.setAttribute('data-header', link.link_detail);
      a.setAttribute('data-textid', link.text_block_id);
      a.setAttribute('data-body', 'popup body text');
      a.setAttribute('data-description', link.link_detail);

      if (link.attr_type === 'anchor-link') {
        a.setAttribute('target', link.link_target);
        selectedTextData.surroundContents(a);
      } else if (link.attr_type === 'email-link' || link.attr_type === 'phone-link') {
        selectedTextData.surroundContents(a);
      }
    }

    if (link.link_display === 'button' && link.button_style) {
      // Now that we are editing in a modal box we have two of the link elements, one in the preview and one in the editor.
      rootElement.querySelectorAll(`#link${link.id}`).forEach((a) => {
        a.setAttribute('data-button-style-id', link.button_style_id);
        let button;

        // Need to figure out if this link is already a button and update it if it is.
        if (a.childNodes[0] instanceof HTMLButtonElement) {
          button = buttonInline(link.button_style, a.childNodes[0].innerText);
        } else {
          button = buttonInline(link.button_style, a.innerHTML);
        }

        ReactDOM.render(button, a);

        // We need to wrap the button in a <div> so that text-align works. This unfortunately means that buttons must be
        // on their own line and can not have other content flow around them, but we'll have to live with that.
        if (!(a.parentNode instanceof HTMLDivElement) || (a.parentNode.contentEditable === 'true')) {
          const div = document.createElement('div');
          a.parentNode.insertBefore(div, a);
          div.appendChild(a);
        }
      });

      onHandleInputChange({ target: editorRef.current });
    }

    onUpdateTextBlock({ ...textBlockObj, block_text: complexSpan1.innerHTML });
    setToolData({ showModal: false });

    hideLinkHelper();
  };

  const renderToolTypeComponent = () => {
    const {
      toolType,
      selectedString,
      mediaToolOnText,
      selectedMediaToolData,
    } = toolData;

    if (toolType === 'media') {
      return (
        <MediaToolBox
          params={props?.params}
          toggle={toggleModal}
          instanceId={instanceId}
          isEmailPage={isEmailPage}
          selectedString={selectedString}
          mediaToolOnText={mediaToolOnText}
          selectedTextBlockId={textBlockObj?.id}
          media={selectedMediaToolData}
          updateTextBlockData={updateTextBlockData}
          onMediaBoxSaveButton={onMediaBoxSaveButton}
        />
      );
    }

    return (
      <LinkToolBox
        params={props?.params}
        linkId={linkId}
        toggle={toggleModal}
        instanceId={instanceId}
        isEmailPage={isEmailPage}
        selectedString={selectedString}
        selectedTextBlockId={textBlockObj?.id}
        onLinkBoxSaveButton={onLinkBoxSaveButton}
      />
    );
  };

  const onHandleTextBlockOffline = async () => {
    try {
      const sendParams = {
        instance_id: instanceId,
        section_id: textBlockObj?.section_content_id,
        text_block_id: textBlockObj?.id,
        is_offline: !textBlockObj?.is_offline,
      };

      const response = await TextBlockApi.textBlockIsOffline(sendParams);
      if (undefined !== response) {
        const {
          data: { is_offline },
        } = response.data;
        onUpdateTextBlock({ ...textBlockObj, is_offline });
        displaySuccess(response.data.messages);
      }
    } catch (error) {
      displayError(`${error?.name}: ${error?.message}`);
    }
  };

  const onLineBreakChange = (e) => {
    const { value } = e;
    onUpdateTextBlock({ ...textBlockObj, line_break: value });
  };

  const onHandleShowSource = () => {
    if (sourceMode) {
      setInitialText(textBlockObj.block_text);
    }

    onUpdateTextBlock({ ...textBlockObj, sourceMode: !sourceMode });
  };

  const renderEditors = () => {
    if (editorType === 'raw' || (editorType === 'normal' && sourceMode)) {
      return (
        <textarea
          ref={editorRef}
          spellCheck="false"
          className="form-control"
          value={textBlockObj?.block_text}
          onChange={onHandleInputChange}
        />
      );
    }

    const editorStyles = { ...textStyles(), ...style };

    // Make sure that the constrast is high enough -- we don't want white text on a white background.
    if (site && editorStyles.color && editorStyles.backgroundColor) {
      const { sValue: textColor } = convertNegativeToHex(get(site, 'colors', []), editorStyles.color);
      const { sValue: backgroundColor } = convertNegativeToHex(get(site, 'colors', []), editorStyles.backgroundColor);

      editorStyles.color = textColor;

      let contrast;
      try {
        contrast = getContrast(textColor, backgroundColor);
      } catch (e) {
        contrast = 100;
      }
      if (textColor && backgroundColor && contrast < 1.35) {
        // Contrast is too low, make the background the "opposite".
        editorStyles.backgroundColor = getOppositeColor(backgroundColor);
      }
    }

    return (
      <>
        <div className="style-prefix-container">
          <div className="device-desktop">
            <div
              ref={editorRef}
              className="editor-value min-height-100px"
              contentEditable
              dangerouslySetInnerHTML={{ __html: initialText }}
              role="textbox"
              style={editorStyles}
              onDragEnter={onDragEnter}
              onDragOver={onDragOver}
              onCopy={onCopy}
              onPaste={onPaste}
              onClick={handleOnClickEditor}
              onKeyDown={handleOnKeyDownEditor}
              onScroll={() => setLinkHelper({ ...linkHelper, show: false })}
              onInput={onHandleInputChange}
              aria-label="editor"
              tabIndex={0}
            />
          </div>
        </div>
        {linkHelper.show && linkHelperElement()}
      </>
    );
  };

  const commonButtons = (
    <>
      {hasFeature('reorder') && index !== 0 && (
        <li className="common-button">
          <Button
            className="custom-simple-txt-btn"
            onClick={() => onHandleMove(index, true)}
          >
            <i className="fa-solid fa-arrow-up" />
          </Button>
        </li>
      )}
      {hasFeature('reorder') && countTextBlocks !== index + 1 && (
        <li className="common-button">
          <Button
            className="custom-simple-txt-btn"
            onClick={() => onHandleMove(index, false)}
          >
            <i className="fa-solid fa-arrow-down" />
          </Button>
        </li>
      )}
      {hasFeature('history') && user?.hasFeature('view_object_history') && (
        <li className="common-button">
          <Button
            className="custom-simple-txt-btn"
            onClick={() => setModal(true)}
          >
            <i className="fal fa-clock-rotate-left" />
          </Button>
        </li>
      )}
      {hasFeature('reorder') && (
        <li className="common-button">
          <Button className="custom-simple-txt-btn handle-sortable">
            <i className="fal fa-arrows-alt" />
          </Button>
        </li>
      )}
      {hasFeature('offline') && (
        <li className="common-button">
          <Button
            className={`custom-simple-txt-btn ${textBlockObj?.is_offline ? 'text-danger' : ''}`}
            onClick={onHandleTextBlockOffline}
          >
            <span className="custom-strike-through">
              {textBlockObj?.is_offline ? (
                <i className="fal fa-eye-slash" />
              ) : (
                <i className="fal fa-eye" />
              )}
            </span>
          </Button>
        </li>
      )}
      {hasFeature('delete') && (
        <li className="common-button">
          <Button
            className="custom-simple-txt-btn"
            onClick={onHandleDeleteEditorChild}
          >
            <i className="fal fa-trash" />
          </Button>
        </li>
      )}
    </>
  );

  const getColumnSize = () => {
    if (editorType === 'normal' && !hasFeature('media')) return '12';
    if (editorType === 'normal') return '9';
    return '12';
  };

  const editorElement = (
    <>
      {toolData.showModal ? renderToolTypeComponent() : ''}
      <div id={editorId} className="rich-text-editor">
        <Row className="mt-3">
          <Col className={`col-${getColumnSize()}`}>
            <Card className="editor-outer border rounded">
              {editorType === 'normal' && (
                <div className="editor-toolbar p-2 border-bottom">
                  <Row>
                    {hasFeature('text_style') && (
                      <Col className="col-2 pe-0">
                        <TextStyleListing
                          isDisabled={sourceMode}
                          style={textBlockObj?.text_style_id}
                          onChange={onChangeTextStyle}
                        />
                      </Col>
                    )}
                    <Col className="col-10">
                      <ul className="d-flex h-100">
                        {showSEOWarning && (
                          <li className="p-0">
                            <CustomTooltip name="SEOWarningTextEditor">
                              <Alert color="warning" className="mb-0" style={{ padding: '8px' }}>
                                <i className="fa-solid fa-message-exclamation" />
                              </Alert>
                            </CustomTooltip>
                          </li>
                        )}
                        <li>
                          <Button
                            className="custom-simple-txt-btn"
                            disabled={sourceMode}
                            onMouseDown={() => onHandleEditorTool('bold')}
                          >
                            <i className="fal fa-bold" aria-hidden="true" />
                          </Button>
                        </li>
                        <li>
                          <Button
                            className="custom-simple-txt-btn"
                            disabled={sourceMode}
                            onMouseDown={() => onHandleEditorTool('italic')}
                          >
                            <i className="fal fa-italic" aria-hidden="true" />
                          </Button>
                        </li>
                        <li>
                          <Button
                            className="custom-simple-txt-btn"
                            disabled={sourceMode}
                            onMouseDown={() => onHandleEditorTool('justifyLeft')}
                          >
                            <i className="fal fa-align-left" aria-hidden="true" />
                          </Button>
                        </li>
                        <li>
                          <Button
                            className="custom-simple-txt-btn"
                            disabled={sourceMode}
                            onMouseDown={() => onHandleEditorTool('justifyCenter')}
                          >
                            <i className="fal fa-align-center" aria-hidden="true" />
                          </Button>
                        </li>
                        <li>
                          <Button
                            className="custom-simple-txt-btn"
                            disabled={sourceMode}
                            onMouseDown={() => onHandleEditorTool('justifyRight')}
                          >
                            <i className="fal fa-align-right" aria-hidden="true" />
                          </Button>
                        </li>
                        <li>
                          <Button
                            className="custom-simple-txt-btn"
                            disabled={sourceMode}
                            onMouseDown={() => onHandleEditorTool('insertUnorderedList')}
                          >
                            <i className="fal fa-list-ul" aria-hidden="true" />
                          </Button>
                        </li>
                        <li>
                          <Button
                            className="custom-simple-txt-btn"
                            disabled={sourceMode}
                            onMouseDown={() => onHandleEditorTool('insertOrderedList')}
                          >
                            <i className="fal fa-list-ol" aria-hidden="true" />
                          </Button>
                        </li>
                        <li>
                          <Button
                            className="custom-simple-txt-btn"
                            disabled={sourceMode}
                            onMouseDown={onHandleDivider}
                          >
                            <i className="fal fa-window-minimize" aria-hidden="true" />
                          </Button>
                        </li>
                        <li>
                          <Button
                            className="custom-simple-txt-btn"
                            disabled={sourceMode}
                            onMouseDown={onClickLink}
                          >
                            <i className="fal fa-link" aria-hidden="true" />
                          </Button>
                        </li>
                        {hasFeature('media') && (
                          <li>
                            <Button
                              className="custom-simple-txt-btn"
                              disabled={sourceMode}
                              onMouseDown={() => onClickMedia(textBlockObj?.media)}
                            >
                              <i className="fal fa-file-image" aria-hidden="true" />
                            </Button>
                          </li>
                        )}
                        <li>
                          <Button
                            className={`custom-simple-txt-btn ${sourceMode ? 'active-btn' : ''}`}
                            onMouseDown={() => onHandleShowSource()}
                          >
                            <i className="fal fa-code" aria-hidden="true" />
                          </Button>
                        </li>
                        {editorType === 'normal' && hasFeature('ai') && (
                          <li>
                            <Button
                              className={`custom-simple-txt-btn ${sourceMode ? 'active-btn' : ''}`}
                              onMouseDown={() => setAI(!ai)}
                            >
                              <i className="fa-light fa-head-side-brain" aria-hidden="true" />
                            </Button>
                          </li>
                        )}
                        {commonButtons}
                      </ul>
                    </Col>
                  </Row>
                </div>
              )}
              {editorType === 'raw' && (
                <div className="editor-toolbar p-2 border-bottom">
                  <Row>
                    <Col>
                      <ul className="custom-ul d-flex h-100">
                        {commonButtons}
                      </ul>
                    </Col>
                  </Row>
                </div>
              )}
              {ai && (
                <AIPromptWriter
                  onAppendContent={(string) => {
                    onUpdateTextBlock({ ...textBlockObj, block_text: textBlockObj.block_text + string });
                  }}
                />
              )}
              <div className="editor-container p-2">
                {renderEditors()}
              </div>
            </Card>
          </Col>
          {hasFeature('media') && editorType === 'normal' && (
            <Col className="col-3">
              <Card className="border rounded h-100">
                <CardBody>
                  <Row className="custom-btm-border">
                    <Col className="col-10">
                      <h5>Media for this textblock</h5>
                    </Col>
                    <Col className="col-2 text-end">
                      {textBlockObj?.media != null && textBlockObj?.media?.id && (
                        <Button
                          className="custom-simple-txt-btn"
                          size="xs"
                          onClick={onHandleDeleteMedia}
                        >
                          <b>
                            <i className="fal fa-trash" />
                          </b>
                        </Button>
                      )}
                    </Col>
                  </Row>
                  <Row className="custom-btm-border">
                    <Col
                      className="col-12 show-text-block-media"
                      onClick={() => onClickMedia(textBlockObj?.media)}
                    >
                      <div className="preview-original-media">
                        {displayMedia(textBlockObj?.media)}
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          )}
          {hasFeature('line_breaks') && (
            <Col className="col-3">
              <Label className="mt-1">
                <b>Line breaks after textblock:</b>
              </Label>
              <Select
                value={getSelectedOption(
                  lineBreaksOptions,
                  textBlockObj?.line_break,
                )}
                options={lineBreaksOptions}
                onChange={onLineBreakChange}
                styles={selectColorStyles}
              />
            </Col>
          )}
        </Row>
      </div>
    </>
  );

  if (hasFeature('no_card')) {
    return editorElement;
  }

  return (
    <>
      <Card className="mb-4">
        <CardBody>
          {editorElement}
        </CardBody>
      </Card>
      {modal && (
        <SectionHistory
          id={textBlockObj?.id}
          modal={modal}
          setModal={setModal}
          onRestore={(e) => {
            onUpdateTextBlock({ ...textBlockObj, block_text: e?.original?.block_text });
            onHandleRestore();
            setModal(false);
          }}
        />
      )}
    </>
  );
}

export default withRouter(RichTextEditor);
