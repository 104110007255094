/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Col } from 'reactstrap';

function Colxx(props) {
  return <Col {...props} widths={['xxs', 'xs', 'sm', 'md', 'lg', 'xl', 'xxl']} />;
}
function Separator() {
  return <></>;
}

export { Colxx, Separator };
