import { isInteger } from 'lodash-es';
/* Get status of expression is true or false */

const SearchExpressionValidator = (event, searchParams) => {
  const stack = [];
  const map = { '(': ')' };
  const expressionStr = event.currentTarget.value;
  const stringToArray = expressionStr.split('');
  const matchOperands = expressionStr.match(/\d+/g);
  const fieldRowCount = searchParams.length;
  const errorMessage = { message: 'Invalid Expression!', activeError: false };
  // const matchOperators = expressionStr.replace(
  // /AND|and/g, '+').replace(/OR|or/g, '-').match(/[+]|[-]/g);
  const matchOperators = expressionStr.replace(/AND/g, '+').replace(/OR/g, '-').match(/[+]|[-]/g);
  /* Checking parenthesis are valid or not */
  for (let i = 0; i < stringToArray.length; i += 1) {
    const beforeIndex = Number(i) - 1;
    if (stringToArray[i] === '(') {
      if (!isInteger(stringToArray[beforeIndex])) {
        stack.push(stringToArray[i]);
        if (stack.length > 0) {
          errorMessage.message = 'Opening parenthesis must have at least 2 operands and 1 operator!';
          errorMessage.activeError = true;
        } else {
          errorMessage.activeError = false;
        }
      } else {
        errorMessage.message = 'Before an opening parenthesis, there must have an operator!';
        errorMessage.activeError = true;
      }
    } else if (isInteger(stringToArray[i])) {
      if (stringToArray[beforeIndex] === '(') {
        errorMessage.message = 'Parenthesis must have at least 2 operands and 1 operator!';
        errorMessage.activeError = true;
      }
    } else if (stringToArray[i] === ')') {
      const last = stack.pop();
      if (stringToArray[i] !== map[last]) {
        errorMessage.activeError = true;
      } else if (stack.length > 0) {
        errorMessage.activeError = true;
      } else {
        errorMessage.activeError = false;
      }
    }
  }

  /* Checking the input expression is valid or not */
  if (matchOperands === null && matchOperators === null) {
    errorMessage.message = 'Custom logic should not be blank!';
    errorMessage.activeError = true;
  } else if (matchOperands === null && matchOperators !== null) {
    errorMessage.message = 'The expression must have operands!';
    errorMessage.activeError = true;
  } else if (
    (Math.max(...matchOperands) > fieldRowCount) || (Math.min(...matchOperands) === 0)
  ) {
    errorMessage.message = 'Digit should be between the # number and not equal to 0!';
    errorMessage.activeError = true;
  } else if (matchOperators !== null && matchOperands !== null) {
    if (
      (matchOperators.length > matchOperands.length)
      || (matchOperators.length === matchOperands.length)
    ) {
      errorMessage.message = 'The expression must have 1 operator between the 2 operands!';
      errorMessage.activeError = true;
    } else if (matchOperators.length !== matchOperands.length - 1) {
      errorMessage.message = 'The operator should be less than 1 to operands!';
      errorMessage.activeError = true;
    }
  } else if (matchOperands !== null && matchOperands.length > 1 && matchOperators === null) {
    errorMessage.message = 'Between 2 operands there should be a 1 operator!';
    errorMessage.activeError = true;
  }

  /* Return expression error status */
  if (errorMessage.activeError === true) {
    const returnData = { errorStatus: errorMessage.activeError, errorMessage, expressionStr };
    return (returnData);
  }
  return (false);
};

export default SearchExpressionValidator;
