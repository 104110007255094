import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { InputGroup, Input, InputGroupText, ButtonDropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import Select from 'react-select';
import { useGetSiteBriefingsQuery } from '../api/apiSlice';
import withRouter from '../helpers/withRouter';
import RadioButton from '../components/common/radioButton';

const AIPromptWriter = (props) => {
  const {
    onAppendContent,
  } = props;

  const { siteId } = useParams();

  const [settingsDropdownOpen, setSettingsDropdownOpen] = useState(false);
  const [provider, setProvider] = useState('openai');
  const [elementId] = useState(crypto.randomUUID());
  const [aiInProgress, setAIInProgress] = useState(false);
  const [aiCustomText, setAICustomText] = useState('');
  const [number, setNumber] = useState(1);
  const [format, setFormat] = useState('headline');
  const [content, setContent] = useState('headline');
  const { data: briefings } = useGetSiteBriefingsQuery({ siteId });

  const briefing = briefings ? briefings[0] : null;

  useEffect(() => {
    // Was using refs but it wasn't working...
    setTimeout(() => document.querySelector(`#aiInput${elementId}`)?.focus(), 200);
  }, []);

  const numberOptions = [
    { label: '1', value: 1 },
    { label: '2', value: 2 },
    { label: '3', value: 3 },
    { label: '4', value: 4 },
    { label: '5', value: 5 },
  ];

  const formatOptions = [
    { label: `headline${number > 1 ? 's' : ''}`, value: 'headline' },
    { label: `sentence${number > 1 ? 's' : ''}`, value: 'sentence' },
    { label: `paragraph${number > 1 ? 's' : ''}`, value: 'paragraph' },
    { label: `bullet point${number > 1 ? 's' : ''}`, value: 'bullet' },
  ];

  const contentOptions = [
    { value: 'big_promise',    label: 'our big promise' },
    { value: 'intro',          label: 'a compelling introduction to the business' },
    { value: 'products',       label: 'the products the business provides' },
    { value: 'owners',         label: 'the business owner(s)' },
    { value: 'history',        label: 'the history of the business' },
    { value: 'today',          label: 'the business today' },
    { value: 'community',      label: 'the business\' community involvement' },
    { value: 'team',           label: 'our team' },
    { value: 'differentiator', label: 'the reasons to choose the business' },
  ];

  if (briefing) {
    briefing.info.product_pages.forEach((page, index) => {
      contentOptions.push({
        value: `product_${index}_experience`,
        label: `our experience related to ${page.name}`,
      });
      contentOptions.push({
        value: `product_${index}_expertise`,
        label: `our expertise related to ${page.name}`,
      });
      contentOptions.push({
        value: `product_${index}_why_choose_us`,
        label: `why to choose us for ${page.name}`,
      });
    });
  }

  const generateContent = async () => {
    if (aiInProgress) {
      return;
    }

    setAIInProgress(true);

    const params = { provider };

    if (briefing) {
      params.site_id = siteId;
      params.number = number;
      params.format = format;
      params.content = content;
    } else {
      params.prompt = aiCustomText;
    }

    const response = await fetch(`/api/v1/ai_text?${new URLSearchParams(params)}`);
    // eslint doesn't know about TextDecoderStream yet...
    // eslint-disable-next-line no-undef
    const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();

    while (true) {
      const { value, done } = await reader.read();
      if (done) {
        break;
      }

      onAppendContent(value.replace(/[\r\n]+/, '<br><br>') + ' ');
    }

    setAIInProgress(false);
  };

  const button = (
    <InputGroupText
      style={{ cursor: aiInProgress ? 'default' : 'pointer' }}
      onClick={generateContent}
    >
      <i className={`fal fa-fw ${aiInProgress ? 'fa-spinner fa-spin' : 'fa-arrow-right'}`} aria-hidden="true" />
    </InputGroupText>
  );

  return (
    <div className="d-flex align-items-center p-2 border-bottom">
      {briefing ? (
        <>
          <div className="d-inline-block">
            Write
            &nbsp;
          </div>
          <div className="d-inline-block" style={{ width: '140px' }}>
            <Select
              options={numberOptions}
              onChange={(e) => setNumber(e.value)}
              value={numberOptions.find((o) => o.value === number)}
            />
          </div>
          &nbsp;
          <div className="d-inline-block" style={{ width: '180px' }}>
            <Select
              options={formatOptions}
              onChange={(e) => setFormat(e.value)}
              value={formatOptions.find((o) => o.value === format)}
            />
          </div>
          &nbsp;
          on
          &nbsp;
          <div className="d-inline-block" style={{ width: '340px' }}>
            <Select
              options={contentOptions}
              onChange={(e) => setContent(e.value)}
              value={contentOptions.find((o) => o.value === content)}
            />
          </div>
          &nbsp;
          <div className="d-inline-block">
            <InputGroup>
              {button}
            </InputGroup>
          </div>
        </>
      ) : (
        <InputGroup>
          <Input
            id={`aiInput${elementId}`}
            placeholder='Write a prompt...'
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                generateContent();
              }
            }}
            value={aiCustomText}
            onChange={(e) => setAICustomText(e.target.value)}
          />
          {button}
        </InputGroup>
      )}
      <div className="d-inline-block ps-4" style={{ marginLeft: 'auto' }}>
        <ButtonDropdown isOpen={settingsDropdownOpen} toggle={() => setSettingsDropdownOpen(!settingsDropdownOpen)}>
          <DropdownToggle caret className="custom-simple-txt-btn">
            <i className="fal fa-cog" />
          </DropdownToggle>
          <DropdownMenu className="p-2 border" style={{ width: '300px' }}>
            Provider:
            <br /><br />
            <RadioButton
              title="OpenAI (ChatGPT)"
              checked={provider === 'openai'}
              onHandleChange={() => setProvider('openai')}
            />
            <br />
            <RadioButton
              title="PaLM (Google)"
              checked={provider === 'palm'}
              onHandleChange={() => setProvider('palm')}
            />
          </DropdownMenu>
        </ButtonDropdown>
      </div>
    </div>
  );
};

export default withRouter(AIPromptWriter);
