import Site from '../../../../api/trash';
import {
  setError, GET_TRASH_LIST, GET_RSTORE_OBJ, SHOW_REPLACE_SKIP_POPUP, GET_ACTIONMESSAGE,
} from '../../../actions';

export const setTrashData = (payload) => ({
  type: GET_TRASH_LIST,
  payload,
});

export const setRestoreDelete = (payload) => ({
  type: GET_RSTORE_OBJ,
  payload,
});
export const setActionMessage = (payload) => ({
  type: GET_ACTIONMESSAGE,
  payload,
});
export const setReplaceSkipPopup = (showPopup, payload) => ({
  type: SHOW_REPLACE_SKIP_POPUP,
  showPopup,
  payload,
});

export const fetchTrashAction = (params) => async (dispatch) => {
  try {
    const response = await Site.trashIndex(params);
    const { data } = response.data;
    dispatch(setReplaceSkipPopup(false, {}));
    dispatch(setTrashData(data));
  } catch (err) {
    dispatch(setTrashData([]));
    dispatch(setError(err));
  }
};

export const recordRestoreAction = (params) => async (dispatch) => {
  try {
    const response = await Site.trashRestore(params);
    const { data, messages } = response.data;
    dispatch(setActionMessage(messages));
    if (data) {
      dispatch(setReplaceSkipPopup(false, {}));
      dispatch(setRestoreDelete(params));
    } else {
      dispatch(setReplaceSkipPopup(true, params));
    }
  } catch (err) {
    dispatch(setRestoreDelete({}));
    dispatch(setError(err));
  }
};

export const recordDeleteAction = (params) => async (dispatch) => {
  try {
    const response = await Site.trashDelete(params);
    const { data, messages } = response.data;
    dispatch(setActionMessage(messages));
    dispatch(setRestoreDelete(data));
    dispatch(setReplaceSkipPopup(false, {}));
  } catch (err) {
    dispatch(setRestoreDelete({}));
    dispatch(setError(err));
  }
};
