/* eslint-disable no-underscore-dangle */
import client from '../../../api';

export default {
  emailIndex: (id) => client.get(`/instances/${id}/email_pages`),
  emailCreate: (params) => client.post(`/sites/${params.site_id}/pages`, params),
  emailDelete: (params) => client.delete(`/sites/${params.site_id}/pages/${params.id}`, params),
  emailSeriesListing: (params) => client.get(`/instances/${params}/email_series`),
  emailSeriesFetch: (params) => client.get(`/instances/${params.instance_id}/email_series/${params.id}`),
  emailSeriesDelete: (params) => client.delete(`/instances/${params.instance_id}/email_series/${params.id}`),
  emailSeriesUpdate: (params) => client.put(`/instances/${params.instance_id}/email_series/${params.id}`, params),
  bulkEmail: (params) => client.post('/bulk_emails', params),
  backgroundJobCancel: (params) => client.post(`instances/${params.instance_id}/job_progress/${params.id}/cancel`, params),
  backgroundJobProgress:(params, config) => client.get(`instances/${params.instance_id}/job_progress`, {params, ...config}),
  jobProgressReport:(params) => client.get(`instances/${params.instance_id}/job_progress/${params.id}/report`),
  emailSeriesSubscribeMember: (params) => client.post(`instances/${params.instance_id}/searches/${params.id}/subscribe_search_members_to_email_series`, params),
};
