import client from '../../../api';

export default {
  uploadCSVFile: (params) => client.post(`instances/${params.instance_id}/member_import/upload_csv`, params.file),
  importMember: (params) => client.post(`instances/${params.instance_id}/member_import`, params),
  progressJob: (params, config) => client.get(
    `instances/${params.instance_id}/job_progress/${params.job_id}`,
    config,
  ),
};
