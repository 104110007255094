import React, { useEffect } from 'react';
import { Container, Row, Card, CardBody, CardTitle, Button } from 'reactstrap';
import { Colxx } from '../../components/common/CustomBootstrap';
import { setPageTitle } from 'Utils';
import { useUpdateUserMutation } from '../../api/apiSlice';
import { useUser } from '../../hooks';

function ClientTerms() {
  setPageTitle('Client Terms');

  const user = useUser();
  const [updateUser] = useUpdateUserMutation();

  useEffect(() => {
    if (user?.agreed_to_terms)  {
      window.location = '/';
    }
  }, [user]);

  return (
    <Container className="h-100 Login">
      <Row className="h-100">
        <Colxx xxs="12" md="10" className="mx-auto my-auto">
          <Card>
            <CardBody>
              <CardTitle className="mb-4">
                <h2>Terms and Conditions &amp; Customer Software License</h2>
              </CardTitle>

              <h3>Web Genius Online Relationship Marketing System</h3>

              <h4>1. Your right to use the Software</h4>
              <p>
                  Web Genius grants to you a personal, non-exclusive and non-transferable right to access and use the Software at the Website to create, post and update your own business web pages and to communicate with your own customers in the manner supported by the Software during the time that you receive the Services, but only on the terms of this license and so long as you continue to pay the associated charges and comply with the terms of this license.
              </p>

              <h4>2. Fees</h4>
              <p>
                  For each month or part-month that you receive the Services you must pay to Web Genius the fees (plus taxes if any) at the level set by Web Genius. If you do not pay these fees by the due date for payment, Web Genius may at its discretion terminate this license and cease supplying the Services to you.
              </p>
              <p>
                  Web Genius may adjust the level of fees from time to time at its sole discretion and will give you at least one month’s prior notice of fee changes by
                  <ul>
                      <li>posting the new fees on the Web Genius website; or</li>
                      <li>detailing on your invoice.</li>
                  </ul>
              </p>

              <h4>3. Your Obligations</h4>
              <p>
                  You must, and those who you are responsible for:
                  <ul>
                      <li>not copy, reverse engineer, decompile, or disassemble the Software (whether in an attempt to duplicate the Software or imitate any of its functions, or otherwise);</li>
                      <li>not alter or modify the Software, or any copyright, trade mark or other proprietary notice on the Software, in any way;</li>
                      <li>not sell, lease, sub-license, lend, assign, or transfer, in whole or in part, or provide unlicensed third parties access to the Software or any updates or other releases of the Software that Web Genius may (but is not obliged to) release from time to time; and</li>
                      <li>use all reasonable efforts to protect the Software from unauthorised use, reproduction, distribution or publication and keep the Software confidential.</li>
                  </ul>
              </p>

              <h4>4. Representations and Warranties</h4>
              <p>
                  You agree that Web Genius, and anyone that Web Genius is responsible for, is not liable to you at law, by statute, in equity or otherwise arising from the relationship between you and Web Genius for any loss, damage or injury incurred by you arising directly or indirectly from the Software or this license.
              </p>
              <p>
                  If, despite the other provisions of this license, Web Genius is found to be liable to you then its liability for any single event or series of related events is limited to an amount equal to your License Fees for one month.
              </p>
              <p>
                  All warranties, representations or conditions whether implied by statute law, trade, custom or otherwise which would otherwise apply to the Software are expressly excluded to the fullest extent permitted by law.
              </p>

              <h4>5. Termination</h4>
              <p>
                  Web Genius may immediately terminate this license and may cease supplying the Services to you if you breach any material term of this license.
              </p>
              <p>
                  Upon termination of this license you must immediately destroy or return to Web Genius all copies of any information or documentation that it has made available to you in relation to this license and/or the Software.
              </p>

              <h4>6. Your Confirmations</h4>
              <p>
                  You confirm that:
                  <ul>
                      <li>You have exercised your own judgment in respect of the Software and have not relied on any representation, promise, warranty or undertaking by Web Genius or its agents, or anyone Web Genius is responsible for;</li>
                      <li>you do not own the intellectual property in the Software and that neither you nor anyone that you are responsible for has, or will acquire, any intellectual property rights in the Software and that this license does not transfer any title or ownership in the Software to you;</li>
                      <li>there is no obligation for Web Genius to provide you with any modifications or updates of the Software, but if it does, the modification and updates will be subject to this agreement;</li>
                  </ul>
              </p>

              <h4>7. Confidentiality</h4>
              <p>
                  This license, the Software, and any other information made available by Web Genius in relation to this license and the Software is confidential information. You must keep that information confidential at all times, and ensure that the people you are responsible for keep that information confidential on the same terms, until that confidential information becomes generally available to, and known by, the public other than due to a breach of this clause.
              </p>

              <h4>8. No Waiver</h4>
              <p>
                  Failure by Web Genius at any time to enforce the provisions of this license will not be a waiver of any of Web Genius&apos; rights under this license.
              </p>

              <h4>9. Changes</h4>
              <p>
                  Web Genius may amend the terms of this license by placing a notice of the amendments on its website.
              </p>

              <h4>10. Express Rights</h4>
              <p>
                  The exercise by Web Genius of any express right set out in this license is without prejudice to any other rights, powers or remedies available to them in contract, at law or in equity including any rights, powers or remedies which would be available to that party if the express right was not set out in this agreement.
              </p>

              <h4>11. Law</h4>
              <p>
                  This agreement is governed by the laws of New Zealand and the parties submit to the non-exclusive jurisdiction of these courts.
              </p>

              <h4>12. Defined Terms and Interpretation</h4>
              <p>
                  In this license:
                  <ul>
                      <li>Person includes a corporation sole and also a body of person, whether corporate or unincorporated;</li>
                      <li>Services means access, via a hosting platform and the Internet, to the Software and to material created, modified and/or stored by you using the Software;</li>
                      <li>Software means the Web Genius online relationship marketing system software developed by Web Genius and offered by itself and its authorised agents. This definition extends to all associated branding, documentation, upgrades, updates, modifications or enhancements made available by Web Genius (if any);</li>
                      <li>Web Genius means The Web Genius Limited;</li>
                      <li>Website means the site on which the Software and Services are available to you;</li>
                      <li>and You, Your means the person who accepts the terms of this license.</li>
                  </ul>
              </p>

              <p>
                <hr />
              </p>

              <Button
                color="success"
                className="button-sm common-success-button"
                onClick={() => {
                  updateUser({ id: user.id, agreed_to_terms: true });
                }}
              >
                Accept
              </Button>
            </CardBody>
          </Card>
        </Colxx>
      </Row>
    </Container>
  );
}

export default ClientTerms;
