import client from '../../../api';

export default {
  fragmentIndex: (params) => client.get(
    `/sites/${params.site_id}/template_fragments?instance_id=${params.instance_id}`,
  ),
  fragmentCreate: (params) => client.post(`/sites/${params.site_id}/template_fragments`, params),
  fragmentDelete: (params) => client.delete(`/sites/${params.site_id}/template_fragments/${params.id}`),
  fragmentUpdate: (params) => client.put(`/sites/${params.site_id}/template_fragments/${params.id}`, params),
  fragmentGet: (params) => client.get(`/sites/${params.site_id}/template_fragments/${params.id}`),
};
