import React, { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import { setPageTitle } from '../../../../../Utils';
import confirm from '../../../components/common/confirm';
import TableComponent from '../../../components/common/Table';
import DateTimeFormat from '../../../components/common/DateTimeFormat';
import HeaderComponent from '../../../components/common/header-component';
import {
  settingRoutes, siteCenter, replacePathParams, getPageEditUrl,
} from '../../../constants/routes';
import Pagination from '../../../components/common/custom-pagination';
import withRouter from '../../../helpers/withRouter';
import { useGetPageVersionsQuery, usePageVersionDeleteMutation } from '../../../api/apiSlice';
import { useNavigate } from 'react-router';
import { Button, Card, CardBody } from 'reactstrap';

function PageHistory(props) {
  const {
    params,
    params: {
      pageNo, pageId, pageVersionId, siteId,
    },
  } = props;

  const [pageSize, setPageSize] = useState(10);
  const [page, setPage] = useState(pageNo);
  const navigate = useNavigate();
  const [pageVersionDelete] = usePageVersionDeleteMutation();
  const { data: { page_versions: pageVersions, pagination } = [] } = useGetPageVersionsQuery(
    {
      pageId, siteId, instanceId: params?.instanceId, page, page_size: pageSize,
    },
  );

  useEffect(() => {
    setPageTitle('Page History');
  });

  const goToHistoryPage = (e) => {
    navigate(
      replacePathParams(
        siteCenter.pageVersionHistory,
        [
          { key: 'pageId', value: pageId },
          { key: 'pageNo', value: e },
          { key: 'pageVersionId', value: pageVersionId },
        ],
        props,
      ),
    );
  };

  const onEditHandler = (version) => {
    const apiParams = { ...params, id: version };
    navigate(getPageEditUrl(apiParams.id, apiParams.pageId, 'web', props));
  };

  const onDeleteHandler = async (version) => {
    const result = await confirm({
      title: (<strong>Confirm!</strong>),
      message: 'Are you sure?',
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });
    if (result) {
      const apiParams = { ...params, id: version };
      pageVersionDelete(apiParams);
    }
  };

  const dataTableColumns = () => ([
    {
      Header: 'Version Number',
      id: 'version_number',
      accessor: (d) => Number(d.version_number),
    },
    {
      Header: 'Page Title',
      id: 'page_title',
      accessor: (d) => d.page_title,
    },
    {
      Header: 'Name',
      id: 'Page Name',
      accessor: (d) => d.name,
    },
    {
      Header: 'Created at',
      id: 'created_at',
      accessor: (d) => <DateTimeFormat datetime={d.created_at} localeString={DateTime.DATETIME_MED} />,
    },
    {
      Header: 'Created by',
      id: 'created_by',
      accessor: (d) => d.created_by,
    },
    {
      Header: 'Updated by',
      id: 'modify_by',
      accessor: (d) => d.modify_by,
    },
    {
      Header: 'Updated at',
      id: 'updated_at',
      accessor: (d) => <DateTimeFormat datetime={d.updated_at} localeString={DateTime.DATETIME_MED} />,
    },
    {
      Header: 'Action',
      id: 'action',
      accessor: (d) => (
        <>
          <Button className="custom-simple-icon" color="primary" size="xs" onClick={() => onEditHandler(d.id)}>
            <i className="fal fa-pen" />
          </Button>
          {' '}
          <Button
            className="custom-simple-icon"
            color="danger"
            size="xs"
            // eslint-disable-next-line eqeqeq
            disabled={d.id == pageVersionId}
            onClick={() => onDeleteHandler(d.id)}
          >
            <i className="fal fa-trash" />
          </Button>
        </>
      ),
    },
  ]);

  const onChangePage = (e) => {
    setPage(e);
    goToHistoryPage(e);
  };

  const changePageSize = (size) => {
    setPageSize(size);
  };

  const editPageVersionUrl = getPageEditUrl(pageVersionId, pageId, 'web', props);

  const siteCenterUrl = replacePathParams(siteCenter.pageList, [], props);

  const staticBreadcrumbData = [
    { name: 'Dashboard', url: replacePathParams(settingRoutes.dashboard, [], props) },
    { name: 'Site Centre', url: siteCenterUrl },
    { name: 'Page', url: editPageVersionUrl },
    { name: 'History', url: '' },
  ];

  if (pageVersions && pagination) {
    return (
      <>
        <HeaderComponent setPath={{
          headingName: 'Page History',
          addNewBtnName: '',
          addNewPath: '',
          backBtnName: 'Back',
          backToPath: editPageVersionUrl,
          showBreadcrumb: false,
          staticBreadcrumbData,
        }}
        />
        <Card className="mb-4">
          <CardBody>
            {pagination.count
              ? (
                <Pagination
                  type={['version', 'versions']}
                  currentPage={Number(page)}
                  records={pageVersions}
                  onChangePage={onChangePage}
                  getPagination={pagination}
                  pageSize={pageSize}
                  changePageSize={changePageSize}
                />
              )
              : ''}
            <TableComponent
              columns={dataTableColumns()}
              data={pageVersions}
            />
          </CardBody>
        </Card>
      </>
    );
  }
  return <span>Loading</span>;
}

export default withRouter(PageHistory);
