var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createStore } from './rootStore';
import { apiSlice } from './dashboard/src/api/apiSlice';
export const addRecentItem = (item) => {
    const store = createStore();
    const w = window;
    w.recentItems || (w.recentItems = {
        lastIdentity: null,
        timeout: null,
        items: [],
        push: (item) => {
            const ts = new Date().getTime();
            // The type should always be set, but the item_id might be falsey, ie null or undefined, that's why we only do a
            // double-equals check below rather than a threequals.
            // eslint-disable-next-line eqeqeq
            const index = w.recentItems.items.findIndex((i) => item.type === i.type && item.item_id == i.item_id);
            if (index > -1) {
                w.recentItems.items[index].ts = ts;
                w.recentItems.items[index].path = window.location.pathname;
            }
            else {
                w.recentItems.items.push(Object.assign(Object.assign({}, item), { ts, path: window.location.pathname }));
            }
            // sort by timestamp and remove all but the most recent 30 items
            w.recentItems.items = w.recentItems.items.sort((a, b) => (b.ts || 0) > (a.ts || 0) ? 1 : -1).slice(0, 30);
            // only send the list if it has changed. we figure that out by creating an "identity" which is sort of a hash
            // of the list's contents.
            const identity = w.recentItems.items.map((i) => `${i.type}-${i.item_id}`).join(',');
            if (identity !== w.recentItems.lastIdentity) {
                w.recentItems.lastIdentity = identity;
                // the list has changed, queue up the send
                clearTimeout(w.recentItems.timeout);
                w.recentItems.timeout = setTimeout(() => w.recentItems.send(), 1000);
            }
        },
        send: () => __awaiter(void 0, void 0, void 0, function* () {
            const userWrapper = yield store.dispatch(apiSlice.endpoints.getUserProfile.initiate({}));
            const user = userWrapper.data.data;
            if (!(user === null || user === void 0 ? void 0 : user.id)) {
                return;
            }
            store.dispatch(apiSlice.endpoints.updateRecentItems.initiate({
                userId: user.id,
                items: w.recentItems.items,
            }));
        }),
    });
    w.recentItems.push(item);
};
