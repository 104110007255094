import React, { useState, Fragment } from 'react';
import {Container, Row, Col, Card, CardBody, Button} from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { map, find, isEmpty } from 'lodash-es';
import { displayError } from '../../../../../Utils';
import confirm from '../../../components/common/confirm';
import { dataCenter, replacePathParams } from '../../../constants/routes';
import { useSelector, useDispatch } from 'react-redux';
import { deleteMember } from '../../../redux/members/actions';
import CustomButton from '../../../components/common/button';
import withRouter from '../../../helpers/withRouter';
import { DateTime } from 'luxon';
import DateTimeFormat from '../../../components/common/DateTimeFormat';
import MemberEmailLogs from './MemberEmailLogs';

function MemberCard(props) {
  const { instanceId, membersRecord, updatedMemberData } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [member, setMember] = useState(null);

  const { instance } = useSelector((state) => state.adminStore.instanceReducer);
  const { fieldsData } = useSelector((state) => state.adminStore.dataCenterReducer);

  // These are the fields that are used in the header of the card, we don't want to repeat them in the body.
  const headlineFields = [instance?.firstname_field_id, instance?.lastname_field_id, instance?.email_field_id];

  const onHandleDeleteMember = async (userId) => {
    try {
      const result = await confirm({
        title: <b>Confirm!</b>,
        message: 'Are you sure?',
        confirmText: 'Yes',
        confirmColor: 'success',
        cancelColor: 'btn btn-danger',
        cancelText: 'No',
      });
      if (result) {
        await dispatch(deleteMember(userId, updatedMemberData, instanceId));
      }
    } catch (error) {
      displayError(`${error?.name}: ${error?.message}`);
    }
  };

  const onHandleEdit = (userId) => {
    navigate(replacePathParams(dataCenter.editMember, [
      { key: 'memberId', value: userId },
    ], props));
  };

  const formatFieldValue = (field, value) => {
    switch (field.attributes.field_type) {
    case 'date':
      return <DateTimeFormat datetime={value[0]} localeString={DateTime.DATE_MED_WITH_WEEKDAY} />;
    default:
      return value.join(', ');
    }
  };

  const onHandleEmailLog = (m) => {
    setMember(m);
  };

  /* Get member data */
  const getMemberData = () => map(membersRecord, (member) => (
    <Col className="ps-0 pe-0 mb-4" key={member.id}>
      <Card>
        <CardBody>
          <Container fluid>
            <Row>
              <Col>
                <h2 className="mb-3 d-inline-block">{member.fullname}</h2>
                {member.email && (
                  <>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <h4 style={{ display: 'inline' }}>
                      <a href={`mailto:${member.email}`}>
                        {member.email}
                      </a>
                    </h4>
                  </>
                )}
              </Col>
              <Col className="text-end">
                <CustomButton
                  size="xs"
                  color="success"
                  icon="fal fa-user-edit"
                  title="Edit Member"
                  className="custom-simple-icon"
                  onClick={() => onHandleEdit(member.id)}
                />
                {' '}
                <CustomButton
                  size="xs"
                  color="danger"
                  icon="fal fa-trash-alt"
                  title="Delete Member"
                  className="custom-simple-icon"
                  onClick={() => onHandleDeleteMember(member.id)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                {fieldsData.map((field) => {
                  if (headlineFields.indexOf(field.id) > -1) {
                    return null;
                  }

                  const value = find(member.data, (d) => d.key === field.id)?.value;

                  if (value && value.length > 0) {
                    return (
                      <Fragment key={field.id}>
                        <b>{field.attributes.field_name}</b>:
                        {' '}
                        {formatFieldValue(field, value)}
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                      </Fragment>
                    );
                  }

                  return null;
                })}
                <hr />
              </Col>
            </Row>
            <Row>
              <Col>
                <Button
                  onClick={() => onHandleEmailLog(member)}
                  className="custom-simple-txt-btn cta-log-more-info"
                >
                  <span><b>Emails sent:</b>{` ${member.stats.emails_sent}`}</span>
                </Button>
              </Col>
            </Row>
          </Container>
        </CardBody>
      </Card>
    </Col>
  ));

  return (
    <>
      {getMemberData()}
      {!isEmpty(member) && <MemberEmailLogs member={member} toggle={() => setMember(null)}/>}
    </>
  );
}

export default withRouter(MemberCard);
