import { displaySuccess } from '../../../../../Utils';
import MemberApi from '../../../api/member_create';
import { DELETE_MEMBER, GET_USER_DETAILS } from '../../actions';

const successDeleteMember = (payload) => ({
  type: DELETE_MEMBER,
  payload,
});

export const deleteMember = (userId, updatedMemberData, instanceId) => async (dispatch) => {
  const response = await MemberApi.deleteMember({ instance_id: instanceId, userId });
  if (undefined !== response) {
    const { messages, data } = response.data;
    dispatch(successDeleteMember(data.id));
    updatedMemberData(true);
    displaySuccess(messages);
  }
};

const successFetchUserDetail = (payload, getFieldIds) => ({
  type: GET_USER_DETAILS,
  payload,
  getFieldIds,
});

export const getMemberDetails = (memberId, instanceId) => async (dispatch) => {
  const response = await MemberApi.getMemberById({ instance_id: instanceId, memberId });
  if (undefined !== response) {
    const { data } = response.data;
    const { attributes } = data;
    const getData = attributes.db_field.map((item) => (
      {
        id: item.id,
        type: data.type,
        attributes: {
          field_name: item.field_name,
          field_type: item.field_type,
          position: item.position,
          values: item.value,
          short_name: item.short_name,
          db_field_values: item.db_field_values,
          default: item.default,
          is_special: item.is_special,
          is_editable: item.is_editable,
        },
      }
    ));
    const getFieldIds = getData.map((items) => ({ key: items.id, value: items.attributes.values }));
    dispatch(successFetchUserDetail(getData, getFieldIds));
  } else {
    dispatch(successFetchUserDetail([], []));
  }
};
