import TemplateApi from '../../../api/template';
import { displaySuccess } from '../../../../../Utils';
import { SET_TEMPLATES, DUPLICATE_TEMPLATE, DELETE_TEMPLATE } from '../../actions';

const actionType = (payload, type) => ({
  type,
  payload,
});

export const getTemplateListing = (params) => async (dispatch) => {
  try {
    const response = await TemplateApi.templateIndex(params);
    dispatch(actionType(response.data.data, SET_TEMPLATES));
  } catch (err) {
    dispatch(actionType([], SET_TEMPLATES));
  }
};

export const duplicateTemplate = (params) => async (dispatch) => {
  try {
    const response = await TemplateApi.templateDuplicate(params);
    dispatch(actionType(response.data.data, DUPLICATE_TEMPLATE));
    displaySuccess(response.data.messages);
  } catch (err) {
    dispatch(actionType(null, DUPLICATE_TEMPLATE));
  }
};

export const deleteTemplate = (params) => async (dispatch) => {
  try {
    const response = await TemplateApi.templateDelete(params);
    dispatch(actionType(response.data.data, DELETE_TEMPLATE));
    displaySuccess(response.data.messages);
  } catch (err) {
    dispatch(actionType(null, DELETE_TEMPLATE));
  }
};

