import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { MaterialReactTable } from 'material-react-table';
import ReportsAPI from 'dashboard/src/api/reports';
import { DateTime } from 'luxon';
import withRouter from 'dashboard/src/helpers/withRouter';
import { useUser } from '../../../hooks';
import { get } from 'lodash-es';

function EmailSeriesStats(props) {
  const [data, setData] = useState([]);

  const { siteId, instanceId } = useParams();

  const { startDate, endDate } = props;

  const user = useUser();

  const timeZone = get(user, 'time_zone', 'Pacific/Auckland');

  useEffect(() => {
    const params = {
      instance_id: instanceId,
      site_id: siteId,
      report: 'Reports::EmailSeriesStats',
      start_date: DateTime.fromISO(startDate, { zone: timeZone }),
      end_date: DateTime.fromISO(endDate, { zone: timeZone }),
    };

    ReportsAPI.runReport(params).then((response) => {
      setData(response.data.data);
    });
  }, [startDate, endDate]);

  const sx = {
    fontSize: 12,
    fontFamily: 'Source Sans Pro',
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: 'subject',
        header: 'Subject',
      },
      {
        accessorKey: 'series_name',
        header: 'Email Series',
      },
      {
        accessorKey: 'sent_count',
        header: 'Sent',
      },
      {
        accessorKey: 'open_rate',
        header: 'Confirmed Open Rate',
      },
      {
        accessorKey: 'clickthrough_count',
        header: 'Clickthroughs',
      },
      {
        accessorKey: 'unsubscribe_count',
        header: 'Unsubscribes',
      },
    ]
  );

  return (
    <MaterialReactTable
      columns={columns}
      data={data}
      enablePagination={false}
      muiTableHeadCellProps={{ sx }}
      muiFormControlProps={{ sx }}
      muiTableBodyCellProps={{ sx }}
      muiTableHeadCellFilterTextFieldProps={{ sx }}
      muiTableContainerProps={{ sx }}
    />
  );
}

export default withRouter(EmailSeriesStats);
