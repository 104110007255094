import React, { useEffect, useState } from 'react';
import CustomSelect from '../../../components/common/custom-select';
import {
  Col,
  Row,
  Card,
  Label,
  Input,
  Table,
  Button,
  CardBody,
  CardTitle,
  FormGroup,
  UncontrolledTooltip,
} from 'reactstrap';
import HeaderComponent from '../../../components/common/header-component';
import { settingRoutes, replacePathParams } from '../../../constants/routes';
import Switch from 'rc-switch';
import {
  isEmpty,
  map,
  remove,
  intersection,
  includes,
  get,
} from 'lodash-es';
import CustomButton from '../../../components/common/button';
import { displayError } from '../../../../../Utils';
import UserFeaturePage from '../../../components/common/user-feature-page';
import BottomActionToolbar from '../../../components/common/toolbar';
import SaveAndContinue from '../../../components/common/saveAndContinue';
import withRouter from '../../../helpers/withRouter';
import { MasterPageSelectBox } from '../../../components/fields/site';
import {
  useGetAllSitesQuery,
  useGetBusinessNichesQuery,
  useGetGeographicAreaQuery,
  useLazyGetGeographicCodeQuery,
  useSitesLinkingToMeQuery,
  useUpdateSiteMutation,
} from '../../../api/apiSlice';
import { useInstance, useSite } from '../../../hooks';
import { addRecentItem } from '../../../../../UtilsTS';

function BusinessDirectory(props) {
  const [params, setParams] = useState({});
  const [newIncludedSiteId, setNewIncludedSiteId] = useState(null);
  const [newExcludedSiteId, setNewExcludedSiteId] = useState(null);
  const site = useSite();
  const siteId = get(site, 'id', null);
  const instance = useInstance();
  const instanceId = get(instance, 'id', null);

  const [updateSite] = useUpdateSiteMutation();
  const { data } = useSitesLinkingToMeQuery({ instanceId, siteId }, { skip: isEmpty(instanceId) || isEmpty(siteId) });
  const sitesLinkingToMe = data || [];

  const { data: sites = [] } = useGetAllSitesQuery();

  const siteSelectOptions = sites.map((s) => ({
    label: `${s.display_name} (${s.primary_domain_name || 'no domain'})`,
    value: s.id,
  }));

  const { data: geoAreas = [] } = useGetGeographicAreaQuery();
  const { data: businessNiches = [] } = useGetBusinessNichesQuery();
  const [trigger, { data: geoCode = [] }] = useLazyGetGeographicCodeQuery();

  useEffect(() => {
    if (!isEmpty(geoCode)) {
      setParams({ ...params, geo_coords: geoCode });
    }
  }, [geoCode]);

  useEffect(() => {
    if (site) {
      setParams(site);
    }
  }, [site]);

  const {
    geo_coords,
    geo_area_id,
    in_directory,
    display_name,
    site_description,
    company_address_street,
    company_address_region,
    company_address_locality,
    broad_business_niche_ids,
    narrow_business_niche_ids,
    directory_auto_included_site_ids,
    directory_manually_included_site_ids,
    directory_excluded_site_ids,
  } = params;

  const getSelectedOptionObj = (options, selected_option) => (
    options.filter((option) => option.value === selected_option)[0]
  );

  const getSelectOptions = (options) => options.map((opt) => ({ label: opt.name, value: opt.id }));

  const onHandleSwitch = (value, field) => {
    setParams({ ...params, [field]: value });
  };

  const onHandleChange = (e) => {
    const { name, value } = e.target;
    setParams({ ...params, [name]: value });
  };

  const onHandleSelectChange = (e, name) => {
    const { value } = e;
    setParams({ ...params, [name]: value });
  };

  const onGetCoordinates = (e) => {
    if (!company_address_street || !company_address_locality || !company_address_region) {
      displayError('Please fill Street address Or Locality Or Region.');
    } else {
      const address = `${company_address_street}, ${company_address_locality}, ${company_address_region}`;
      const sendParams = {
        address: address.replace(/ /g, '+'),
      };
      trigger(sendParams);
    }
    e.preventDefault();
  };

  const onChangeLatLong = (e) => {
    const { name, value } = e.target;
    const tempState = { ...params };
    const getArrVal = isEmpty(tempState.geo_coords) ? ['', ''] : tempState.geo_coords;
    if (name === 'latitude') {
      getArrVal[0] = parseFloat(value, 10);
    } else {
      getArrVal[1] = parseFloat(value, 10);
    }
    tempState.geo_coords = getArrVal;
    setParams(tempState);
  };

  const getBusinessNichesOptions = (options, name) => {
    const tempArr = [];
    map(options, (ele) => ele.broad === name && tempArr.push({
      label: ele.name,
      value: ele.id,
    }));
    return tempArr;
  };

  const onBusinessNichesChange = (e, name) => {
    const tempState = { ...params };
    const d = Array.isArray(e) ? e.map((x) => x.value) : [];
    tempState[name] = d;
    setParams(tempState);
  };

  const onHandleSubmit = () => {
    if (intersection(params.directory_manually_included_site_ids, params.directory_excluded_site_ids).length > 0) {
      alert('Can not have a site in both include and exclude lists.');
      return false;
    }

    const sendParams = {
      id: siteId,
      instance_id: instanceId,
      ...params,
    };
    updateSite(sendParams);

    return true;
  };
  // Some sites have numeric IDs, and comparing with === doesn't work.
  // eslint-disable-next-line eqeqeq
  const getSiteById = (id) => sites.filter((s) => s.id == id)[0];

  if (instanceId && siteId) {
    addRecentItem({
      instance_id: instanceId,
      site_id: siteId,
      type: 'business-directory',
    });
  }

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    { name: 'Business Directory', url: '' },
  ];

  return (
    <div className="BusinessDirectory">
      <HeaderComponent setPath={{
        headingName: 'Business Directory',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Back',
        backToPath: settingRoutes.dashboard,
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <UserFeaturePage feature="manage_business_directory_listing">
        <Card className="mb-4">
          <CardBody>
            <CardTitle>Directory Settings</CardTitle>
            <Row>
              <Col lg={12}>
                <FormGroup>
                  <Label>In Directory</Label>
                  <Switch
                    className="custom-switch custom-switch-primary"
                    checked={in_directory}
                    onChange={(e) => onHandleSwitch(e, 'in_directory')}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ display: in_directory ? 'flex' : 'none' }}>
              <Col lg={12}>
                <FormGroup>
                  <Label>
                    Master Page
                  </Label>
                  <MasterPageSelectBox
                    siteId={siteId}
                    params={params}
                    setParams={setParams}
                    instanceId={instanceId}
                    keyName="business_directory_master_page_id"
                  />
                </FormGroup>
              </Col>
              <Col lg={12}>
                <FormGroup>
                  <Label className="has-tooltip" id="displayNameHelp">Display Name</Label>
                  <UncontrolledTooltip placement="right" target="displayNameHelp" style={{ textAlign: 'left' }}>
                    {`This can be used to override the instance name. It is used in several places, including on the
                    business directory page, the Privacy Policy page, and in the (Site:Name) macro.`}
                  </UncontrolledTooltip>
                  <Input
                    type="text"
                    name="display_name"
                    id="display_name"
                    value={display_name || ''}
                    onChange={onHandleChange}
                  />
                </FormGroup>
              </Col>
              <Col lg={12}>
                <FormGroup>
                  <Label className="has-tooltip" id="siteDescriptionHelp">Site Description</Label>
                  <UncontrolledTooltip placement="right" target="siteDescriptionHelp" style={{ textAlign: 'left' }}>
                    {`This is used on the business directory page,
                    and is also appended to page titles to generate the page description.`}
                  </UncontrolledTooltip>
                  <Input
                    type="textarea"
                    name="site_description"
                    value={site_description || ''}
                    onChange={(e) => onHandleChange(e)}
                    rows={5}
                  />
                </FormGroup>
              </Col>
              <Col lg={12}>
                <FormGroup>
                  <Label>Geographical Area</Label>
                  <CustomSelect
                    options={getSelectOptions(geoAreas)}
                    isSearch={false}
                    className="flex-grow-1"
                    name="geo_area_id"
                    value={getSelectedOptionObj(
                      getSelectOptions(geoAreas),
                      geo_area_id,
                    )}
                    onChange={(e) => onHandleSelectChange(e, 'geo_area_id')}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ display: in_directory ? 'flex' : 'none' }}>
              <Col lg={4}>
                <FormGroup>
                  <Label>Street Address</Label>
                  <Input
                    type="text"
                    name="company_address_street"
                    value={company_address_street || ''}
                    onChange={(e) => onHandleChange(e)}
                  />
                </FormGroup>
              </Col>
              <Col lg={4}>
                <FormGroup>
                  <Label>Locality</Label>
                  <Input
                    type="text"
                    name="company_address_locality"
                    value={company_address_locality || ''}
                    onChange={(e) => onHandleChange(e)}
                  />
                </FormGroup>
              </Col>
              <Col lg={4}>
                <FormGroup>
                  <Label>Region</Label>
                  <Input
                    type="text"
                    name="company_address_region"
                    value={company_address_region || ''}
                    onChange={(e) => onHandleChange(e)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row style={{ display: in_directory ? 'flex' : 'none' }}>
              <Col lg={4}>
                <FormGroup>
                  <Label>Latitude</Label>
                  <Input
                    type="number"
                    name="latitude"
                    value={geo_coords ? geo_coords[0] : ''}
                    onChange={onChangeLatLong}
                  />
                </FormGroup>
              </Col>
              <Col lg={4}>
                <FormGroup>
                  <Label>Longitude</Label>
                  <Input
                    type="number"
                    name="longitude"
                    value={geo_coords ? geo_coords[1] : ''}
                    onChange={onChangeLatLong}
                  />
                </FormGroup>
              </Col>
              <Col lg={4} className="d-flex align-items-center">
                <CustomButton
                  size="md"
                  color="info"
                  title="Get Coordinates"
                  onClick={(e) => onGetCoordinates(e)}
                />
              </Col>
            </Row>
            <Row style={{ display: in_directory ? 'flex' : 'none' }}>
              <Col lg={6}>
                <FormGroup>
                  <Label>Broad Business Niche</Label>
                  <CustomSelect
                    isMulti
                    options={getBusinessNichesOptions(businessNiches, true)}
                    isSearch={false}
                    className="flex-grow-1"
                    name="broad_business_niche_ids"
                    value={
                      getBusinessNichesOptions(businessNiches, true).filter(
                        (obj) => broad_business_niche_ids && broad_business_niche_ids.includes(
                          obj.value,
                        ),
                      )
                    }
                    onChange={(e) => onBusinessNichesChange(e, 'broad_business_niche_ids')}
                  />
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup>
                  <Label>Narrow Business Niche</Label>
                  <CustomSelect
                    isMulti
                    options={getBusinessNichesOptions(businessNiches, false)}
                    isSearch={false}
                    className="flex-grow-1"
                    name="narrow_business_niche_ids"
                    value={
                      getBusinessNichesOptions(businessNiches, false).filter(
                        (obj) => narrow_business_niche_ids && narrow_business_niche_ids
                          .includes(
                            obj.value,
                          ),
                      )
                    }
                    onChange={(e) => onBusinessNichesChange(e, 'narrow_business_niche_ids')}
                  />
                </FormGroup>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card className="mb-4">
          <CardBody>
            <CardTitle>Automatically Included Sites</CardTitle>
            <Row>
              <Col lg={12}>
                <Table>
                  <thead>
                    <tr>
                      <th>Site name</th>
                      <th>Domain</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {directory_auto_included_site_ids && directory_auto_included_site_ids.map((otherSiteId) => {
                      const getSite = getSiteById(otherSiteId);

                      if (!getSite) {
                        return <tr />;
                      }

                      return (
                        <tr key={otherSiteId}>
                          <td>{getSite.display_name}</td>
                          <td>{getSite.primary_domain_name}</td>
                          <td>
                            <Button
                              className="custom-simple-icon"
                              color="danger"
                              size="xs"
                              onClick={() => {
                                const tempIds = [...directory_auto_included_site_ids];
                                // eslint-disable-next-line eqeqeq
                                remove(tempIds, (id) => id == otherSiteId);
                                setParams({ ...params, directory_auto_included_site_ids: tempIds });
                              }}
                            >
                              <i className="fal fa-trash" />
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card className="mb-4">
          <CardBody>
            <CardTitle>Manually Included Sites</CardTitle>
            <Row>
              <Col lg={12}>
                <Table>
                  <thead>
                    <tr>
                      <th>Site name</th>
                      <th>Domain</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {directory_manually_included_site_ids && directory_manually_included_site_ids.map((otherSiteId) => {
                      const getSite = getSiteById(otherSiteId);

                      if (!getSite) {
                        return <tr />;
                      }

                      return (
                        <tr key={otherSiteId}>
                          <td>{getSite.display_name}</td>
                          <td>{getSite.primary_domain_name}</td>
                          <td>
                            <Button
                              className="custom-simple-icon"
                              color="danger"
                              size="xs"
                              onClick={() => {
                                const tempIds = [...directory_manually_included_site_ids];
                                // eslint-disable-next-line eqeqeq
                                remove(tempIds, (id) => id == otherSiteId);
                                setParams({ ...params, directory_manually_included_site_ids: tempIds });
                              }}
                            >
                              <i className="fal fa-trash" />
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>

            <h3>
              Add new site
            </h3>

            <Row>
              <Col lg={9}>
                <CustomSelect
                  options={siteSelectOptions}
                  className="flex-grow-1"
                  value={getSelectedOptionObj(siteSelectOptions, newIncludedSiteId)}
                  onChange={(e) => setNewIncludedSiteId(e.value)}
                />
              </Col>
              <Col lg={3} className="d-flex align-items-center">
                <CustomButton
                  color="primary"
                  size="sm"
                  onClick={() => {
                    if (newIncludedSiteId && !includes(directory_manually_included_site_ids, newIncludedSiteId)) {
                      setParams({ ...params, directory_manually_included_site_ids: [...directory_manually_included_site_ids, newIncludedSiteId] });
                    }
                  }}
                  title="Add site"
                  icon="fal fa-plus"
                />
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card className="mb-4">
          <CardBody>
            <CardTitle>Manually Excluded Sites</CardTitle>
            <Row>
              <Col lg={12}>
                <Table>
                  <thead>
                    <tr>
                      <th>Site name</th>
                      <th>Domain</th>
                      <th>Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {directory_excluded_site_ids && directory_excluded_site_ids.map((otherSiteId) => {
                      const getSite = getSiteById(otherSiteId);

                      if (!getSite) {
                        return <tr />;
                      }

                      return (
                        <tr key={otherSiteId}>
                          <td>{getSite.display_name}</td>
                          <td>{getSite.primary_domain_name}</td>
                          <td>
                            <Button
                              className="custom-simple-icon"
                              color="danger"
                              size="xs"
                              onClick={() => {
                                const tempIds = [...directory_excluded_site_ids];
                                // eslint-disable-next-line eqeqeq
                                remove(tempIds, (id) => id == otherSiteId);
                                setParams({ ...params, directory_excluded_site_ids: tempIds });
                              }}
                            >
                              <i className="fal fa-trash" />
                            </Button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </Col>
            </Row>

            <h3>
              Add new site
            </h3>

            <Row>
              <Col lg={9}>
                <CustomSelect
                  options={siteSelectOptions}
                  className="flex-grow-1"
                  value={getSelectedOptionObj(siteSelectOptions, newExcludedSiteId)}
                  onChange={(e) => setNewExcludedSiteId(e.value)}
                />
              </Col>
              <Col lg={3} className="d-flex align-items-center">
                <CustomButton
                  color="primary"
                  size="sm"
                  onClick={() => {
                    if (newExcludedSiteId && !includes(directory_excluded_site_ids, newExcludedSiteId)) {
                      setParams({ ...params, directory_excluded_site_ids: [...directory_excluded_site_ids, newExcludedSiteId] });
                    }
                  }}
                  title="Add site"
                  icon="fal fa-plus"
                />
              </Col>
            </Row>
          </CardBody>
        </Card>

        <Card className="mb-4">
          <CardBody>
            <CardTitle>Sites Linking To {site?.label}</CardTitle>
            <Row>
              <Col lg={12}>
                <Table>
                  <thead>
                    <tr>
                      <th>Site name</th>
                      <th>Domain</th>
                      <th>TrustFlow</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sitesLinkingToMe.map((otherSite) => (
                      <tr key={otherSite.id}>
                        <td>{otherSite.display_name}</td>
                        <td>
                          <a href={`https://${otherSite.primary_domain_name}/${otherSite.link_directory_number}`} target="_blank" rel="noreferrer">{otherSite.primary_domain_name}</a>
                        </td>
                        <td>{otherSite.majestic_trust_flow_home}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </CardBody>
        </Card>

        <BottomActionToolbar
          component={(
            <SaveAndContinue
              onSave={onHandleSubmit}
              onContinue={replacePathParams(settingRoutes.dashboard, [], props)}
            />
          )}
        />
      </UserFeaturePage>
    </div>
  );
}
export default withRouter(BusinessDirectory);
