import React from 'react';
import {
  Input,
  Label,
  FormGroup,
  Row,
  Col,
  InputGroup,
  Container,
  Card,
  CardBody,
  CardTitle,
  UncontrolledTooltip,
  CardColumns,
} from 'reactstrap';
import CheckBox from '../../../components/common/checkBox';
import { MasterPageSelectBox } from '../../../components/fields/site';
import { getSelectedOption, selectColorStyles } from '../../../../../Utils';
import { get, isEmpty, map, uniq, uniqueId } from 'lodash-es';
import Switch from 'rc-switch';
import TextStyleListing from '../../common/textStyles';
import { DateTime } from 'luxon';
import CustomSelect from '../../../components/common/custom-select';
import ColorPicker from '../../../components/common/color-picker';
import { getPageHierarchy } from '../../../components/common/PageHierarchy';
import MediaSelectBox from './MediaSelectBox';
import { useInstance, useSite } from '../../../hooks';
import {
  useGetButtonStylesQuery,
  useGetPageQuery,
  useGetPageVersionQuery,
  useGetAllSectorsFromSiteQuery,
  useGetPageTreeQuery,
} from '../../../api/apiSlice';
import { getPageEditUrl } from '../../../constants/routes';

const startEndTime = (() => {
  const times = [];
  let time = DateTime.fromObject({ hour: 0, minute: 0 });
  while (times.length < 48) {
    times.push({ label: time.toFormat('h:mm a'), value: time.toUTC().toFormat('HH:mm') });
    time = time.plus({ minutes: 30 });
  }
  return times;
})();

const weekDays = [
  { name: 'Sunday', value: 0 },
  { name: 'Monday', value: 1 },
  { name: 'Tuesday', value: 2 },
  { name: 'Wednesday', value: 3 },
  { name: 'Thursday', value: 4 },
  { name: 'Friday', value: 5 },
  { name: 'Saturday', value: 6 },
];

const styleOptions = [
  { label: 'Casual', value: 'casual' },
  { label: 'Professional', value: 'professional' },
];

function InstantContactSettings({ params, setParams }) {
  const instance = useInstance();
  const site = useSite();

  const { data: buttonStyles } = useGetButtonStylesQuery(site?.id, { skip: isEmpty(site?.id) });

  const onChangeWeekDays = (e) => {
    const { checked, value } = e.target;
    let checkedDays = [...params.instant_contact_form_days_of_week];
    if (checked) {
      checkedDays.push(parseInt(value));
    } else {
      checkedDays = checkedDays.filter(
        (e) => e !== parseInt(value)
      );
    }
    setParams({ ...params, instant_contact_form_days_of_week: checkedDays });
  };

  const getWeekDaysCheckedValue = (data, value) => {
    if (!isEmpty(data)) {
      return data.includes(value);
    }
    return false;
  };

  const weekDaysRender = (data) => map(weekDays, (item) => (
    <CheckBox
      title={item.name}
      value={item.value}
      key={uniqueId('weekdays-')}
      onChange={(e) => onChangeWeekDays(e)}
      checked={getWeekDaysCheckedValue(data, item.value)}
    />
  ));

  const getStartEndTime = (t) => {
    if (t && t.match(/T/)) {
      // This is an ISO format date/time from the API
      return DateTime.fromISO(t).toUTC().toFormat('HH:mm');
    }

    // This is probably just HH:MM, we get this when the user changes the time.
    return t;
  };

  const { data: page } = useGetPageQuery({
    id: site?.instant_contact_page_id,
    siteId: site?.id,
    instanceId: instance?.id,
  }, { skip: isEmpty(site) });

  const { data: pageVersion } = useGetPageVersionQuery({
    id: page?.published_version_id,
    pageId: page?.id,
    siteId: site?.id,
    instanceId: instance?.id,
  }, { skip: isEmpty(page) });

  const { data: pageTree } = useGetPageTreeQuery({
    instanceId: instance?.id,
    siteId: site?.id,
  }, { skip: isEmpty(instance) || isEmpty(site) });

  const pageOptions = pageTree ? getPageHierarchy(pageTree, '') : [];

  const { data: sectors } = useGetAllSectorsFromSiteQuery({
    instanceId: instance?.id,
    siteId: site?.id,
  }, { skip: isEmpty(instance) || isEmpty(site) });

  // Build an array of sector labels, filtering out duplicates
  const sectorLabels = uniq(sectors?.map((s) => s.label));

  return (
    <CardColumns>
      <Card>
        <CardBody>
          <CardTitle>
            Button Settings
          </CardTitle>
          <Container fluid>
            <Row>
              <Col lg={12}>
                <FormGroup>
                  <Label>Enable Instant Contact Button</Label>
                  <Switch
                    className="custom-switch custom-switch-primary"
                    checked={params.enable_instant_contact_form}
                    onChange={(e) => setParams({ ...params, enable_instant_contact_form: e })}
                  />
                </FormGroup>
              </Col>
              <Col lg={12}>
                <FormGroup>
                  <Label>Button Text</Label>
                  <Input
                    type="text"
                    name="text_for_instant_contact_form"
                    value={get(params, 'text_for_instant_contact_form', '')}
                    onChange={(e) => setParams({ ...params, text_for_instant_contact_form: e.target.value })}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <FormGroup>
                  <Label>Text Style</Label>
                  <TextStyleListing
                    style={params.text_style_for_instant_contact_form_id}
                    onChange={(e) => setParams({ ...params, text_style_for_instant_contact_form_id: e.value })}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="BreakPoint align-items-center">
              <Col lg={6}>
                <FormGroup>
                  <Label>Button Background Color</Label>
                  <InputGroup className="h-40px">
                    <ColorPicker
                      isBackgroundColor
                      color={get(params, 'background_for_button', '')}
                      onChange={({ target: { value } }) => setParams({ ...params, background_for_button: value })}
                      onDeleteValue={() => setParams({ ...params, background_for_button: '' })}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup>
                  <Label>Button Hover Background Color</Label>
                  <InputGroup className="h-40px">
                    <ColorPicker
                      isBackgroundColor
                      color={get(params, 'hover_background_for_button', '')}
                      onChange={({ target: { value } }) => setParams({ ...params, hover_background_for_button: value })}
                      onDeleteValue={() => setParams({ ...params, hover_background_for_button: '' })}
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <FormGroup>
                  <Label>Start Time</Label>
                  <CustomSelect
                    isSearch={false}
                    className="flex-grow-1"
                    value={getSelectedOption(startEndTime, getStartEndTime(params.instant_contact_form_start_time))}
                    onChange={(e) => setParams({ ...params, instant_contact_form_start_time: e.value })}
                    options={startEndTime}
                  />
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup>
                  <Label>End Time</Label>
                  <CustomSelect
                    isSearch={false}
                    className="flex-grow-1"
                    value={getSelectedOption(startEndTime, getStartEndTime(params.instant_contact_form_end_time))}
                    onChange={(e) => setParams({ ...params, instant_contact_form_end_time: e.value })}
                    options={startEndTime}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                {weekDaysRender(params.instant_contact_form_days_of_week)}
              </Col>
            </Row>
          </Container>
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <CardTitle>
            Contact Options
          </CardTitle>
          <Container fluid>
            <Row>
              <Col lg={6}>
                <FormGroup>
                  <Label>Form</Label>
                  <Switch
                    className="custom-switch custom-switch-primary"
                    checked={params.enable_instant_contact_form_form}
                    onChange={(e) => setParams({ ...params, enable_instant_contact_form_form: e })}
                  />
                </FormGroup>
              </Col>
              <Col lg={6}>
                <FormGroup>
                  <Label>AI Chatbot</Label>
                  <Switch
                    className="custom-switch custom-switch-primary"
                    checked={params.enable_instant_contact_form_chat}
                    onChange={(e) => setParams({ ...params, enable_instant_contact_form_chat: e })}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Container>

          {params.enable_instant_contact_form_form && params.enable_instant_contact_form_chat && (
            <>
              <Container fluid>
                <Row>
                  <Col>
                    <FormGroup>
                      <Label className="has-tooltip" id="icfIntroTextHelp">Introduction text</Label>
                      <UncontrolledTooltip placement="right" target="icfIntroTextHelp" autohide={false} style={{ textAlign: 'left' }}>
                        This is for the text at the top of the Instant Contact popup asking users to choose whether
                        they want to contact you via a form or use the AI chatbot.
                      </UncontrolledTooltip>
                      <Input
                        type="textarea"
                        rows={3}
                        value={params.instant_contact_intro_text}
                        onChange={(e) => setParams({ ...params, instant_contact_intro_text: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg={12}>
                    <FormGroup>
                    <Label className="has-tooltip" id="icfIntroTextStyleHelp">Text Style</Label>
                      <UncontrolledTooltip placement="right" target="icfIntroTextStyleHelp" autohide={false} style={{ textAlign: 'left' }}>
                        This is the text style used for the intro text on the Instant Contact popup asking users to
                        choose whether they want to contact you via a form or use the AI chatbot.
                      </UncontrolledTooltip>
                      <TextStyleListing
                        style={params.instant_contact_interstitial_text_style_id}
                        onChange={(e) => setParams({ ...params, instant_contact_interstitial_text_style_id: e.value })}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Label>Button Style</Label>
                    <CustomSelect
                      options={buttonStyles?.map((style) => ({ label: style.name, value: style.id }))}
                      value={buttonStyles?.filter((s) => s.id === params.instant_contact_interstitial_button_style_id).map((s) => ({ label: s.name, value: s.id }))[0]}
                      onChange={(e) => setParams({ ...params, instant_contact_interstitial_button_style_id: e.value })}
                    />
                  </Col>
                </Row>
              </Container>
            </>
          )}
        </CardBody>
      </Card>

      {params.enable_instant_contact_form_form && (
        <Card>
          <CardBody>
            <CardTitle>
              Form
            </CardTitle>
            <p>
              The form can be edited
              {' '}
              <a
                href={getPageEditUrl(pageVersion?.id, page?.id, 'web')}
                target="_blank"
                rel="noreferrer"
              >
                here
              </a>
              .
            </p>
          </CardBody>
        </Card>
      )}

      {params.enable_instant_contact_form_chat && (
        <Card>
          <CardBody>
            <CardTitle>
              AI Chat Settings
            </CardTitle>
            <Container fluid>
              <Row>
                <Col>
                  <Label className="has-tooltip" id="chatbotMasterPageHelp">Master Page</Label>
                  <UncontrolledTooltip placement="right" target="chatbotMasterPageHelp" autohide={false} style={{ textAlign: 'left' }}>
                    {params.enable_instant_contact_form_form && (
                      <>
                        This master page will be used by both the initial popup where the user can select the
                        contact option, and by the chatbot popup.
                      </>
                    )}
                    {!params.enable_instant_contact_form_form && (
                      <>
                        This master page will be used by the chatbot popup.
                      </>
                    )}
                  </UncontrolledTooltip>
                  <MasterPageSelectBox
                    siteId={site?.id}
                    params={params}
                    setParams={setParams}
                    instanceId={instance?.id}
                    labelName="Master Page"
                    keyName="instant_contact_master_page_id"
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Persona Name (optional)</Label>
                    <Input
                      value={params.chatbot_persona_name}
                      onChange={(e) => setParams({ ...params, chatbot_persona_name: e.target.value })}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Persona Icon</Label>
                    <MediaSelectBox
                      value={params?.chatbot_persona_icon_id}
                      onchange={(e) => setParams({ ...params, chatbot_persona_icon_id: e ? e.value : null })}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Chatbot style</Label>
                    <CustomSelect
                      options={styleOptions}
                      value={styleOptions.find((o) => o.value === params.chatbot_style)}
                      onChange={(e) => setParams({ ...params, chatbot_style: e.value })}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label className="has-tooltip" id="chatbotExtraInstructionsHelp">Extra instructions</Label>
                    <UncontrolledTooltip placement="right" target="chatbotExtraInstructionsHelp" autohide={false} style={{ textAlign: 'left' }}>
                      This is for any extra instructions for the chatbot. For example, &quot;Do not give any medical advice.&quot;
                    </UncontrolledTooltip>
                    <Input
                      type="textarea"
                      rows={3}
                      value={params.chatbot_extra_instructions}
                      onChange={(e) => setParams({ ...params, chatbot_extra_instructions: e.target.value })}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label className="has-tooltip" id="chatbotInitialGreetingHelp">Initial greeting</Label>
                    <UncontrolledTooltip placement="right" target="chatbotInitialGreetingHelp" autohide={false} style={{ textAlign: 'left' }}>
                      If you have specified a persona name, you may want to include it in the initial greeting.
                    </UncontrolledTooltip>
                    <Input
                      type="textarea"
                      rows={3}
                      value={params.chatbot_initial_message}
                      onChange={(e) => setParams({ ...params, chatbot_initial_message: e.target.value })}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                  <Label>Text Style</Label>
                    <TextStyleListing
                      style={params.instant_contact_chat_text_style_id}
                      onChange={(e) => setParams({ ...params, instant_contact_chat_text_style_id: e.value })}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Pages to exclude</Label>
                    <CustomSelect
                      isMulti
                      onChange={(e) => setParams({ ...params, chatbot_pages_to_exclude_ids: [...e.map((o) => o.value)] })}
                      options={pageOptions}
                      value={params.chatbot_pages_to_exclude_ids.map((pageId) => ({
                        label: pageOptions.find((p) => p.value === pageId)?.label,
                        value: pageId,
                      }))}
                      styles={selectColorStyles}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label>Sectors to exclude</Label>
                    <CustomSelect
                      isMulti
                      options={sectorLabels.map((label) => ({ label, value: label }))}
                      value={params.chatbot_sectors_to_exclude.map((label) => ({ label, value: label }))}
                      onChange={(e) => setParams({ ...params, chatbot_sectors_to_exclude: e.map((o) => o.value) })}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Container>
          </CardBody>
        </Card>
      )}
    </CardColumns>
  );
}

export default InstantContactSettings;
