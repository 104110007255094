import React, { useEffect } from 'react';
import { setPageTitle } from '../../../../../Utils';
import withRouter from '../../../helpers/withRouter';
import Upload from '../../media-uploads/Upload';

const MediaFolder = (props) => {
  const { params: { fileFolderId } } = props;

  useEffect(() => {
    setPageTitle('Media Centre');
  }, []);

  return (
    <Upload type="media-center" currentFolderId={fileFolderId} />
  );
};

export default withRouter(MediaFolder);
