import React from 'react';
import { Table, Card, CardBody, CardHeader } from 'reactstrap';
import CustomButton from 'dashboard/src/components/common/button';
import { get, map, isEmpty } from 'lodash-es';
import confirm from 'dashboard/src/components/common/confirm';
import importMemberAPI from 'dashboard/src/api/import_members';
import { useEffect, useState } from 'react';
import { DateTime } from 'luxon';
import HeaderComponent from 'dashboard/src/components/common/header-component';
import { mainRoutes, replacePathParams, settingRoutes } from 'dashboard/src/constants/routes';
import withRouter from 'dashboard/src/helpers/withRouter';
import BulkEmailAPI from 'dashboard/src/api/deliveryCenter';
import DateTimeFormat from 'dashboard/src/components/common/DateTimeFormat';
import { Bar } from 'react-chartjs-2';
import './index.scss';

function JobReports(props) {
  const { params : { reportType, reportId, instanceId }, navigate } = props;
  const [report, setReport] = useState(null);

  const getReport = async () => {
    const response = await BulkEmailAPI.jobProgressReport({ id: reportId, instance_id: instanceId });
    if (undefined !== response) {
      const { data: { data }} = response;
      setReport({...get(data, 'report', data), ...get(data, 'object', {})});
    }
  };

  useEffect(() => {
    getReport();
  }, [reportId]);

  const unsubscribedMember = get(report, 'unsubscribed_members_skipped', 0);
  const opensByHour = get(report, 'opens_by_hour', {});

  const reportsList = () => {
    switch (reportType) {
    case 'MemberImportJob':
      return (
        <Table>
          <tbody>
            <tr>
              <td>New members imported:</td>
              <td>{get(report, 'new_members', 0)}</td>
            </tr>
            <tr>
              <td>Existing members updated:</td>
              <td>{get(report, 'updated_members', 0)}</td>
            </tr>
            <tr>
              <td>Rows with blank email addresses:</td>
              <td>{get(report, 'blank_emails', 0)}</td>
            </tr>
            <tr>
              <td>Unsubscribed members skipped:</td>
              <td>{unsubscribedMember}</td>
            </tr>
            {unsubscribedMember > 0 && (
              <tr>
                <td>Resubscribe:</td>
                <td>
                  <CustomButton
                    size="sm"
                    title="Resubscribe Now"
                    color="success"
                    onClick={() => onResubscribe()}
                  />
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      );
    case 'BulkEmailQueueJob':
      return (
        <Table>
          <tbody>
            <tr>
              <td>Date Sent:</td>
              <td><DateTimeFormat datetime={get(report, 'date_sent', null)} localeString={DateTime.DATETIME_MED} /></td>
            </tr>
            <tr>
              <td>Email Subject:</td>
              <td>{get(report, 'subject', '')}</td>
            </tr>
            <tr>
              <td>Saved Search Name:</td>
              <td>{get(report, 'search_name', '')}</td>
            </tr>
            <tr>
              <td>Opened Email:</td>
              <td>{get(report, 'email_opened', 0)}</td>
            </tr>
            <tr>
              <td>Email Sent:</td>
              <td>{get(report, 'email_sent', 0)}</td>
            </tr>
            <tr>
              <td>Unsubscribed:</td>
              <td>{get(report, 'unsubscribed_count', 0)}</td>
            </tr>
            <tr>
              <td>Number of Clicks:</td>
              <td>{get(report, 'email_click_count', 0)}</td>
            </tr>
          </tbody>
        </Table>
      );
    default: return '';
    }
  };

  const onResubscribe = async () => {

    const result = await confirm({
      title: (<strong>Confirm!</strong>),
      message: `Are you sure you want to re-subscribe ${unsubscribedMember} member${unsubscribedMember === 1 ? '' : 's'}?`,
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });

    if (result) {
      const sendParams = {
        data: get(report, 'data', []),
        import_name: get(report, 'import_name', []),
        instance_id: get(report, 'instance_id', []),
        email_series: get(report, 'email_series', []),
        unique_identifier: get(report, 'unique_identifier', []),
        resubscribe_members: true,
      };
      const res = await importMemberAPI.importMember(sendParams);

      if (undefined !== res) {
        navigate(replacePathParams(settingRoutes.backgroundJobs, [
          {key: 'pageNo', value: 1},
        ], props));
      }
    }
  };

  const staticBreadcrumbData = [
    { name: 'Dashboard', url: replacePathParams(settingRoutes.dashboard, [], props) },
    { name: 'Settings', url: replacePathParams(mainRoutes.setting, [], props) },
    { name: 'Background Jobs', url:  replacePathParams(settingRoutes.backgroundJobs, [
      {key: 'pageNo', value: 1},
    ], props)},
    { name: reportType, url:  ''},
  ];

  const timeLabels = (data, labels = []) => {
    for (const property in data) {
      labels.push({ x: property, y: data[property]});
    }
    return labels;
  };

  const data = {
    labels: map(timeLabels(opensByHour), (e) => DateTime.fromSeconds(parseInt(e.x)).toFormat('y-LL-dd HH:mm')),
    datasets: [
      {
        label: 'Time',
        data: map(timeLabels(opensByHour), (e) => e.y),
        backgroundColor: [
          '#45afd0',
        ],
        borderColor: [
          '#45afd0',
        ],
        borderWidth: 1,
      },
    ],
  };

  return (
    <div className="JobReport">
      <HeaderComponent setPath={{
        headingName: 'Reports',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Back',
        backToPath: '',
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <Card>
        <CardBody>
          {reportsList()}
          {!isEmpty(opensByHour) && (
            <>
              <CardHeader>Opens By Hour:</CardHeader>
              <div className="graph">
                <Bar data={data} />
              </div>
            </>
          )}
        </CardBody>
      </Card>
    </div>
  );
}

export default withRouter(JobReports);
