import React, { useEffect } from 'react';
import { setPageTitle } from '../../../../../Utils';
import { addRecentItem } from 'UtilsTS';
import HeaderComponent from '../../../components/common/header-component';
import { settingRoutes, replacePathParams } from '../../../constants/routes';
import withRouter from '../../../helpers/withRouter';
import SiteTree from './SiteTree';
import { useInstance, useSite } from '../../../hooks';

function PageTree(props) {
  useEffect(() => {
    setPageTitle('Site Centre Pages');
  });

  const instance = useInstance();
  const site = useSite();

  if (instance?.id && site?.id) {
    addRecentItem({
      instance_id: instance.id,
      site_id: site.id,
      type: 'site-center',
    });
  }

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    { name: 'Site Centre', url: '' },
    { name: 'Pages', url: '' },
  ];

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Site Centre',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Dashboard',
        backToPath: settingRoutes.dashboard,
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <SiteTree props={props} />
    </>
  );
}
export default withRouter(PageTree);
