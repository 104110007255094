import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchTrashAction, recordRestoreAction, recordDeleteAction, setReplaceSkipPopup,
} from '../../../redux/settings/trash/actions';
import {
  Card, CardBody, Row, Col, Button,
} from 'reactstrap';
import HeaderComponent from '../../../components/common/header-component';
import { settingRoutes, mainRoutes, replacePathParams } from '../../../constants/routes';
import TableComponent from '../../../components/common/Table';
import confirm from '../../../components/common/confirm';
import { isEmpty, get } from 'lodash-es';
import { displayError, displaySuccess } from '../../../../../Utils';
import Pagination from '../../../components/common/custom-pagination';
import { useNavigate, useParams } from 'react-router-dom';
import DateTimeFormat from '../../../components/common/DateTimeFormat';
import { DateTime } from 'luxon';
import withRouter from '../../../helpers/withRouter';
import UserFeaturePage from '../../../components/common/user-feature-page';

function Trash(props) {
  const { instanceId, pageNo } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    trashData, showReplacePopup, restoreData, actionMessage, error, trashPagination,
  } = useSelector((state) => state.adminStore.trashReducer);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [orderBy, setOrderBy] = useState('deleted_at');
  const [orderDirection, setOrderDirection] = useState('desc');

  useEffect(() => {
    dispatch(fetchTrashAction({
      instance_id: instanceId,
      page_size: pageSize,
      page: pageNo,
      order_by: orderBy,
      order_direction: orderDirection,
    }));
    setCurrentPage(pageNo);
  }, [dispatch, pageSize, pageNo, orderBy, orderDirection]);

  useEffect(() => {
    if (!isEmpty(actionMessage)) displaySuccess(actionMessage);
  }, [actionMessage]);

  useEffect(() => {
    if (!isEmpty(error)) displayError(`${error?.name}: ${error?.message}`);
  }, [error]);

  const replaceSkip = async () => {
    if (showReplacePopup && !isEmpty(restoreData)) {
      const sendParams = { ...restoreData, replace_record: true };
      const result = await confirm({
        title: (<strong>Confirm!</strong>),
        message: 'Record exists already, Do you want to replace?',
        confirmText: 'Replace',
        confirmColor: 'success',
        cancelColor: 'btn btn-danger',
        cancelText: 'Skip',
      });
      if (result) {
        dispatch(recordRestoreAction(sendParams));
      } else {
        dispatch(setReplaceSkipPopup(false, {}));
      }
    }
  };

  useEffect(() => {
    replaceSkip();
  }, [showReplacePopup, dispatch]);

  const onHandleRestore = (e) => {
    dispatch(recordRestoreAction(e));
  };

  const onHandleDelete = async (e) => {
    const result = await confirm({
      title: (<strong>Confirm!</strong>),
      message: 'Are you sure?',
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });
    if (result) {
      dispatch(recordDeleteAction(e));
    }
  };

  const dataTableColumns = [
    {
      Header: 'Name',
      id: 'name',
      accessor: (d) => get(d, 'name', ''),
      disableSortBy: true,
    },
    {
      Header: (
        <Button
          onClick={() => {
            setOrderBy('friendly_name');
            setOrderDirection(orderDirection === 'desc' ? 'asc' : 'desc');
          }}
          className="custom-simple-txt-btn fw-bold"
        >
          <span>
            {'Type '}
            {orderBy === 'friendly_name' ? (
              <i
                className={` ${orderDirection === 'desc' ? 'fa fa-chevron-circle-up' : 'fa fa-chevron-circle-down'}`}
              />
            ) : ''}
          </span>
        </Button>
      ),
      id: 'friendly_name',
      accessor: (d) => get(d, 'friendly_name', ''),
      disableSortBy: true,
    },
    {
      Header: 'Deleted By',
      id: 'deleted_by',
      accessor: (d) => get(d, 'deleted_by', ''),
      disableSortBy: true,
    },
    {
      Header: (
        <Button
          onClick={() => {
            setOrderBy('deleted_at');
            setOrderDirection(orderDirection === 'desc' ? 'asc' : 'desc');
          }}
          className="custom-simple-txt-btn fw-bold"
        >
          <span>
            {'Deleted Date '}
            {orderBy === 'deleted_at' ? (
              <i
                className={` ${orderDirection === 'desc' ? 'fa fa-chevron-circle-up' : 'fa fa-chevron-circle-down'}`}
              />
            ) : ''}
          </span>
        </Button>
      ),
      disableSortBy: true,
      id: 'deleted_at',
      accessor: (d) => (
        <DateTimeFormat datetime={get(d, 'deleted_at', '')} localeString={DateTime.DATETIME_SHORT} />
      ),
    },
    {
      Header: 'Action',
      id: 'action',
      accessor: (d) => (
        <>
          <Button className="custom-simple-icon" color="success" size="xs" onClick={() => onHandleRestore(d)}>
            <i className="fal fa-recycle" />
          </Button>
          {' '}
          <Button className="custom-simple-icon" color="danger" size="xs" onClick={() => onHandleDelete(d)}>
            <i className="fal fa-trash" />
          </Button>
        </>
      ),
    },
  ];

  const commonPathParams = (path) => replacePathParams(path, [
    { key: 'instanceId', value: instanceId },
  ], props);

  const onChangePage = (e) => {
    setCurrentPage(e);
    navigate(
      replacePathParams(
        settingRoutes.trash,
        [
          { key: 'pageNo', value: e },
        ], props
      ),
    );
  };

  const staticBreadcrumbData = [
    { name: 'Dashboard', url: commonPathParams(settingRoutes.dashboard) },
    { name: 'Settings', url: commonPathParams(mainRoutes.setting) },
    { name: 'Trash', url: '' },
  ];

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Trash',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Back',
        backToPath: commonPathParams(settingRoutes.dashboard),
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <UserFeaturePage feature="trash">
        <Card>
          <CardBody>
            <Pagination
              type={['item', 'items']}
              currentPage={Number(currentPage)}
              records={trashData}
              onChangePage={onChangePage}
              getPagination={trashPagination}
              pageSize={pageSize}
              changePageSize={(e) => setPageSize(e)}
            />
            <Row>
              <Col>
                <TableComponent
                  columns={dataTableColumns}
                  data={trashData}
                />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </UserFeaturePage>
    </>
  );
}

export default withRouter(Trash);
