import React, { useState, useEffect, useRef } from 'react';
import { Button, Table, InputGroup, FormGroup, Label, Card, CardBody, Nav, NavItem, NavLink, CardTitle } from 'reactstrap';
import { map, get, cloneDeep, isEmpty, isEqual } from 'lodash-es';
import AdvancedSettings from '../../../../components/common/advancedSettings';
import confirm from '../../../../components/common/confirm';
import { displayError, getMaxValueByKey, updateStylesheet } from '../../../../../../Utils';
import CustomSelect from '../../../../components/common/custom-select';
import CustomButton from '../../../../components/common/button';
import { sectionTypes } from '../../../../schema';
import withRouter from '../../../../helpers/withRouter';
import { numberFieldWithUnitSelector } from '../../design-options/components/component';
import './index.scss';
import DragDropComponent from '../../../../components/common/reactSortable';
import {
  useGetSectionQuery,
  useGetColumnsQuery,
  useSectionUpdateMutation,
} from '../../../../api/apiSlice';
import GridLayoutView from '../SectionSetting/GridLayoutView';
import classnames from 'classnames';
import { getDeviceType } from '../../../../../../Utils';
import { useUser } from '../../../../hooks';

function ColumnType(props) {
  const {
    section: { id: sectionId },
    isEmailPage,
    submitCounter,
    postSave,
    params: { instanceId, siteId, pageVersionId },
    refetchPageData,
  } = props;

  const user = useUser();

  const { data: section } = useGetSectionQuery({ instanceId, sectionId });

  const { data: columnSections, refetch: refetchColumns } = useGetColumnsQuery({ instanceId, sectionId });

  const [sectionUpdate, { isSuccess }] = useSectionUpdateMutation();

  const [tempSection, setTempSection] = useState(null);

  const [tempColumnSections, setTempColumnSections] = useState(null);

  const [toggleTab, setToggleTab] = useState('desktop');

  useEffect(() => {
    if (isSuccess) {
      refetchColumns();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (!isEqual(section, tempSection)) {
      setTempSection(cloneDeep(section));
    }
  }, [section]);

  if (columnSections && tempColumnSections === null) {
    setTempColumnSections(cloneDeep(columnSections));
  }

  const mounted = useRef(false);

  useEffect(() => {
    if (!mounted.current) {
      mounted.current = true;
      return;
    }
    if (submitCounter) {
      (async () => {
        await save();
        postSave();
        if (!isEmailPage) {
          updateStylesheet({ siteId, pageId: tempSection?.page_id, pageVersionId });
        }
      })();
    }
  }, [submitCounter, isEmailPage]);

  if (!section || !tempColumnSections) {
    return <></>;
  }

  const { sector, page_version_id } = section;

  const onHandleAddSectionColumn = () => {
    const newColumn = {
      instance_id: instanceId,
      break_point: {
        desktop_styles: {
          width: ['100%'],
          'max-width': ['100%'],
        },
        tablet_styles: {
          width: ['100%'],
          'max-width': ['100%'],
        },
        phone_styles: {
          width: ['100%'],
          'max-width': ['100%'],
        },
      },
      sector,
      type: 'SectionContent',
      page_version_id,
      column_section_id: sectionId,
      order: getMaxValueByKey(tempColumnSections, 'order'),
      id: crypto.randomUUID(), // Adding a new ID so we can track the object, will unset it later.
    };

    tempColumnSections.push(newColumn);
    setTempColumnSections([...tempColumnSections]);
    setTempSection({ ...tempSection, column_sections: tempColumnSections });
  };

  const onHandleDeleteSection = async (id) => {
    try {
      const result = await confirm({
        title: <b>Confirm!</b>,
        message: 'Are you sure?',
        confirmText: 'Yes',
        confirmColor: 'success',
        cancelColor: 'btn btn-danger',
        cancelText: 'No',
      });
      if (result) {
        const i = tempColumnSections.findIndex((c) => c.id === id);
        tempColumnSections[i]._destroy = true;
        let counter = 0;
        setTempColumnSections(tempColumnSections.map((s) => ({ ...s, order: s._destroy ? null : ++counter })));
        setTempSection({ ...tempSection, column_sections: tempColumnSections });
      }
    } catch (error) {
      displayError(`${error?.name}: ${error?.message}`);
    }
  };

  const handleSectionType = async (e, index, columnId) => {
    const { value } = e;

    if (value !== tempColumnSections[index].type) {
      const result = await confirm({
        title: <b>Confirm!</b>,
        message: 'The content in this column will be deleted - are you sure?',
        confirmText: 'Yes',
        confirmColor: 'success',
        cancelColor: 'btn btn-danger',
        cancelText: 'No',
      });

      if (result) {
        const i = tempColumnSections.findIndex((c) => c.id === columnId);
        tempColumnSections[i].type = value;
        setTempColumnSections([...tempColumnSections]);
        setTempSection({ ...tempSection, column_sections: tempColumnSections });
      }
    }
  };

  const onChangeValue = (i, value, deviceName, style) => {
    const tempArr = tempColumnSections.filter((item) => !item._destroy);
    tempArr[i].break_point[deviceName][style] = [value];
    setTempColumnSections([...tempArr, ...tempColumnSections.filter((item) => item._destroy)]);
    setTempSection({ ...tempSection, column_sections: tempArr });
  };

  const onHandleDrop = (newState) => {
    const temp = [...tempColumnSections];
    newState.forEach((element, i) => {
      const columnIdx = temp.findIndex((c) => c.id === element.id);
      if (columnIdx > -1) {
        temp[columnIdx].order = i + 1;
      }
    });
    setTempColumnSections([...temp].sort((a, b) => (a.order > b.order ? 1 : -1)));
  };

  const save = async () => {
    const sendParams = {
      instance_id: instanceId,
      page_version_id: pageVersionId,
      ...tempSection,
      columns_attributes: tempColumnSections.map((c) => (
        {
          ...c,
          temp_id: c.id,
          id: c.id.match(/^[0-9a-f]{24}/) ? c.id : null, // Remove temporary random IDs
        }
      )),
    };
    delete sendParams.column_sections;
    const res = await sectionUpdate(sendParams);
    if (!isEmpty(res)) {
      refetchPageData();
      setTempColumnSections(cloneDeep(res?.data?.column_sections));
      return true;
    }
    return false;
  };

  const sectionTypeOptions = sectionTypes(true, isEmailPage ? 'email' : 'web', user);

  const renderColumns = () => {
    const interfaceWeb = map(tempColumnSections.filter((c) => !c._destroy), (column, i) => (
      <tr key={column.id}>
        <td className="text-start" style={{ width: '230px' }}>
          <div className="d-flex align-items-center">
            <Button className="custom-simple-txt-btn me-2 handle-sortable">
              <i className="fa-solid fa-arrows" />
            </Button>
            <span className="ms-2 me-2">{`${i + 1}.`}</span>
            <CustomSelect
              className="w-100"
              name="selectedSectionType"
              options={sectionTypeOptions}
              onChange={(e) => handleSectionType(e, i, column.id)}
              value={sectionTypeOptions.filter((res) => res.value === column.type)}
            />
            <Button className="custom-simple-txt-btn" onClick={() => onHandleDeleteSection(column.id)}>
              <i className="fal fa-trash ms-2 text-danger" />
            </Button>
          </div>
        </td>
        <td className="border-start">
          <input
            type="checkbox"
            checked={get(column, 'break_point.desktop_styles.display[0]', 'block') === 'block'}
            onChange={(e) => {
              const value = e.target.checked ? 'block' : 'none';
              onChangeValue(i, value, 'desktop_styles', 'display');
            }}
          />
        </td>
        <td>
          <InputGroup>
            {numberFieldWithUnitSelector(
              get(column, 'break_point.desktop_styles.width[0]', '0%'),
              (value) => onChangeValue(i, value, 'desktop_styles', 'width'),
              'width',
              true,
              true,
              '%',
            )}
          </InputGroup>
        </td>
        <td>
          <InputGroup>
            {numberFieldWithUnitSelector(
              get(column, 'break_point.desktop_styles.min-width[0]', ''),
              (value) => onChangeValue(i, value, 'desktop_styles', 'min-width'),
              'min-width',
              true,
              true,
            )}
          </InputGroup>
        </td>
        <td>
          <InputGroup>
            {numberFieldWithUnitSelector(
              get(column, 'break_point.desktop_styles.max-width[0]', '100%'),
              (value) => onChangeValue(i, value, 'desktop_styles', 'max-width'),
              'max-width',
              true,
              true,
              '%',
            )}
          </InputGroup>
        </td>
        <td>
          <InputGroup>
            {numberFieldWithUnitSelector(
              get(column, 'break_point.desktop_styles.wrapper-padding-left[0]', ''),
              (value) => onChangeValue(i, value, 'desktop_styles', 'wrapper-padding-left'),
              'padding-left',
              true,
              true,
            )}
          </InputGroup>
        </td>
        <td>
          <InputGroup>
            {numberFieldWithUnitSelector(
              get(column, 'break_point.desktop_styles.wrapper-padding-right[0]', ''),
              (value) => onChangeValue(i, value, 'desktop_styles', 'wrapper-padding-right'),
              'padding-right',
              true,
              true,
            )}
          </InputGroup>
        </td>
        <td className="border-start">
          <input
            type="checkbox"
            checked={get(column, 'break_point.tablet_styles.display[0]', 'block') === 'block'}
            onChange={(e) => {
              const value = e.target.checked ? 'block' : 'none';
              onChangeValue(i, value, 'tablet_styles', 'display');
            }}
          />
        </td>
        <td>
          <InputGroup>
            {numberFieldWithUnitSelector(
              get(column, 'break_point.tablet_styles.width[0]', '0%'),
              (value) => onChangeValue(i, value, 'tablet_styles', 'width'),
              'width',
              true,
              true,
              '%',
            )}
          </InputGroup>
        </td>
        <td>
          <InputGroup>
            {numberFieldWithUnitSelector(
              get(column, 'break_point.tablet_styles.min-width[0]', ''),
              (value) => onChangeValue(i, value, 'tablet_styles', 'min-width'),
              'min-width',
              true,
              true,
            )}
          </InputGroup>
        </td>
        <td>
          <InputGroup>
            {numberFieldWithUnitSelector(
              get(column, 'break_point.tablet_styles.max-width[0]', '100%'),
              (value) => onChangeValue(i, value, 'tablet_styles', 'max-width'),
              'max-width',
              true,
              true,
              '%',
            )}
          </InputGroup>
        </td>
        <td>
          <InputGroup>
            {numberFieldWithUnitSelector(
              get(column, 'break_point.tablet_styles.wrapper-padding-left[0]', ''),
              (value) => onChangeValue(i, value, 'tablet_styles', 'wrapper-padding-left'),
              'padding-left',
              true,
              true,
            )}
          </InputGroup>
        </td>
        <td>
          <InputGroup>
            {numberFieldWithUnitSelector(
              get(column, 'break_point.tablet_styles.wrapper-padding-right[0]', ''),
              (value) => onChangeValue(i, value, 'tablet_styles', 'wrapper-padding-right'),
              'padding-right',
              true,
              true,
            )}
          </InputGroup>
        </td>

        <td className="border-start">
          <input
            type="checkbox"
            checked={get(column, 'break_point.phone_styles.display[0]', 'block') === 'block'}
            onChange={(e) => {
              const value = e.target.checked ? 'block' : 'none';
              onChangeValue(i, value, 'phone_styles', 'display');
            }}
          />
        </td>
        <td>
          <InputGroup>
            {numberFieldWithUnitSelector(
              get(column, 'break_point.phone_styles.width[0]', '0%'),
              (value) => onChangeValue(i, value, 'phone_styles', 'width'),
              'width',
              true,
              true,
              '%',
            )}
          </InputGroup>
        </td>
        <td>
          <InputGroup>
            {numberFieldWithUnitSelector(
              get(column, 'break_point.phone_styles.min-width[0]', ''),
              (value) => onChangeValue(i, value, 'phone_styles', 'min-width'),
              'min-width',
              true,
              true,
            )}
          </InputGroup>
        </td>
        <td>
          <InputGroup>
            {numberFieldWithUnitSelector(
              get(column, 'break_point.phone_styles.max-width[0]', '100%'),
              (value) => onChangeValue(i, value, 'phone_styles', 'max-width'),
              'max-width',
              true,
              true,
              '%',
            )}
          </InputGroup>
        </td>
        <td>
          <InputGroup>
            {numberFieldWithUnitSelector(
              get(column, 'break_point.phone_styles.wrapper-padding-left[0]', ''),
              (value) => onChangeValue(i, value, 'phone_styles', 'wrapper-padding-left'),
              'padding-left',
              true,
              true,
            )}
          </InputGroup>
        </td>
        <td>
          <InputGroup>
            {numberFieldWithUnitSelector(
              get(column, 'break_point.phone_styles.wrapper-padding-right[0]', ''),
              (value) => onChangeValue(i, value, 'phone_styles', 'wrapper-padding-right'),
              'padding-right',
              true,
              true,
            )}
          </InputGroup>
        </td>
      </tr>
    ));

    const interfaceEmail = map(tempColumnSections.filter((c) => !c._destroy), (column, i) => (
      <tr key={column.id}>
        <td className="text-start" style={{ width: '230px' }}>
          <div className="d-flex align-items-center">
            <Button className="custom-simple-txt-btn me-2 handle-sortable">
              <i className="fa-solid fa-arrows" />
            </Button>
            <span className="ms-2 me-2">{`${i + 1}.`}</span>
            <CustomSelect
              className="w-100"
              name="selectedSectionType"
              options={sectionTypeOptions}
              onChange={(e) => handleSectionType(e, i, column.id)}
              value={sectionTypeOptions.filter((res) => res.value === column.type)}
            />
            <Button className="custom-simple-txt-btn" onClick={() => onHandleDeleteSection(column.id)}>
              <i className="fal fa-trash ms-2 text-danger" />
            </Button>
          </div>
        </td>
        <td>
          <InputGroup>
            {numberFieldWithUnitSelector(
              get(column, 'break_point.all_styles.width[0]', '0%'),
              (value) => onChangeValue(i, value, 'all_styles', 'width'),
              'width',
              true,
              true,
              '%',
            )}
          </InputGroup>
        </td>
        <td>
          <InputGroup>
            {numberFieldWithUnitSelector(
              get(column, 'break_point.all_styles.min-width[0]', ''),
              (value) => onChangeValue(i, value, 'all_styles', 'min-width'),
              'min-width',
              true,
              true,
            )}
          </InputGroup>
        </td>
        <td>
          <InputGroup>
            {numberFieldWithUnitSelector(
              get(column, 'break_point.all_styles.max-width[0]', '100%'),
              (value) => onChangeValue(i, value, 'all_styles', 'max-width'),
              'max-width',
              true,
              true,
              '%',
            )}
          </InputGroup>
        </td>
        <td>
          <InputGroup>
            {numberFieldWithUnitSelector(
              get(column, 'break_point.all_styles.wrapper-padding-left[0]', ''),
              (value) => onChangeValue(i, value, 'all_styles', 'wrapper-padding-left'),
              'padding-left',
              true,
              true,
            )}
          </InputGroup>
        </td>
        <td>
          <InputGroup>
            {numberFieldWithUnitSelector(
              get(column, 'break_point.all_styles.wrapper-padding-right[0]', ''),
              (value) => onChangeValue(i, value, 'all_styles', 'wrapper-padding-right'),
              'padding-right',
              true,
              true,
            )}
          </InputGroup>
        </td>
      </tr>
    ));

    return (
      <DragDropComponent
        tag="tbody"
        className="column-section-drag align-middle column-row"
        keyName="text_block_ids"
        handle=".handle-sortable"
        returnData={isEmailPage ? interfaceEmail : interfaceWeb}
        list={tempColumnSections.filter((c) => !c._destroy)}
        setNewState={onHandleDrop}
      />
    );
  };

  const addCellButton = (title, onClick) => {
    return (
      <CustomButton
        color="info"
        className="add-new-button button-md fw-bold mt-4 mb-4"
        title={title}
        icon="fa fa-plus"
        onClick={onClick}
      />
    )
  };

  const columnTypeColumn = () => {
    return (
      <>
        <Table className="text-center mt-3 mb-0">
          <thead>
            {!isEmailPage && (
              <tr>
                <th />
                <th colSpan="6" className="border-start"><i className="fal fa-desktop"></i></th>
                <th colSpan="6" className="border-start"><i className="fal fa-tablet-alt"></i></th>
                <th colSpan="6" className="border-start"><i className="fal fa-mobile-alt"></i></th>
              </tr>
            )}
            <tr>
              <th />
              {!isEmailPage && (<th className="border-start">Display?</th>)}
              <th>Width</th>
              <th>Min<br />Width</th>
              <th>Max<br />Width</th>
              <th colSpan="2">Gaps<br />(L / R)</th>
              {!isEmailPage && (
                <>
                  <th className="border-start">Display?</th>
                  <th>Width</th>
                  <th>Min<br />Width</th>
                  <th>Max<br />Width</th>
                  <th colSpan="2">Gaps<br />(L / R)</th>

                  <th className="border-start">Display?</th>
                  <th>Width</th>
                  <th>Min<br />Width</th>
                  <th>Max<br />Width</th>
                  <th colSpan="2">Gaps<br />(L / R)</th>
                </>
              )}
            </tr>
          </thead>
          {renderColumns()}
        </Table>
        {addCellButton('ADD COLUMN', onHandleAddSectionColumn)}
      </>
    )
  }

  const onLayoutChange = (layout, deviceKey) => {
    const tempState = { ...tempSection };
    tempState[deviceKey] = layout.map((obj) => ({
      x: obj.x,
      y: obj.y,
      w: obj.w,
      h: obj.h,
      i: obj.i,
      maxW: 12,
    }));
    setTempSection(tempState);
  };

  const columnTypeGrid = () => {
    return (
      <>
        <Table className="text-center mt-3 mb-0">
          <thead>
            <tr>
              <th />
              <th className="border-start"><i className="fal fa-desktop"></i></th>
              <th className="border-start"><i className="fal fa-tablet-alt"></i></th>
              <th className="border-start"><i className="fal fa-mobile-alt"></i></th>
            </tr>
            <tr>
              <th />
              <th className="border-start">Display?</th>
              <th className="border-start">Display?</th>
              <th className="border-start">Display?</th>
            </tr>
          </thead>
          <tbody>
            {map(tempColumnSections.filter((c) => !c._destroy), (column, i) => (
              <tr key={`${column?.id}`}>
                <td className="text-start" style={{ width: '230px' }}>
                  <div className="d-flex align-items-center">
                    <span className="ms-2 me-2">{`${i + 1}.`}</span>
                    <CustomSelect
                      className="w-100"
                      name="selectedSectionType"
                      options={sectionTypeOptions}
                      onChange={(e) => handleSectionType(e, i, column.id)}
                      value={sectionTypeOptions.filter((res) => res.value === column.type)}
                    />
                    <Button className="custom-simple-txt-btn" onClick={() => onHandleDeleteSection(column.id)}>
                      <i className="fal fa-trash ms-2 text-danger" />
                    </Button>
                  </div>
              </td>
              <td className="border-start">
                <input
                  type="checkbox"
                  checked={get(column, 'break_point.desktop_styles.display[0]', 'block') === 'block'}
                  onChange={(e) => {
                    const value = e.target.checked ? 'block' : 'none';
                    onChangeValue(i, value, 'desktop_styles', 'display');
                  }}
                />
              </td>
              <td className="border-start">
                <input
                  type="checkbox"
                  checked={get(column, 'break_point.tablet_styles.display[0]', 'block') === 'block'}
                  onChange={(e) => {
                    const value = e.target.checked ? 'block' : 'none';
                    onChangeValue(i, value, 'tablet_styles', 'display');
                  }}
                />
              </td>
              <td className="border-start">
                <input
                  type="checkbox"
                  checked={get(column, 'break_point.phone_styles.display[0]', 'block') === 'block'}
                  onChange={(e) => {
                    const value = e.target.checked ? 'block' : 'none';
                    onChangeValue(i, value, 'phone_styles', 'display');
                  }}
                />
              </td>
            </tr>
            ))}
          </tbody>
        </Table>
        {addCellButton('ADD COLUMN', onHandleAddSectionColumn)}
        <Card>
          <CardBody>
            <Nav tabs className="separator-tabs ms-0 mb-5 custom-cursor-pointer justify-content-center">
              <NavItem>
                <NavLink
                  className={classnames({ active: toggleTab === 'desktop' })}
                  onClick={() => setToggleTab('desktop')}
                >
                  Desktop
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: toggleTab === 'tablet' })}
                  onClick={() => setToggleTab('tablet')}
                >
                  Tablet
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: toggleTab === 'phone' })}
                  onClick={() => setToggleTab('phone')}
                >
                  Mobile
                </NavLink>
              </NavItem>
            </Nav>

            {!isEmpty(tempSection) && (
              <GridLayoutView
                isDraggableResizable
                sectionData={tempSection}
                deviceKey={getDeviceType(toggleTab)}
                layoutChange={(e, y) => onLayoutChange(e, y)}
                sectorColor={tempSection.sector_color}
              />
            )}
          </CardBody>
        </Card >
      </>
    )
  }

  return (
    <>
      <AdvancedSettings
        siteId={siteId}
        instanceId={instanceId}
        isEmailPage={isEmailPage}
        section={tempSection}
        setSection={setTempSection}
        handleSaveBreakpoint={(e) => setTempSection({ ...tempSection, break_point: e })}
        onHandleSectionIsOffline={() => setTempSection({ ...tempSection, is_offline: !tempSection.is_offline })}
        onChangeInitialVisibility={() => setTempSection(
          { ...tempSection, initial_visibility: !tempSection.initial_visibility },
        )}
        onHandleChangeVisibility={(e) => setTempSection({ ...tempSection, visible_to: e.value })}
      />
      {!isEmailPage && (
        <Card>
          <CardBody>
            <CardTitle>Layout</CardTitle>
            <FormGroup>
              <Label className="d-flex me-2">
                <input
                  type="radio"
                  value="column"
                  className="me-2"
                  checked={tempSection?.layout === 'column'}
                  onChange={(e) => {
                    setTempSection({ ...tempSection, layout: e.target.value })
                  }}
                />
                Column
              </Label>
              <Label className="d-flex me-2">
                <input
                  type="radio"
                  value="grid"
                  className="me-2"
                  checked={tempSection?.layout === 'grid'}
                  onChange={(e) => {
                    setTempSection({ ...tempSection, layout: e.target.value })
                  }}
                />
                Grid
              </Label>
            </FormGroup>
          </CardBody>
        </Card>
      )}

      {tempSection?.layout === 'column' ?
        <div className="column-section-editor">{columnTypeColumn()}</div> : columnTypeGrid()
      }
    </>
  );
}

export default withRouter(ColumnType);
