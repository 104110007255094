/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { Input, Row, Col, Alert, Card, CardBody } from 'reactstrap';
import TemplateApi from 'dashboard/src/api/template';
import { displaySuccess, displayError, setPageTitle, selectColorStyles } from '../../../../../../Utils';
import withRouter from 'dashboard/src/helpers/withRouter';
import Select from 'react-select';
import HeaderComponent from 'dashboard/src/components/common/header-component';
import { settingRoutes, replacePathParams, siteCenter } from 'dashboard/src/constants/routes';
import BottomActionToolbar from 'dashboard/src/components/common/toolbar';
import SaveAndContinue from 'dashboard/src/components/common/saveAndContinue';
import TemplateEditorGrid from './TemplateEditorGrid';
import { useGetSitesQuery } from 'dashboard/src/api/apiSlice';
import { isEmpty } from 'lodash-es';
import { addRecentItem } from '../../../../../../UtilsTS';

const TemplateEditGrid = (props) => {
  const { params: { siteId, id, instanceId } } = props;
  /* Handle errors with state */
  const [errorVal, setError] = useState('');

  const [sectorList, setSectorList] = useState([]);

  /* Initializing states */
  const [params, setParams] = useState({
    id: '',
    site_id: siteId,
    instance_id: instanceId,
    name: '',
    html: '',
    template_type: '',
    desktop_layout: [],
    tablet_layout: [],
    phone_layout: [],
    desktop_width: '2000px',
    tablet_width: '100%',
    phone_width: '100%',
  });

  if (instanceId) {
    addRecentItem({
      instance_id: instanceId,
      type: 'edit-template',
      item_id: id,
    });
  }

  /* Initializing temp state for params */
  const paramsTemp = { ...params };

  useEffect(() => {
    try {
      const fetchingTemplateData = async () => {
        const sendParams = {
          site_id: siteId,
          id,
          instance_id: params.instance_id,
        };
        const response = await TemplateApi.templateGet(sendParams);
        if (undefined !== response) {
          const { data: { template, sectors } } = response.data;
          if (template) {
            Object.keys(template).forEach((i) => { paramsTemp[i] = template[i]; });
            setParams(template);
            setSectorList(sectors);
          }
        }
      };
      fetchingTemplateData();
      setPageTitle('Edit template');
    } catch (error) {
      displayError(`${error?.name}: ${error?.message}`);
    }
  }, []);

  const { data: sites } = useGetSitesQuery(instanceId, { skip: isEmpty(instanceId) });

  const onSelectSite = (e) => { paramsTemp.site_id = e.value; setParams(paramsTemp); };

  const onTemplateNameChange = (e) => {
    const { target: { value } } = e;
    paramsTemp.name = value;
    setParams(paramsTemp);
  };

  const onHandleSubmit = async () => {
    try {
      const sectorLabels = sectorList.map((sector) => (sector.label));
      if (!params.name) {
        displayError('Template name should not be blank!');
      } else if (!params.site_id) {
        displayError('Site should not be blank!');
      } else if (sectorLabels.includes('')) {
        displayError('Sector name should not be blank!');
      } else if (params.desktop_width.match(/%/) && parseFloat(params.desktop_width.replace(/[^0-9]+/)) > 100) {
        displayError('Desktop width should not be more than 100%');
      } else if (params.tablet_width.match(/%/) && parseFloat(params.tablet_width.replace(/[^0-9]+/)) > 100) {
        displayError('Tablet width should not be more than 100%');
      } else if (params.phone_width.match(/%/) && parseFloat(params.phone_width.replace(/[^0-9]+/)) > 100) {
        displayError('Mobile width should not be more than 100%');
      } else {
        setError('');
        const sectorIds = sectorList.map((sector) => (sector.id));
        params.sector_ids = sectorIds;
        const response = await TemplateApi.templateUpdate(params);
        const { data } = response;
        displaySuccess(`${data.messages}`);
      }
    } catch (error) {
      displayError(`${error?.name}: ${error?.message}`);
    }
  };

  const getSelectedOption = (options, selected_option) => (
    options.filter((option) => option.id === selected_option)
  );

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    {
      name: 'Site Centre',
      url: replacePathParams(siteCenter.pageList, [], props),
    },
    {
      name: 'Design Options',
      url: replacePathParams(siteCenter.designOption, [], props),
    },
    {
      name: 'Templates',
      url: replacePathParams(siteCenter.templateList, [], props),
    },
    { name: `${params.name || ''}`, url: '' },
  ];

  const getSiteOptions = (options) => options.map((opt) => ({ label: opt.label, value: opt.id }));

  return (
    <>
      <HeaderComponent
        setPath={{
          addNewPath: '',
          addNewBtnName: '',
          backBtnName: 'Back',
          staticBreadcrumbData,
          showBreadcrumb: false,
          headingName: 'Editing Template',
        }}
      />
      {errorVal ? <Alert color="danger" className="custom-alert-span">{errorVal}</Alert> : ''}
      <Row>
        <Col className="col-sm-10">
          <Input autoFocus placeholder="Enter title here" value={params.name || ''} onChange={onTemplateNameChange} />
        </Col>
        <Col className="col-sm-2 text-end">
          <SaveAndContinue
            onSave={onHandleSubmit}
            onContinue={replacePathParams(siteCenter.templateList, [
              { key: 'siteId', value: siteId },
              { key: 'instanceId', value: instanceId },
            ])}
          />
        </Col>
      </Row>
      <hr />
      <Card className="mb-4">
        <CardBody>
          <Row>
            <Col lg={6} className="mb-3">
              <p><b>Select site:</b></p>
              <Select
                value={getSelectedOption(sites, params.site_id)}
                placeholder="Sites"
                onChange={onSelectSite}
                options={getSiteOptions(sites)}
                styles={selectColorStyles}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
      <Row>
        <Col>
          <TemplateEditorGrid template={params} onChange={setParams} />
        </Col>
      </Row>
      <BottomActionToolbar
        component={(
          <SaveAndContinue
            onSave={onHandleSubmit}
            onContinue={replacePathParams(siteCenter.templateList, [
              { key: 'siteId', value: siteId },
              { key: 'instanceId', value: instanceId },
            ])}
          />
        )}
      />
    </>
  );
};
export default withRouter(TemplateEditGrid);
