import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useGetSiteBriefingsQuery,
  useCreateSiteBriefingMutation,
  useDeleteSiteBriefingMutation,
  useGenerateInstanceFromSiteBriefingMutation,
} from '../../../api/apiSlice';
import TableComponent from '../../../components/common/Table';
import { Row, Card, CardBody } from 'reactstrap';
import CustomButton from '../../../components/common/button';
import { setPageTitle, displaySuccess, displayError } from '../../../../../Utils';
import { useUser } from '../../../hooks';
import UserFeaturePage from '../../../components/common/user-feature-page';
import UserFeatureComponent from '../../../components/common/user-feature-component';
import HeaderComponent from '../../../components/common/header-component';
import CustomSelect from '../../../components/common/custom-select';
import { DateTime } from 'luxon';
import { dateTimeString } from '../../../components/common/DateTimeFormat';
import confirm from '../../../components/common/confirm';
import { mainRoutes, settingRoutes, replacePathParams } from '../../../constants/routes';
import withRouter from '../../../helpers/withRouter';
import BottomActionToolbar from '../../../components/common/toolbar';

function SiteBriefingList(props) {
  const { data: briefings } = useGetSiteBriefingsQuery();
  const navigate = useNavigate();
  const [createSiteBriefing] = useCreateSiteBriefingMutation();
  const [deleteBriefing] = useDeleteSiteBriefingMutation();
  const [generateInstance] = useGenerateInstanceFromSiteBriefingMutation();

  const user = useUser();

  useEffect(() => {
    setPageTitle('Site Briefing List');
  }, []);

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    {
      name: 'Settings',
      url: replacePathParams(mainRoutes.setting, [], props),
    },
    { name: 'Briefings', url: '' },
  ];

  const tableColumns = [
    {
      Header: 'Site Created',
      id: 'site_created',
      accessor: (b) => b.site_id,
      Cell: ({ value }) => (
        <>
          {value && <span style={{ fontSize: 14, color: 'green' }}>✓</span>}
          {!value && <span style={{ fontSize: 14, color: 'red' }}>✗</span>}
        </>
      ),
    },
    {
      Header: 'Site Created At',
      id: 'site_created_at',
      accessor: (b) => b.site_created_at,
      Cell: ({ value }) => value ? dateTimeString(value, user?.time_zone, DateTime.DATE_SHORT) : '',
    },
    {
      Header: 'Company Name',
      id: 'company_name',
      accessor: (b) => b.info.company_name,
    },
    {
      Header: 'Domain',
      id: 'domain',
      accessor: (b) => b.info.temp_domain ? `www.${b.info.temp_domain}.wb.gs` : '',
    },
    {
      Header: 'Project Manager',
      id: 'project_manager',
      accessor: (b) => b.project_manager_name,
    },
    {
      Header: 'Adviser',
      id: 'adviser',
      accessor: (b) => b.adviser_name,
    },
    {
      Header: 'Brief Date',
      id: 'brief_date',
      accessor: (b) => b.info.brief_date,
      Cell: ({ value }) => dateTimeString(value, user?.time_zone, DateTime.DATE_SHORT),
    },
    {
      Header: 'Updated',
      id: 'updated',
      accessor: (b) => b.updated_at,
      Cell: ({ value }) => dateTimeString(value, user?.time_zone, DateTime.DATE_SHORT),
    },
    {
      Header: 'Actions',
      id: 'actions',
      Cell: ({ row: { original: b }}) => {
        const options = [
          { label: 'Edit', value: 'Edit' },
          { label: 'Delete', value: 'Delete' },
        ];

        if (!b.site_id) {
          options.push({ label: 'Generate Instance', value: 'Generate Instance' });
        }

        options.push({ label: 'Download PDF', value: 'Download PDF' });

        return (
          <CustomSelect
            options={options}
            onChange={(e) => {
              switch (e.value) {
                case 'Edit':
                  return onEdit(b);
                case 'Delete':
                  return onDelete(b);
                case 'Generate Instance':
                  return onGenerateInstance(b);
                case 'Download PDF':
                  window.location.assign(`/api/v1/briefings/${b.id}/pdf`);
              }

              return null;
            }}
          />
        );
      },
    },
  ];

  const onEdit = (briefing) => {
    navigate(replacePathParams(settingRoutes.siteBriefingForm, [{ key: 'id', value: briefing.id }], props));
  };

  const onDelete = async (briefing) => {
    const result = await confirm({
      title: <b>Confirm!</b>,
      message: <p>Are you sure?</p>,
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });

    if (result) {
      deleteBriefing(briefing);
    }
  };

  const onGenerateInstance = async (briefing) => {
    const confirmed = await confirm({
      title: <b>Confirm!</b>,
      message: <p>Are you sure?</p>,
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });

    if (confirmed) {
      const result = await generateInstance(briefing);
      if (result.error) {
        displayError(`Errors in instance generation:\n\n${Object.keys(result.error.data.data).map((k) => result.error.data.data[k])}`);
      } else {
        displaySuccess('Instance generation started.');
      }
    }
  };

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Briefings',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Settings',
        backToPath: replacePathParams(mainRoutes.setting, [], props),
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <UserFeaturePage feature={['view_briefings', 'create_edit_briefings']}>
        <UserFeatureComponent feature="view_briefings">
          <Row className="SiteBriefing">
            <Card className="mb-4">
              <CardBody>
                  <TableComponent
                    columns={tableColumns}
                    data={briefings || []}
                  />
              </CardBody>
            </Card>
          </Row>
        </UserFeatureComponent>

        <UserFeatureComponent feature="create_edit_briefings">
          <BottomActionToolbar
            component={(
              <CustomButton
                color="info"
                className="add-new-button button-md fw-bold"
                title="ADD BRIEFING"
                icon="fa fa-plus"
                onClick={createSiteBriefing}
              />
            )}
          />
        </UserFeatureComponent>
      </UserFeaturePage>
    </>
  );
}

export default withRouter(SiteBriefingList);
