import ComponentCategoryApi from '../../../api/component_category';
import { displaySuccess } from '../../../../../Utils';
import { SET_COMPONENT_CATEGORIES, SET_COMPONENT_CATEGORY, UPDATE_COMPONENT_CATEGORY, CREATE_COMPONENT_CATEGORY, DELETE_COMPONENT_CATEGORY } from '../../actions';

const actionType = (payload, type) => ({
  type,
  payload,
});

export const getComponentCategoryListing = (params) => async (dispatch) => {
  try {
    const response = await ComponentCategoryApi.index(params);
    dispatch(actionType(response.data.data, SET_COMPONENT_CATEGORIES));
  } catch (err) {
    dispatch(actionType([], SET_COMPONENT_CATEGORIES));
  }
};

export const getComponentCategory = (params) => async (dispatch) => {
  try {
    const response = await ComponentCategoryApi.show(params);
    dispatch(actionType(response.data.data, SET_COMPONENT_CATEGORY));
  } catch (err) {
    dispatch(actionType([], SET_COMPONENT_CATEGORY));
  }
};

export const updateComponentCategory = (params) => async (dispatch) => {
  try {
    const response = await ComponentCategoryApi.update(params);
    dispatch(actionType(response.data.data, UPDATE_COMPONENT_CATEGORY));
  } catch (err) {
    dispatch(actionType([], UPDATE_COMPONENT_CATEGORY));
  }
};

export const createComponentCategory = (params) => async (dispatch) => {
  try {
    const response = await ComponentCategoryApi.create(params);
    dispatch(actionType(response.data.data, CREATE_COMPONENT_CATEGORY));
    displaySuccess(response.data.messages);
  } catch (err) {
    dispatch(actionType({}, CREATE_COMPONENT_CATEGORY));
  }
};

export const deleteComponentCategory = (params) => async (dispatch) => {
  try {
    const response = await ComponentCategoryApi.delete(params);
    dispatch(actionType(params?.id, DELETE_COMPONENT_CATEGORY));
    displaySuccess(response.data.messages);
  } catch (err) {
    dispatch(actionType(null, DELETE_COMPONENT_CATEGORY));
  }
};
