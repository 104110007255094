import React from 'react';
import { useSelector } from 'react-redux';
import { Spinner } from 'reactstrap';

function Loader() {
  const { spinCount, isDuplicateInstance } = useSelector((state) => state.adminStore.spinner);
  if (spinCount > 0 && !isDuplicateInstance) {
    return (
      <div className="spinner-outer">
        <div className="spinner-inner">
          <Spinner />
        </div>
      </div>
    );
  }
  return '';
}

export default Loader;
