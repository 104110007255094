/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from 'react';
import RGL, { WidthProvider } from 'react-grid-layout';
import { Label, Input } from 'reactstrap';
import { formSectionInputType, databaseFieldType } from '../../../../../schema/formSection';
import { filter, get, isEmpty, map } from 'lodash-es';
import Select from 'react-select';
import { returnTextAreaValues } from '../../../../../views/site-centre/Section/Form';
import { baseDomain } from '../../../../../../../api';
import { useParams } from 'react-router-dom';
import withRouter from '../../../../../helpers/withRouter';
import { selectColorStyles } from '../../../../../../../Utils';
import { checkboxView } from '../../Form';
import { useGetButtonStyleByIdQuery } from '../../../../../api/apiSlice';
import { getBackgroundColor, getForegroundColor } from '../../../design-options/templates/TemplateEditorGrid';
import { sectionFriendlyNames } from 'dashboard/src/schema/index.js';

const ReactGridLayout = WidthProvider(RGL);

const formLayoutView = (data) => {
  if (!isEmpty(data)) {
    const { form_items } = data;
    return form_items.map((ele) => {
      const {
        id, field_type, name, values, is_required, placeholder, break_values_in_newline,
      } = ele;
      switch (field_type) {
        case formSectionInputType.SINGLE_LINE:
        case databaseFieldType.SHORT_TEXT:
        case databaseFieldType.DATE:
          return (
            <div key={`${id}`}>
              <Label style={{ width: '100%' }}>
                {name}
                {is_required ? <span style={{ color: 'red' }}>*</span> : ''}
                <Input type="text" placeholder={placeholder || ''} disabled />
              </Label>
            </div>
          );
        case formSectionInputType.MULTI_LINE:
        case databaseFieldType.LONG_TEXT:
          return (
            <div key={`${id}`}>
              <Label style={{ width: '100%' }}>
                {name}
                {is_required ? <span style={{ color: 'red' }}>*</span> : ''}
                <Input
                  type="textarea"
                  placeholder={placeholder || ''}
                  value={returnTextAreaValues(values) || ''}
                  disabled
                />
              </Label>
            </div>
          );
        case formSectionInputType.DROPDOWN:
        case databaseFieldType.SELECT_LIST:
          return (
            <div key={`${id}`}>
              {name && (
                <Label style={{ width: '100%' }}>
                  {name}
                  {is_required ? <span style={{ color: 'red' }}>*</span> : ''}
                </Label>
              )}
              <Select
                styles={selectColorStyles}
                options={values.map((opt) => ({ label: opt, value: opt }))}
                isDisabled
              />
            </div>
          );
        case formSectionInputType.CHECKBOX:
          return (
            <div key={`${id}`}>
              {name && (
                <Label style={{ width: '100%' }}>
                  {name}
                  {is_required ? <span style={{ color: 'red' }}>*</span> : ''}
                </Label>
              )}
              <ul className={
                !break_values_in_newline ? 'custom-ul-layout mb-0 display-inline-li' : 'custom-ul-layout mb-0'
              }
              >
                {!isEmpty(values) && values.map((opt, index) => (
                  checkboxView(
                    opt,
                    false,
                    (e) => e.preventDefault(),
                    `check_${index}`,
                  )
                ))}
              </ul>
            </div>
          );
        case formSectionInputType.RADIO:
          return (
            <div key={`${id}`}>
              {name && (
                <Label style={{ width: '100%' }}>
                  {name}
                  {is_required ? <span style={{ color: 'red' }}>*</span> : ''}
                </Label>
              )}
              <ul className={
                !break_values_in_newline ? 'custom-ul-layout mb-0 display-inline-li' : 'custom-ul-layout mb-0'
              }
              >
                {!isEmpty(values) && values.map((opt, index) => (
                  <li key={index}>
                    <Label
                      key={`radio_${index}`}
                      style={{ width: '100%' }}
                      className="radio-container checkbox-radio-container"
                    >
                      <Input
                        type="radio"
                        name={opt}
                        onChange={(e) => e.preventDefault()}
                        disabled
                      />
                      {opt}
                      <span className="checkmark checkmark-radio" />
                    </Label>
                  </li>
                ))}
              </ul>
            </div>
          );
        case formSectionInputType.HIDDEN:
          return (
            <Input key={`${id}`} name={name} value={values} type="hidden" disabled />
          );
        case formSectionInputType.TEXT:
          return (
            <div key={`${id}`}>
              <Label style={{ width: '100%' }}>
                <b>{name}</b>
                {values}
              </Label>
            </div>
          );
        case formSectionInputType.FILE_UPLOAD:
          return (
            <div key={`${id}`}>
              <Input type="file" name="avatar" disabled />
            </div>
          );
        case databaseFieldType.EMAIL:
          return (
            <div key={`${id}`}>
              {name}
              <Input
                type="email"
                placeholder={placeholder || ''}
                disabled
              />
            </div>
          );
        case databaseFieldType.NUMERIC:
          return (
            <div key={`${id}`}>
              {name}
              <Input
                type="number"
                placeholder={placeholder || ''}
                disabled
              />
            </div>
          );
        default:
          return <div key={id}>TEST</div>;
      }
    });
  }
  return null;
};

function GridLayoutView(props) {
  const {
    deviceKey,
    sectionData,
    layoutChange,
    isDraggableResizable,
    sectorColor,
  } = props;

  const { siteId } = useParams();

  const [isHovering, setIsHovering] = useState(false);
  const [mounted, setMounted] = useState(false);
  const [mouseOverUrl, setMouseOverUrl] = useState(false);
  const [buttonStyle, setButtonStyle] = useState({});

  const {
    button_text,
    button_style_id,
    button_image_url,
    submit_button_type,
    button_hover_image_url,
  } = sectionData;

  const { data } = useGetButtonStyleByIdQuery({ site_id: siteId, id: button_style_id }, { skip: isEmpty(button_style_id) ? true : false });

  useEffect(() => {
    if (!isEmpty(data)) {
      setButtonStyle(data);
    }
  }, [data]);

  useEffect(() => {
    setMounted(true);
  }, [mounted]);

  const onHandleLayout = (e) => {
    layoutChange(e, deviceKey);
  };

  const onHandleMouseOver = () => setMouseOverUrl(!mouseOverUrl);

  const submitImageUrl = (isMouseOver) => {
    if (isMouseOver && button_hover_image_url) {
      return `${baseDomain}${button_hover_image_url}`;
    }
    return `${baseDomain}${button_image_url}`;
  };

  const getEleHeight = (data) => {
    const { field_type } = data;
    switch (field_type) {
      case formSectionInputType.HIDDEN:
        return 0;
      case formSectionInputType.RADIO:
      case formSectionInputType.CHECKBOX: {
        const h = Math.round((data.values.length / 2.5) + 1);
        return h;
      }
      default: return 1;
    }
  };

  const gridLayout = (data) => {
    if (!isEmpty(data[deviceKey])) {
      return data[deviceKey];
    }
    const { form_items, column_sections } = data;
    const items = form_items || column_sections || [];
    const getObj = map(items, (ele, index) => ({
      x: 0,
      y: index,
      w: 12,
      h: getEleHeight(ele),
      i: ele.id,
      maxW: 12,
    }));
    return getObj;
  };

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const submitButtonCSS = () => {
    let css = {};
    let hover_css = {};
    if (!isEmpty(buttonStyle)) {
      Object.keys(buttonStyle.css).forEach((element) => {
        css = { ...css, [element]: buttonStyle.css[element] };
      });
      Object.keys(buttonStyle.hover_css).forEach((element) => {
        hover_css = { ...hover_css, [element]: buttonStyle.hover_css[element] };
      });
    }
    return { css, hover_css };
  };

  const renderButton = () => {
    switch (submit_button_type) {
      case 'image':
        return (
          <Input
            type="image"
            name="submit"
            src={submitImageUrl(mouseOverUrl)}
            style={{ border: 'none' }}
            onMouseOver={onHandleMouseOver}
            onMouseOut={onHandleMouseOver}
            onFocus={() => undefined}
            onBlur={() => undefined}
          />
        );
      case 'button_style':
        return (
          <Input
            type="submit"
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            style={isHovering ? submitButtonCSS().hover_css : submitButtonCSS().css}
          />
        );
      default: return (
        <Input type="submit" name="submit" value={button_text} />
      );
    }
  };

  const layoutType = () => {
    switch (sectionData?.type) {
      case "SectionColumn":
        return (
          map(filter(sectionData?.column_sections, (c) => !c._destroy), (c, i) => {
            // Skip sections that have display: none
            if (deviceKey === 'desktop_layout' && get(c, 'break_point.desktop_styles.display[0]') === 'none') {
              return;
            }
            if (deviceKey === 'tablet_layout' && get(c, 'break_point.tablet_styles.display[0]') === 'none') {
              return;
            }
            if (deviceKey === 'phone_layout' && get(c, 'break_point.phone_styles.display[0]') === 'none') {
              return;
            }

            return (
            <div
              key={c.id}
              style={{
                backgroundColor: getBackgroundColor(i),
                color: getForegroundColor(i),
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span>
                {`${i + 1}: ${sectionFriendlyNames[c?.type]}`}
              </span>
            </div>
          );
        })
        )
      case "SectionForm":
        return [
          ...formLayoutView(sectionData),
          ...[!isEmpty(sectionData) && (
              <div key="submit_button">
                {renderButton()}
              </div>
            )],
        ];
      default: return null;
    }
  }

  return (
    <ReactGridLayout
      cols={12}
      rowHeight={60}
      width={1200}
      margin={[0, 0]}
      layout={gridLayout(sectionData)}
      className="layout"
      useCSSTransforms={mounted}
      onLayoutChange={(e) => onHandleLayout(e)}
      isDraggable={isDraggableResizable}
      isResizable={isDraggableResizable}
      style={{ background: sectorColor }}
    >
      {layoutType()}
    </ReactGridLayout>
  );
}

export default withRouter(GridLayoutView);
