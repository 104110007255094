import React, { useEffect, useState } from 'react';
import {
  Card,
  CardColumns,
  CardBody,
  Container,
  Row,
  Col,
} from 'reactstrap';
import HeaderComponent from '../../../components/common/header-component';
import { settingRoutes, replacePathParams } from '../../../constants/routes';
import BottomActionToolbar from '../../../components/common/toolbar';
import {
  PopupPage,
  PopupStyle,
  PopupWidth,
  PopupTrigger,
  PopupFrequency,
  PopupVisibility,
} from '../../../components/fields/common/';
import SaveAndContinue from '../../../components/common/saveAndContinue';
import withRouter from '../../../helpers/withRouter';
import { useUpdateSiteMutation } from '../../../api/apiSlice';
import UserFeaturePage from '../../../components/common/user-feature-page';
import { useInstance, useSite } from '../../../hooks';
import { get } from 'lodash-es';
import { addRecentItem } from '../../../../../UtilsTS';

function SiteSettingPopup(props) {
  const [params, setParams] = useState({});

  const site = useSite();
  const instance = useInstance();
  const siteId = get(site, 'id', null);
  const instanceId = get(instance, 'id', null);
  const [updateSite] = useUpdateSiteMutation();

  useEffect(() => {
    if (site) {
      setParams(site);
    }
  }, [site]);

  const onSave = () => {
    updateSite({
      id: siteId,
      instance_id: instanceId,
      ...params,
    });

    return true;
  };

  if (instanceId) {
    addRecentItem({
      instance_id: instanceId,
      type: 'settings-popup',
    });
  }

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    { name: 'Site-wide Popup', url: '' },
  ];

  return (
    <div className="EditSite">
      <HeaderComponent
        setPath={{
          headingName: 'Site-wide Popup',
          addNewBtnName: '',
          addNewPath: '',
          backBtnName: 'Back',
          backToPath: settingRoutes.siteList,
          showBreadcrumb: false,
          staticBreadcrumbData,
        }}
      />
      <UserFeaturePage feature="manage_site_wide_popup">
        <CardColumns>
          <Card>
            <CardBody>
              <Container>
                <Row>
                  <Col>
                    <PopupPage
                      instanceId={instanceId}
                      siteId={siteId}
                      params={params}
                      setParams={setParams}
                    />
                  </Col>
                </Row>
                {params.popup_page_id && (
                  <>
                    <Row>
                      <Col>
                        <PopupVisibility params={params} setParams={setParams} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <PopupFrequency params={params} setParams={setParams} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <PopupStyle params={params} setParams={setParams} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <PopupWidth params={params} setParams={setParams} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <PopupTrigger params={params} setParams={setParams} />
                      </Col>
                    </Row>
                  </>
                )}
              </Container>
            </CardBody>
          </Card>
        </CardColumns>

        <BottomActionToolbar
          component={(
            <SaveAndContinue
              onSave={onSave}
              onContinue={replacePathParams(settingRoutes.siteList, [], props)}
            />
          )}
        />
      </UserFeaturePage>
    </div>
  );
}

export default withRouter(SiteSettingPopup);
