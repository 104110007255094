import React, { useState } from 'react';
import {
  Row, Col, Label, Card, CardBody, FormGroup, Collapse, Button, Input,
} from 'reactstrap';
import Switch from 'rc-switch';
import Select from 'react-select';
import CSSBoxModel from '../../../components/common/breakpoint/boxModel';
import { selectColorStyles } from '../../../../../Utils';
import { isEmpty } from 'lodash-es';
import { renderBreakpoint } from '../textStyles';
import CustomTooltip from 'dashboard/src/components/common/CustomTooltip';

export const visibilityOptions = [
  { label: 'All database members', value: 'all_database_members' },
  { label: 'All visitors to your site', value: 'all_visitors_to_your_site' },
  { label: 'Those not on your database', value: 'those_not_on_your_database' },
];

const getSelectedOption = (options, option) => options.filter((opt) => opt.value === option);

function AdvancedSettings({
  siteId,
  isEmailPage,
  section,
  setSection,
  handleSaveBreakpoint,
  onHandleSectionIsOffline,
  onHandleChangeVisibility,
  onChangeInitialVisibility,
}) {
  const [accordion, setAccordion] = useState(false);
  const [tempCSSClasses, setTempCSSClasses] = useState(null);

  const cssClassRegex = /^[_a-zA-Z][_a-zA-Z0-9-]*$/;

  const onHandleAccordion = () => {
    setAccordion(!accordion);
  };

  const breakpointObj = {
    isEmailPage,
    siteId: siteId,
    id: section?.id,
    showSettingType: true,
    objectType: section?.type,
    onChange: handleSaveBreakpoint,
    breakPoint: section?.break_point,
  };
  // We want the whole bar to be clickable when it's collapsed but only the header when it's expanded.
  return (
    <Card
      className={`mb-4 rounded ${!accordion && 'cursor-pointer'}`}
      onClick={() => !accordion && onHandleAccordion()}
    >
      <CardBody>
        <Row
          className={accordion ? 'cursor-pointer' : ''}
          onClick={() => accordion && onHandleAccordion()}
        >
          <Col lg={8}>
            <h2 className="mb-0">Advanced Settings</h2>
          </Col>
          <Col lg={4} className="text-end">
            <Button
              className={`custom-simple-txt-btn ${accordion ? 'custom-text-color-blue' : ''}`}
              aria-expanded={accordion}
            >
              <h4 className="d-flex align-items-center justify-content-center">
                <i
                  className={`${
                    accordion
                      ? 'fa fa-arrow-circle-down'
                      : 'fa fa-arrow-circle-right'
                  }`}
                />
              </h4>
            </Button>
          </Col>
        </Row>
        <Collapse isOpen={accordion}>
          {!isEmpty(section)
            ? (
              <>
                <Row className="margin-t25 mb-2">
                  <Col>
                    <Label>
                      Online:
                      {' '}
                      <Switch
                        className="custom-switch custom-switch-primary d-inline-block"
                        checked={!section.is_offline}
                        onChange={onHandleSectionIsOffline}
                      />
                    </Label>
                  </Col>
                  {!isEmailPage
                    && (
                      <Col>
                        <Label>
                          Initial Visibility:
                          {' '}
                          <Switch
                            className="custom-switch custom-switch-primary d-inline-block"
                            checked={section.initial_visibility}
                            onChange={onChangeInitialVisibility}
                          />
                        </Label>
                      </Col>
                    )}
                  <Col>
                    <Label className="w-100">
                      Visibility:
                      {' '}
                      <Select
                        placeholder="Visible to"
                        value={getSelectedOption(visibilityOptions, section.visible_to)}
                        options={visibilityOptions}
                        onChange={onHandleChangeVisibility}
                        styles={selectColorStyles}
                      />
                    </Label>
                  </Col>
                </Row>
                {!isEmailPage
                  && (
                    <CSSBoxModel
                      labelName="Margin (outside):"
                      eleTop={renderBreakpoint({...breakpointObj, settingName: 'margin-top'})}
                      eleLeft={renderBreakpoint({...breakpointObj, settingName: 'margin-left'})}
                      eleRight={renderBreakpoint({...breakpointObj, settingName: 'margin-right'})}
                      eleBottom={renderBreakpoint({...breakpointObj, settingName: 'margin-bottom'})}
                    />
                  )}
                <hr />
                <CSSBoxModel
                  labelName="Padding (inside):"
                  eleTop={renderBreakpoint({...breakpointObj, settingName: 'padding-top'})}
                  eleLeft={renderBreakpoint({...breakpointObj, settingName: 'padding-left'})}
                  eleRight={renderBreakpoint({...breakpointObj, settingName: 'padding-right'})}
                  eleBottom={renderBreakpoint({...breakpointObj, settingName: 'padding-bottom'})}
                />
                <hr />
                <CSSBoxModel
                  labelName="Border width:"
                  eleTop={renderBreakpoint({...breakpointObj, settingName: 'border-top-width'})}
                  eleLeft={renderBreakpoint({...breakpointObj, settingName: 'border-left-width'})}
                  eleRight={renderBreakpoint({...breakpointObj, settingName: 'border-right-width'})}
                  eleBottom={renderBreakpoint({...breakpointObj, settingName: 'border-bottom-width'})}
                />
                <hr />
                <Row>
                  <Col className="mb-4" md={6}>
                    <Label className="w-100">Border colour:</Label>
                    <Row className="mb-2">
                      <Col>
                        {renderBreakpoint({...breakpointObj, settingName: 'border-color'})}
                      </Col>
                    </Row>
                  </Col>
                  <Col className="mb-4" md={6}>
                    <Label className="w-100">Background colour:</Label>
                    <FormGroup>
                      {renderBreakpoint({...breakpointObj, settingName: 'background'})}
                    </FormGroup>
                  </Col>
                  <Col className="mb-4" md={6}>
                    <Label className="w-100">Background image:</Label>
                    <FormGroup>
                      {renderBreakpoint({...breakpointObj, settingName: 'background-image'})}
                    </FormGroup>
                  </Col>
                  <Col className="mb-4" md={6}>
                    <Label className="w-100">Background horizontal position:</Label>
                    <FormGroup>
                      {renderBreakpoint({...breakpointObj, settingName: 'background-position-x'})}
                    </FormGroup>
                  </Col>
                  <Col className="mb-4" md={6}>
                    <Label className="w-100">Background vertical position:</Label>
                    <FormGroup>
                      {renderBreakpoint({...breakpointObj, settingName: 'background-position-y'})}
                    </FormGroup>
                  </Col>
                  <Col className="mb-4" md={6}>
                    <Label className="w-100">Background size:</Label>
                    <FormGroup>
                      {renderBreakpoint({...breakpointObj, settingName: 'background-size'})}
                    </FormGroup>
                  </Col>
                  <Col className="mb-4" md={6}>
                    <Label className="w-100">Background repeat:</Label>
                    <FormGroup>
                      {renderBreakpoint({...breakpointObj, settingName: 'background-repeat'})}
                    </FormGroup>
                  </Col>
                  <Col className="mb-4" md={12}>
                    <FormGroup>
                      <CustomTooltip name="SectionCSSClasses">
                        <Label className="has-tooltip">
                          CSS Classes
                        </Label>
                      </CustomTooltip>
                      <Input
                        type="text"
                        value={tempCSSClasses === null ? section.css_classes.join(' ') : tempCSSClasses}
                        invalid={tempCSSClasses && !tempCSSClasses.split(/\s+/).every((c) => c.match(cssClassRegex))}
                        onChange={(e) => {
                          setTempCSSClasses(e.target.value);
                          const classes = e.target.value.split(/\s+/);
                          if (classes.every((c) => c === '' || c.match(cssClassRegex))) {
                            setSection({ ...section, css_classes: classes });
                          }
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </>
            )
            : ''}
        </Collapse>

      </CardBody>
    </Card>
  );
}

export default AdvancedSettings;
