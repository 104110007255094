import AutoReportAPI from '../../api/automated_report';
import { displayError } from '../../../../Utils';
import { SET_AUTO_REPORT } from '../actions';

const dispatchAction = (payload, type) => ({
  type,
  payload,
});

export const getAutoReports = (params) => async (dispatch) => {
  try {
    const response = await AutoReportAPI.autoReportGet(params);
    const { data } = response.data;
    dispatch(dispatchAction(data, SET_AUTO_REPORT));
  } catch (err) {
    dispatch(dispatchAction([], SET_AUTO_REPORT));
  }
};

export const runReport = (params) => async () => {
  try {
    await AutoReportAPI.autoReportRun(params);
  } catch (err) {
    displayError(`${err?.name}: ${err?.message}`);
  }
};
