import React from 'react';
import { Label } from 'reactstrap';
import CustomButton from '../../../components/common/button';
import PropTypes from 'prop-types';

function RadioButton({
  title,
  checked,
  display,
  disabled,
  className,
  onHandleChange,
}) {
  return (
    <Label style={{ display }}>
      <CustomButton
        title={title}
        disabled={disabled}
        onClick={onHandleChange}
        icon={checked ? 'fa fa-dot-circle-o' : 'fa fa-circle-thin'}
        className={`custom-simple-txt-btn fw-normal ${className}`}
      />
    </Label>
  );
}

RadioButton.propTypes = {
  disabled: PropTypes.bool,
  display: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onHandleChange: PropTypes.func.isRequired,
};

RadioButton.defaultProps = {
  disabled: false,
  display: 'inline-block',
  className: 'custom-simple-txt-btn fw-normal',
};

export default RadioButton;
