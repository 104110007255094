import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';

function CustomButton(props) {
  const {
    size,
    icon,
    title,
    style,
    color,
    onClick,
    disabled,
    className,
  } = props;
  return (
    <Button
      {...props}
      size={size}
      color={color}
      className={className}
      disabled={disabled}
      onClick={onClick}
      style={style}
      type="submit"
    >
      {icon && <i className={icon} /> }
      {typeof title === 'string' ? ` ${title}` : title}
    </Button>
  );
}

CustomButton.propTypes = {
  title: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  style: PropTypes.shape({}),
  className: PropTypes.string,
  size: PropTypes.string,
  color: PropTypes.oneOf([
    'primary',
    'info',
    'success',
    'warning',
    'danger',
    'rose',
    'white',
    'twitter',
    'facebook',
    'google',
    'linkedin',
    'pinterest',
    'youtube',
    'tumblr',
    'github',
    'behance',
    'dribbble',
    'reddit',
    'transparent',
    'lightBlue',
  ]),
  onClick: PropTypes.func.isRequired,
};

CustomButton.defaultProps = {
  size: 'lg',
  className: '',
  style: {},
  disabled: false,
  color: undefined,
};

export default CustomButton;
