/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, Fragment } from 'react';
import {
  Button,
  Card,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
} from 'reactstrap';
import Select from 'react-select';
import { getSelectedOption, selectColorStyles } from '../../../../Utils';
import HeaderComponent from '../../components/common/header-component';
import { dataCenter, settingRoutes, mainRoutes, replacePathParams } from '../../constants/routes';
// import './index.scss';
import BottomActionToolbar from '../../components/common/toolbar';
import SaveAndContinue from '../../components/common/saveAndContinue';
import { DBFieldTypeOptions } from '../../schema';
import { useParams } from 'react-router';
import { databaseFieldUpdate, getDatabaseFieldById } from '../../redux/dataCenter/actions';
import { useDispatch, useSelector } from 'react-redux';
import CheckBox from '../../components/common/checkBox';
import withRouter from '../../helpers/withRouter';

function EditDataField(props) {
  const dispatch = useDispatch();
  const { id, instanceId } = useParams();
  const [params, setParams] = useState({
    fieldName: '',
    shortName: '',
    macro: '',
    selectedFieldType: '',
    valuesArray: [],
    defaultField: false,
    showResultPage: false,
  });
  const { databaseFieldObj } = useSelector((state) => state.adminStore.dataCenterReducer);
  const {
    fieldName,
    shortName,
    macro,
    valuesArray,
    selectedFieldType,
    showResultPage,
  } = params;

  useEffect(() => {
    dispatch(getDatabaseFieldById({ field_id: id, instance_id: instanceId }));
  }, [dispatch, id, instanceId]);

  useEffect(() => {
    if (databaseFieldObj) {
      setParams((prevState) => ({
        ...prevState,
        fieldName: databaseFieldObj.field_name,
        shortName: databaseFieldObj.short_name,
        macro: databaseFieldObj.macro,
        selectedFieldType: databaseFieldObj.field_type,
        showResultPage: databaseFieldObj.show_on_data_search_result_page,
        valuesArray: databaseFieldObj.values,
      }));
    }
  }, [databaseFieldObj]);

  const onFieldTypeChange = (option) => {
    setParams((prevState) => ({
      ...prevState,
      selectedFieldType: option.value,
    }));
  };

  const onHandleChange = (e) => {
    const { name, value } = e.currentTarget;
    setParams((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const onHandleValueChange = (e, index) => {
    const { value } = e.target;
    const updatedArray = [...valuesArray];
    updatedArray[index] = value;
    setParams((prevState) => ({
      ...prevState,
      valuesArray: updatedArray,
    }));
  };

  const removeValues = (e, index) => {
    const updatedArray = [...valuesArray];
    updatedArray.splice(index, 1);
    setParams((prevState) => ({
      ...prevState,
      valuesArray: updatedArray,
    }));
  };

  const valuesListing = (field_type) => {
    let placeholderName = '';
    if (field_type === 'select_list') {
      placeholderName = 'Option name';
    } else if (field_type === 'checkboxes') {
      placeholderName = 'Checkbox label name';
    } else if (field_type === 'radio_button') {
      placeholderName = 'Radio name';
    }

    return valuesArray.map((val, index) => (
      <FormGroup key={index}>
        <li className="dropdown-options-li">
          <Input
            type="text"
            placeholder={`${placeholderName}`}
            value={`${val || ''}`}
            onChange={(e) => onHandleValueChange(e, index)}
          />
          <Button
            align="right"
            color="danger"
            className="custom-simple-icon btn btn-info btn-xs"
            onClick={(e) => removeValues(e, index)}
          >
            <i className="fal fa-minus-circle" />
          </Button>
        </li>
      </FormGroup>
    ));
  };

  const addMoreValues = () => {
    setParams((prevState) => ({
      ...prevState,
      valuesArray: [...prevState.valuesArray, ''],
    }));
  };

  const renderFieldValues = () => {
    if (['select_list', 'radio_button', 'checkboxes'].includes(selectedFieldType)) {
      return (
        <ul>
          {valuesListing(selectedFieldType)}
          <Button
            align="right"
            color="primary"
            className="custom-simple-icon btn btn-info btn-xs"
            onClick={addMoreValues}
          >
            <i className="fal fa-plus-circle" />
          </Button>
        </ul>
      );
    }
    return null;
  };

  const onHandleSubmit = async () => {
    const getArrayData = valuesArray.filter((data) => data.length > 0);
    const sendParams = {
      id,
      instance_id: instanceId,
      field_name: fieldName,
      short_name: shortName,
      macro,
      field_type: selectedFieldType,
      values: getArrayData,
      show_on_data_search_result_page: showResultPage,
    };
    await dispatch(databaseFieldUpdate(sendParams));
  };

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    {
      name: 'Data Centre',
      url: replacePathParams(mainRoutes.dataCenter, [], props),
    },
    {
      name: 'Database Fields',
      url: replacePathParams(dataCenter.databaseFields, [], props),
    },
    { name: 'Edit Database Field', url: '' },
  ];
  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Edit Database Field',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Back',
        backToPath: replacePathParams(dataCenter.databaseFields, [], props),
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <Row>
        <Col lg={9} sm={12}>
          <Card body>
            <Form>
              <FormGroup>
                <Label for="fieldName">Field Name</Label>
                <Input
                  autoFocus
                  type="text"
                  name="fieldName"
                  id="fieldName"
                  value={`${fieldName}`}
                  onChange={onHandleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="shortName">Short Name</Label>
                <Input
                  type="text"
                  name="shortName"
                  id="shortName"
                  value={`${shortName}`}
                  onChange={onHandleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="macro">Macro</Label>
                <Input
                  type="text"
                  name="macro"
                  id="macro"
                  value={macro}
                  onChange={onHandleChange}
                />
              </FormGroup>
              <Label className="form-group has-top-label">
                Field Type:
                <Select
                  className="basic-single"
                  classNamePrefix="select"
                  value={getSelectedOption(DBFieldTypeOptions, selectedFieldType)}
                  options={DBFieldTypeOptions}
                  onChange={onFieldTypeChange}
                  styles={selectColorStyles}
                />
              </Label>
              {renderFieldValues()}
            </Form>
          </Card>
        </Col>
        <Col lg={3} sm={12}>
          <div className="custom-right-sidebar-box">
            <div className="custom-sidebar-bg mb-4">
              <p><b>Options</b></p>
              <CheckBox
                title="Show on Data Centre Search Results Page?"
                checked={showResultPage}
                onChange={(e) => setParams((prevState) => ({
                  ...prevState,
                  showResultPage: e.target.checked,
                }))}
              />
            </div>
          </div>
        </Col>
      </Row>
      <BottomActionToolbar
        component={(
          <SaveAndContinue
            onSave={() => onHandleSubmit()}
            onContinue={replacePathParams(dataCenter.databaseFields, [], props)}
          />
        )}
      />
    </>
  );
}
export default withRouter(EditDataField);
