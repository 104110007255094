import React, { useEffect, useState, useRef } from 'react';
import {
  Card, CardBody, Row, Col, Button, ButtonDropdown, DropdownToggle, DropdownMenu,
} from 'reactstrap';
import HeaderComponent from '../../../components/common/header-component';
import { settingRoutes, mainRoutes, replacePathParams } from '../../../constants/routes';
import LogApi from '../../../api/backend_error_log';
import { DateTime } from 'luxon';
import DateTimeFormat from '../../../components/common/DateTimeFormat';
import UserFeatureComponent from 'dashboard/src/components/common/user-feature-component';
import TableComponent from '../../../components/common/Table';
import Pagination from '../../../components/common/custom-pagination';
import { useParams } from 'react-router-dom';
import { get, uniqueId } from 'lodash-es';
import { capitalizeFirstLetter, displayError } from '../../../../../Utils';
import CheckBox from '../../../components/common/checkBox';
import withRouter from '../../../helpers/withRouter';

function Logs(props) {
  const { navigate } = props;
  const { pageNo } = useParams();
  const [data, setData] = useState([]);
  const [getPagination, setPagination] = useState({});
  const [orderBy, setOrderBy] = useState('desc');
  const [categoryList, setCategoryList] = useState([]);
  const [pageSize, setPageSize] = useState(10);
  const fetchIdRef = useRef(0);
  const [catToggle, setCatToggle] = useState(false);
  const [selectedCat, setCategory] = useState([]);

  const commonPathParams = (path) => replacePathParams(path, [], props);

  const formatData = (d) => {
    d.forEach((df) => {
      df.date = <DateTimeFormat datetime={df.date} localeString={DateTime.DATETIME_FULL} />;
    });
    return d;
  };

  const fetchData = async (page, orderDir, pSize, categories) => {
    // Give this fetch an ID
    fetchIdRef.current += 1;
    const fetchId = fetchIdRef.current;

    const sendParams = {
      page,
      page_size: pSize,
      order_direction: orderDir,
      category: categories,
    };

    const response = await LogApi.backendErrorLogIndex(sendParams);
    const logsData = formatData(response.data.data.backend_error_logs_data);

    // Only update the data if this is the latest fetch
    if (fetchId === fetchIdRef.current) {
      setData(logsData);
      setPagination(response.data.data.pagination);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const response = await LogApi.backendErrorLogCategories();
        if (response) {
          setCategory(response.data.data);
          setCategoryList(response.data.data);
        }
      } catch (error) {
        displayError(`${error?.name}: ${error?.message}`);
      }
    })();
  }, []);

  useEffect(() => {
    fetchData(pageNo, orderBy, pageSize, selectedCat);
  }, [pageNo, orderBy, pageSize, selectedCat]);

  const staticBreadcrumbData = [
    { name: 'Dashboard', url: commonPathParams(settingRoutes.dashboard) },
    { name: 'Settings', url: commonPathParams(mainRoutes.setting) },
    { name: 'System Logs', url: '' },
  ];

  const onCatChange = (e) => {
    const { checked, value } = e.target;
    if (checked) {
      setCategory([...selectedCat, value]);
    } else {
      setCategory(selectedCat.filter((e) => e !== value));
    }
  };

  const dataTableColumns = () => [
    {
      Header: (
        <Button
          onClick={() => {
            setOrderBy(orderBy === 'desc' ? 'asc' : 'desc');
          }}
          className="custom-simple-txt-btn fw-bold"
        >
          <span>
            {'Date/Time '}
            <i className={` ${orderBy === 'desc' ? 'fa fa-chevron-circle-up' : 'fa fa-chevron-circle-down'}`} />
          </span>
        </Button>
      ),
      id: 'date',
      accessor: (d) => get(d, 'date', ''),
      disableSortBy: true,
    },
    {
      Header: (
        <ButtonDropdown toggle={() => setCatToggle(!catToggle)} isOpen={catToggle}>
          <DropdownToggle caret className="custom-simple-txt-btn fw-bold">
            {' Category'}
          </DropdownToggle>
          <DropdownMenu end>
            {categoryList.map((el) => (
              <div key={el} style={{ whiteSpace: 'nowrap' }}>
                <CheckBox
                  value={el}
                  key={uniqueId('log-category-')}
                  className="ms-2 me-2"
                  checked={selectedCat.includes(el)}
                  onChange={(e) => onCatChange(e)}
                  title={capitalizeFirstLetter(el.replace(/_/g, ' '))}
                />
              </div>
            ))}
            <Button
              className="ms-2"
              onClick={() => setCategory([])}
            >
              Clear
            </Button>
          </DropdownMenu>
        </ButtonDropdown>
      ),
      id: 'category',
      accessor: (d) => <span className="text-capitalize">{get(d, 'category', '').replace(/_/g, ' ')}</span>,
      disableSortBy: true,
    },
    {
      Header: 'Instance',
      id: 'instance',
      accessor: (d) => {
        const instanceName = get(d, 'instance_name', '');
        const instanceId = get(d, 'instance_id', '');
        const siteId = get(d, 'site_id', '');

        if (instanceName) {
          if (instanceId && siteId) {
            return <a href={`/dashboards/instance/${instanceId}/site/${siteId}`} target="_blank" rel="noreferrer">{instanceName}</a>;
          }

          return instanceName;
        }

        return instanceId;
      },
      disableSortBy: true,
    },
    {
      Header: 'Site',
      id: 'site',
      accessor: (d) => {
        const domain = get(d, 'domain', '');
        const instanceId = get(d, 'instance_id', '');
        const siteId = get(d, 'site_id', '');
        if (domain && instanceId && siteId) {
          return <a href={`/dashboards/instance/${instanceId}/site/${siteId}`} target="_blank" rel="noreferrer">{domain}</a>;
        }

        return siteId;
      },
      disableSortBy: true,
    },
    {
      Header: 'Text',
      id: 'text',
      accessor: (d) => get(d, 'text', ''),
      disableSortBy: true,
    },
  ];

  const onChangePage = (e) => {
    navigate(
      replacePathParams(
        settingRoutes.logs,
        [
          { key: 'pageNo', value: e },
        ], props
      ),
    );
  };

  const changePageSize = (size) => {
    setPageSize(size);
  };

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'System Logs',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Back',
        backToPath: commonPathParams(settingRoutes.dashboard),
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <Pagination
        type={['logs', 'logs']}
        currentPage={Number(pageNo)}
        totalRecord={getPagination.count}
        perPage={getPagination.per_page}
        totalPages={getPagination.pages}
        records={data}
        onChangePage={onChangePage}
        getPagination={getPagination}
        pageSize={pageSize}
        changePageSize={changePageSize}
      />
      <Card>
        <CardBody>
          <UserFeatureComponent feature="system_logs">
            <Row>
              <Col>
                <TableComponent
                  columns={dataTableColumns()}
                  data={data}
                />
              </Col>
            </Row>
          </UserFeatureComponent>
        </CardBody>
      </Card>
    </>
  );
}

export default withRouter(Logs);
