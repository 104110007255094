import React from 'react';
import CustomButton from 'dashboard/src/components/common/button';
import { Card, CardBody } from 'reactstrap';
import HeaderComponent from 'dashboard/src/components/common/header-component';
import { settingRoutes, replacePathParams, mainRoutes } from 'dashboard/src/constants/routes';
import UserFeaturePage from 'dashboard/src/components/common/user-feature-page';
import withRouter from 'dashboard/src/helpers/withRouter';

function Tools(props) {
  const { navigate } = props;

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    {
      name: 'Settings',
      url: replacePathParams(mainRoutes.setting, [], props),
    },
    { name: 'Tools', url: '' },
  ];

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Tools',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Back',
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <Card>
        <CardBody>
          <UserFeaturePage feature="misc_tools">
            <h2>Tools</h2>
            <table className="table">
              <thead>
                <tr>
                  <th>Tool</th>
                  <th>Run</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Google Visibility</td>
                  <td>
                    <CustomButton
                      size="xs"
                      title="Run"
                      color="success"
                      className="custom-simple-icon"
                      onClick={() => navigate(replacePathParams(settingRoutes.toolGoogleVisibility, [], props))}
                    />
                  </td>
                </tr>
                <tr>
                  <td>Current Visibility Score</td>
                  <td>
                    <CustomButton
                      size="xs"
                      title="Run"
                      color="success"
                      className="custom-simple-icon"
                      onClick={() => navigate(replacePathParams(settingRoutes.toolCurrentVisibilityScore, [], props))}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </UserFeaturePage>
        </CardBody>
      </Card>
    </>
  );
}

export default withRouter(Tools);
