import React, { useMemo, useState } from 'react';
import { isEmpty, cloneDeep } from 'lodash-es';
import CustomSelect from 'dashboard/src/components/common/custom-select';
import { FormGroup, Label } from 'reactstrap';
import { MaterialReactTable } from 'material-react-table';
import HeaderComponent from 'dashboard/src/components/common/header-component';
import { settingRoutes, replacePathParams, mainRoutes } from 'dashboard/src/constants/routes';
import UserFeaturePage from 'dashboard/src/components/common/user-feature-page';
import withRouter from 'dashboard/src/helpers/withRouter';
import { useGetReportConfigQuery, useGetReportDataQuery } from 'dashboard/src/api/apiSlice';
// import './index.scss';

function Reports(props) {
  const { data: reportConfig } = useGetReportConfigQuery();
  const [currentReport, setCurrentReport] = useState('Sites');

  // Table state
  const [columnVisibility, setColumnVisibility] = useState({});
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const currentReportConfig = reportConfig?.find((r) => r.name === currentReport);

  const initialColumnVisibility = {};
  currentReportConfig?.columns?.forEach((column) => initialColumnVisibility[column.accessorKey] = !column.hidden);

  let fullColumnVisibility;
  if (Object.keys(columnVisibility).length === 0) {
    fullColumnVisibility = initialColumnVisibility;
  } else {
    fullColumnVisibility = columnVisibility;
    currentReportConfig?.columns?.forEach((column) => {
      if (typeof fullColumnVisibility[column.accessorKey] === 'undefined') {
        fullColumnVisibility[column.accessorKey] = !column.hidden;
      }
    });
  }

  const { data: reportData } = useGetReportDataQuery({
    currentReport,
    columnVisibility: fullColumnVisibility,
    columnFilters: cloneDeep(columnFilters),
    pagination,
    sorting,
  }, { skip: isEmpty(currentReport) });

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    {
      name: 'Settings',
      url: replacePathParams(mainRoutes.setting, [], props),
    },
    { name: 'Reports', url: '' },
  ];

  const columns = useMemo(
    () => {
      if (!reportConfig || !currentReport || !reportData) {
        return [];
      }

      // The columns array is frozen when it comes out of RTK
      return currentReportConfig.columns.map((c) => {
        const column = { ...c };

        if (column.filterVariant === 'checkbox') {
          column.Cell = ({ renderedCellValue }) => {
            if (renderedCellValue === true) {
              return <span style={{ fontSize: 14, color: 'green' }}>✓</span>;
            } else if (renderedCellValue === false) {
              return <span style={{ fontSize: 14, color: 'red' }}>✗</span>;
            } else {
              return '';
            }
          };
        } else if (column.link) {
          column.Cell = ({ renderedCellValue }) => {
            if (!renderedCellValue) {
              return '';
            }

            return (
              <a href={renderedCellValue?.match(/^https?:\/\//) ? renderedCellValue : `https://${renderedCellValue}`} target="_blank" rel="noreferrer">
                {renderedCellValue}
              </a>
            );
          };
        }

        return column;
      });
    },
    [reportConfig, currentReport, reportData],
  );

  if (!reportConfig) {
    return <></>;
  }

  const sx = {
    fontSize: 12,
    fontFamily: 'Source Sans Pro',
  };

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Reports',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Back',
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <UserFeaturePage feature="reports">
        <div className="Reports">
          <FormGroup style={{ maxWidth: '400px' }}>
            <Label>Report</Label>
            <CustomSelect
              options={reportConfig.map((object) => ({ label: object.name, value: object.name }))}
              className="flex-grow-1"
              onChange={(e) => setCurrentReport(e.value)}
              value={{ label: currentReport, value: currentReport }}
            />
          </FormGroup>

          {reportData && (
            <MaterialReactTable
              columns={columns}
              data={reportData.rows}
              enableGlobalFilter={false}
              initialState={{
                showColumnFilters: true,
                columnVisibility: initialColumnVisibility,
              }}
              manualFiltering
              manualPagination
              manualSorting
              onColumnVisibilityChange={setColumnVisibility}
              onColumnFiltersChange={setColumnFilters}
              onPaginationChange={setPagination}
              onSortingChange={setSorting}
              rowCount={reportData.rowCount}
              state={{
                columnVisibility: fullColumnVisibility,
                columnFilters: cloneDeep(columnFilters),
                pagination,
                sorting,
              }}
              muiTableHeadCellProps={{ sx }}
              muiFormControlProps={{ sx }}
              muiTableBodyCellProps={{ sx }}
              muiTableHeadCellFilterTextFieldProps={{ sx }}
              muiTableContainerProps={{ sx }}
            />
          )}
        </div>
      </UserFeaturePage>
    </>
  );
}

export default withRouter(Reports);
