import React, { useState, useEffect } from 'react';
import HeaderComponent from '../../components/common/header-component';
import {
  Row, Col, Button, Input, Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';
import { DropzoneComponent } from 'react-dropzone-component';
import ReactDOMServer from 'react-dom/server';
import confirm from '../../components/common/confirm';
import { displaySuccess, displayError } from '../../../../Utils';
import CommonApi from '../../api/common';
import FileFolderApi from '../../api/file_folder';
import MediaCenterApi from '../../api/media_center';
import Pagination from '../../components/common/custom-pagination';
import { settingRoutes, replacePathParams, mediaCenterRoutes } from '../../constants/routes';
import { baseURL } from '../../../../api';
import { omit, isEmpty } from 'lodash-es';
import withRouter from '../../helpers/withRouter';
import Listing from './Listing';
// import './index.scss';
import { useNavigate } from 'react-router';
import { addRecentItem } from '../../../../UtilsTS';

const Upload = (props) => {
  const {
    type,
    galleryType,
    currentFolderId,
    setUploadedFileData,
    params: { pageNo, fileFolderId },
  } = props;

  const [width, setWidth] = useState('');
  const [height, setHeight] = useState('');
  const [mediaId, setMediaId] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [isFolder, setIsFolder] = useState(false);
  const [modal, setModal] = useState(false);
  const [mediaData, setMediaData] = useState(null);
  const [pagination, setPagination] = useState(null);
  const [isEditAction, setIsEditAction] = useState(false);
  const [newFolderName, setNewFolderName] = useState('');
  const [uploaderWindow, setUploaderWindow] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeFolderName, setActiveFolderName] = useState('');
  const [parentFolders, setParentFolders] = useState([]);
  const [forSortingFieldName, setForSortingFieldName] = useState('created_at');
  const [forSortingDirection, setForSortingDirection] = useState('desc');
  const [rewriteValue, setRewriteValue] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [dropzone, setDropzone] = useState({});
  const [fileData, setFileData] = useState({});
  const instanceId = props.params.instanceId;

  let globalTimeout;

  const navigate = useNavigate();

  if (instanceId && (!currentFolderId || activeFolderName)) {
    addRecentItem({
      instance_id: instanceId,
      type: currentFolderId ? 'media-center-folder' : 'media-center',
      item_id: currentFolderId,
    });
  }

  useEffect(() => {
    getFolderData();
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);

    return () => {
      clearTimeout(globalTimeout);
      window.removeEventListener('resize', updateWindowDimensions);
    };
  }, []);

  useEffect(() => {
    if (isEmpty(filterValue)) {
      return;
    }

    clearTimeout(globalTimeout);
    globalTimeout = setTimeout(() => {
      onHandleSearch();
    }, 3000);
  }, [filterValue]);

  useEffect(() => {
    if (type === 'media-center') {
      onHandleSearch();
    }
  }, [pageSize]);

  useEffect(() => {
    if(rewriteValue && !isEmpty(fileData)) {
      dropzone.removeFile(fileData);
      dropzone.addFile(fileData);
    }
  }, [rewriteValue, fileData]);

  // componentDidUpdate(prevProps) {
  //   const { currentFolderId: prevFolderId } = prevProps;
  //   const { currentFolderId } = this.props;

  //   if (prevFolderId !== currentFolderId) {
  //     this.getFolderData();
  //   }
  // }

  const getFolderData = () => {
    if (currentFolderId) {
      getMediaData(currentFolderId, pageNo, forSortingFieldName, forSortingDirection, pageSize);
      historyPushOnFolderPage(currentFolderId, pageNo);
    } else if (type === 'media-center') {
      getMediaData(currentFolderId, pageNo, forSortingFieldName, forSortingDirection, pageSize);
      navigate(
        replacePathParams(mediaCenterRoutes.mediaCenterDefault, [
          { key: 'pageNo', value: pageNo },
        ], props),
      );
    }
  };

  const historyPushOnFolderPage = (folderId, pageNo) => {
    navigate(
      replacePathParams(
        mediaCenterRoutes.mediaCenterFolder,
        [
          { key: 'fileFolderId', value: folderId },
          { key: 'pageNo', value: pageNo },
        ], props
      ),
    );
  };

  const getMediaData = async (folderId, activePage, fieldName, direction, size, isSearch, redirectToFirstPage) => {
    let sendParams = {
      file_folder_id: folderId,
      instance_id: instanceId,
      page: activePage,
      filter_value: filterValue,
      page_size: size,
      order_by: fieldName,
      order_direction: direction,
    };

    if (isEmpty(folderId)) {
      sendParams = omit(sendParams, ['file_folder_id']);
    }
    const response = await MediaCenterApi.mediaCenterShow(sendParams);
    if (undefined !== response) {
      const {
        data: {
          folder_name, files, pagination, parent_folders,
        },
      } = response.data;

      setMediaData(files);
      setActiveFolderName(folder_name);
      setParentFolders(parent_folders);
      setPagination(pagination);

      if (isSearch) {
        navigate(replacePathParams(
          !isEmpty(folderId) ? mediaCenterRoutes.mediaCenterFolder : mediaCenterRoutes.mediaCenterDefault,
          [
            { key: 'pageNo', value: 1 },
            { key: 'fileFolderId', value: folderId },
          ], props
        ));
      }

      if (redirectToFirstPage) {
        navigate(
          replacePathParams(mediaCenterRoutes.mediaCenterDefault, [
            { key: 'pageNo', value: 1 },
          ], props),
        );
      }
    }
  };

  const getSortMediaData = (fieldName, sortingDirection) => {
    let fieldNameForSorting;
    if (fieldName === 'name') {
      fieldNameForSorting = 'filename';
    } else if (fieldName === 'date') {
      fieldNameForSorting = 'created_at';
    } else if (fieldName === 'size') {
      fieldNameForSorting = 'length';
    } else {
      fieldNameForSorting = '';
    }
    const directionValue = sortingDirection ? 'desc' : 'asc';
    getMediaData(currentFolderId, 1, fieldNameForSorting, directionValue, pageSize, false, true);
    setCurrentPage(1);
    setForSortingFieldName(fieldNameForSorting);
    setForSortingDirection(directionValue);

    navigate(
      replacePathParams(mediaCenterRoutes.mediaCenterDefault, [
        { key: 'pageNo', value: 1 },
      ], props),
    );
  };

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  const onHandleFolderNameChange = (e) => {
    const { target: { value } } = e;
    setNewFolderName(value);
  };

  const onChangeSearchValue = (e) => {
    const { target: { value } } = e;
    setFilterValue(value);
  };

  const onHandleDeleteMedia = async (id, type) => {
    let result;
    try {
      if (type !== true) {
        const checkParams = { instanceId, objectId: id, objectType: 'WGFile' };
        const checkResponse = await CommonApi.checkBeforeDelete(checkParams);
        if (undefined !== checkResponse) {
          const { data: { data: { total, desc } } } = checkResponse;
          result = await confirm({
            title: <b>Confirm!</b>,
            message: (
              <>
                <b>Total Uses:</b>
                {' '}
                {`${total}`}
                <p>
                  {' '}
                  {desc.map((val) => <li key={val}>{val}</li>)}
                </p>
                <p>Are you sure?</p>
              </>
            ),
            confirmText: 'Yes',
            confirmColor: 'success',
            cancelColor: 'btn btn-danger',
            cancelText: 'No',
          });
        }
      } else {
        result = await confirm({
          title: <b>Confirm!</b>,
          message: 'Are you sure?',
          confirmText: 'Yes',
          confirmColor: 'success',
          cancelColor: 'btn btn-danger',
          cancelText: 'No',
        });
      }
      if (result) {
        let response;
        const sendParams = { id, instance_id: instanceId };
        if (type === true) {
          response = await FileFolderApi.fileFolderDelete(sendParams);
        } else {
          response = await MediaCenterApi.mediaCenterDelete(sendParams);
        }
        if (undefined !== response) {
          getMediaData(currentFolderId, pageNo, forSortingFieldName, forSortingDirection, pageSize);
        }
      }
    } catch (error) {
      displayError(`${error?.name}: ${error?.message}`);
    }
  };

  const toggle = () => {
    setModal(!modal);
  };

  const handleAddFolder = () => {
    setNewFolderName('');
    setIsFolder(true);
    toggle();
  };

  const onHandleCreateFolder = async () => {
    const sendParams = {
      instance_id: instanceId,
      file_folder_id: currentFolderId,
      name: newFolderName,
    };
    const response = await FileFolderApi.fileFolderCreate(sendParams);
    if (undefined !== response) {
      const { data: { messages, data: { id } } } = response;
      setModal(!modal);
      if (currentFolderId) {
        getMediaData(id, 1, forSortingFieldName, forSortingDirection, pageSize);
      }
      historyPushOnFolderPage(id, currentPage);
      displaySuccess(messages);
    }
  };

  const onHandleRename = async () => {
    const tempState = [...mediaData];

    const allowedFiles = /^([a-zA-Z0-9\s_\\.\-:])+.doc|.docx|.pdf|.xls|.xlsx|.png|.jpg|.jpeg|.gif$/;
    const sendParams = {
      id: mediaId,
      instance_id: instanceId,
      name: newFolderName,
    };

    let response;

    if (isFolder) {
      response = await FileFolderApi.fileFolderUpdate(sendParams);
    } else if ((!allowedFiles.test(newFolderName))) {
      response = await MediaCenterApi.mediaCenterRename(sendParams);
    } else {
      displayError('Sorry, you can\'t change extensions');
    }
    if (undefined !== response) {
      const { data, messages } = response.data;
      const index = tempState.findIndex((item) => item.id === data.id);
      tempState[index] = data;
      setModal(!modal);
      setIsFolder(!isFolder);
      setIsEditAction(!isEditAction);
      setMediaId('');
      setMediaData(tempState);
      setNewFolderName('');
      displaySuccess(messages);
    }
  };

  const onHandleEnter = (event) => {
    if (event.keyCode === 13) {
      if (isEditAction) {
        onHandleRename();
      } else {
        onHandleCreateFolder();
      }
    }
  };

  const onHandleSearch = () => {
    getMediaData(currentFolderId, 1, forSortingFieldName, forSortingDirection, pageSize, true);
  };

  const onEditMediaItem = (data) => {
    const { id, filename, is_folder } = data;
    setModal(!modal);
    setNewFolderName(filename);
    setIsEditAction(true);
    setIsFolder(is_folder);
    setMediaId(id);
  };

  const onHandlePageChange = (e) => {
    setCurrentPage(e);
    if (fileFolderId) {
      getMediaData(fileFolderId, e, forSortingFieldName, forSortingDirection, pageSize);
      historyPushOnFolderPage(fileFolderId, e);
    } else {
      getMediaData(null, e, forSortingFieldName, forSortingDirection, pageSize);
      navigate(
        replacePathParams(mediaCenterRoutes.mediaCenterDefault, [
          { key: 'pageNo', value: e },
        ], props),
      );
    }
  };

  const getUpdatedData = (data, pageNo) => {
    getMediaData(data, pageNo, forSortingFieldName, forSortingDirection, pageSize);
  };

  const dragDrop = () => {
    setUploaderWindow(false);
  };

  const dragEnter = (data) => {
    const { isTrusted } = data;
    setUploaderWindow(isTrusted);
  };

  const dragOver = (data) => {
    const { isTrusted } = data;
    setUploaderWindow(isTrusted);
  };

  const onSuccess = (data) => {
    displaySuccess(JSON.parse(data.xhr.response).messages);
    if (setUploadedFileData !== undefined) {
      setUploadedFileData(JSON.parse(data.xhr.response).data);
    }
    const mainDiv = document.getElementsByClassName('filepicker dropzone')[0];
    setTimeout(() => {
      mainDiv.removeChild(data.previewElement);
    }, 10000);
    getMediaData(currentFolderId, pageNo, forSortingFieldName, forSortingDirection, pageSize);
  };

  const onError = async (data) => {
    const mainDiv = document.getElementsByClassName('filepicker dropzone')[0];
    if (data.xhr === undefined) {
      const { previewTemplate: { textContent } } = data;
      displayError(textContent);
      setTimeout(() => {
        mainDiv.removeChild(data.previewElement);
      }, 3000);
    } else {
      const { xhr: { response } } = data;
      const fileName = data.upload.filename;
      if (response.includes('File already exists')) {
        const dialog = await confirm({
          title: (<strong>Confirm!</strong>),
          message: `File: ${fileName} already exists, do you want to overwrite the file?`,
          confirmText: 'Yes',
          confirmColor: 'success',
          cancelColor: 'btn btn-danger',
          cancelText: 'No',
          object: true,
        });
        const result = await dialog.open();
        if (result) {
          setFileData(data);
          setRewriteValue(true);
        } else {
          mainDiv.removeChild(data.previewElement);
        }
      } else if (response.includes('Invalid file format')) {
        displayError('Can not upload this file, Invalid file format.');
        setTimeout(() => {
          mainDiv.removeChild(data.previewElement);
        }, 3000);
      }
    }
  };

  const getMediaCenterBreadCrumb = () => {
    const crumbs = [
      {
        name: 'Dashboard',
        url: replacePathParams(settingRoutes.dashboard, [], props),
      },
    ];

    if (currentFolderId) {
      crumbs.push({
        name: 'Media Centre',
        url: replacePathParams(mediaCenterRoutes.mediaCenterDefault, [
          { key: 'pageNo', value: '1' },
        ], props),
      });

      parentFolderBreadcrumbs(parentFolders).forEach((folderCrumb) => {
        crumbs.push(folderCrumb);
      });

      crumbs.push({
        name: activeFolderName,
        url: '',
      });
    } else {
      crumbs.push({ name: 'Media Centre', url: '' });
    }

    return crumbs;
  };

  const parentFolderBreadcrumbs = (parentFoldersData) => {
    return parentFoldersData.map((folder) => (
      {
        name: folder.folder_name,
        url: replacePathParams(
          mediaCenterRoutes.mediaCenterFolder,
          [
            { key: 'fileFolderId', value: folder.id },
            { key: 'pageNo', value: '1' },
          ], props
        ),
      }
    ));
  };

  const changePageSize = (size) => {
    setPageSize(size);
  };

  const dropzoneComponentConfig = {
    postUrl: `${baseURL}/instances/${instanceId}/files/?file_folder_id=${currentFolderId}&rewrite=${rewriteValue}&context=media`,
  };

  const eventHandlers = {
    init: (d) => setDropzone(d),
    drop: dragDrop,
    dragenter: dragEnter,
    dragover: dragOver,
    success: onSuccess,
    error: onError,
  };

  const djsConfig = {
    dictFileTooBig: 'File is too big, max filesize: 1GB.',
    maxFilesize: 1000,
    thumbnailHeight: 65,
    maxThumbnailFilesize: 11111,
    timeout: 300000,
    previewTemplate: ReactDOMServer.renderToStaticMarkup(
      <div className="dz-preview dz-file-preview mb-3">
        <div className="d-flex flex-row ">
          <div className="p-0 w-30 position-relative">
            <div className="preview-container">
              <img data-dz-thumbnail className="img-thumbnail border-0" alt="" />
              <i className="fal fa-file preview-icon" />
            </div>
          </div>
          <div className="ps-3 pt-2 pe-2 pb-1 w-70 dz-details position-relative">
            <div>
              {' '}
              <span data-dz-name />
              {' '}
            </div>
            <div className="text-primary text-extra-small" data-dz-size />
            <div
              className="progress progress-striped active"
              role="progressbar"
              aria-valuemin="0"
              aria-valuemax="100"
              aria-valuenow="0"
            >
              <div className="progress-bar progress-bar-success dz-upload" data-dz-uploadprogress />
            </div>
            <div className="dz-error-message"><span data-dz-errormessage /></div>
          </div>
        </div>
      </div>,
    ),
    headers: { 'My-Awesome-Header': 'header value' },
  };

  return (
    galleryType ? (
      <DropzoneComponent
        config={dropzoneComponentConfig}
        eventHandlers={eventHandlers}
        djsConfig={djsConfig}
      >
        <div id="dropzone" style={uploaderWindow ? { width, height } : {}} />
      </DropzoneComponent>
    )
      : (
        <>
          <HeaderComponent setPath={{
            headingName: 'Media Centre',
            addNewBtnName: '',
            addNewPath: '',
            backBtnName: 'Dashboard',
            backToPath: settingRoutes.dashboard,
            staticBreadcrumbData: getMediaCenterBreadCrumb(),
          }}
          />
          <Row className="media-upload">
            <DropzoneComponent
              config={dropzoneComponentConfig}
              eventHandlers={eventHandlers}
              djsConfig={djsConfig}
            >
              <div id="dropzone" style={uploaderWindow ? { width, height } : {}} />
            </DropzoneComponent>
          </Row>

          <hr />

          {pagination !== null ? (
            <>
              <Row>
                <Col className="search-sm d-inline-block float-md-left me-1 mb-1 align-top">
                  <Input
                    placeholder="Search by Name"
                    name="search"
                    type="text"
                    id="search"
                    value={filterValue}
                    onChange={onChangeSearchValue}
                  />
                </Col>
                <Col className="text-end">
                  <Button
                    color="info"
                    onClick={() => handleAddFolder()}
                    className="top-right-button me-1 btn btn-primary custom-simple-icon"
                  >
                    <i className="fal fa-folder-plus fa-lg" />
                    <b className="ms-1">  Add folder</b>
                  </Button>
                </Col>
              </Row>
              <Pagination
                type={['record', 'records']}
                sortingField={forSortingFieldName}
                sortingDirection={forSortingDirection}
                currentPage={Number(pageNo)}
                records={mediaData}
                onChangePage={onHandlePageChange}
                getPagination={pagination}
                pageSize={pageSize}
                changePageSize={changePageSize}
              />
            </>
          ) : ''}

          <Listing
            getSortMediaData={getSortMediaData}
            data={mediaData}
            handleDelete={onHandleDeleteMedia}
            onEditMediaItem={onEditMediaItem}
            getUpdatedData={getUpdatedData}
          />
          <Modal isOpen={modal} toggle={toggle} autoFocus={false}>
            <ModalHeader toggle={toggle}>
              {isFolder ? (
                <b>{isEditAction ? 'Edit Folder' : 'New Folder'}</b>
              ) : (
                <b>Edit File</b>
              )}
            </ModalHeader>
            <ModalBody>
              <b>Please enter a name:</b>
              <Input
                autoFocus
                type="text"
                value={newFolderName || ''}
                onChange={onHandleFolderNameChange}
                onKeyDown={onHandleEnter}
              />
            </ModalBody>
            <ModalFooter>
              <Button color="danger" onClick={toggle}><b>Cancel</b></Button>
              {
                isEditAction
                  ? <Button color="success" onClick={onHandleRename}><b>Update</b></Button>
                  : <Button color="success" onClick={onHandleCreateFolder}><b>Create</b></Button>
              }
            </ModalFooter>
          </Modal>
        </>
      )
  );
};

export default withRouter(Upload);
