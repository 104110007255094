import styleOverrideAPI from 'dashboard/src/api/style_override';
import { displaySuccess } from '../../../../../Utils';
import { getTemplateListing } from 'dashboard/src/redux/designOptions/templates/actions';
import {
  SET_STYLE_OVERRIDE,
  CREATE_STYLE_OVERRIDE,
  DELETE_STYLE_OVERRIDE,
  UPDATE_STYLE_OVERRIDE,
  UPDATE_STYLE_OVERRIDE_DATA,
} from '../../actions';

const actionType = (payload, type) => ({
  type,
  payload,
});

export const updateStyleOverrideData = (params) => async (dispatch) => {
  try {
    dispatch(actionType(params, UPDATE_STYLE_OVERRIDE_DATA));
  } catch (err) {
    dispatch(actionType(null, UPDATE_STYLE_OVERRIDE_DATA));
  }
};

export const getStyleOverride = (params, instanceId) => async (dispatch) => {
  try {
    const response = await styleOverrideAPI.styleOverrideGet(params);
    dispatch(getTemplateListing({ ...params, instance_id: instanceId }));
    const { data: { data } } = response;
    dispatch(actionType(data, SET_STYLE_OVERRIDE));
  } catch (err) {
    dispatch(actionType([], SET_STYLE_OVERRIDE));
  }
};

export const createStyleOverride = (params) => async (dispatch) => {
  try {
    const response = await styleOverrideAPI.styleOverrideCreate(params);
    dispatch(actionType(response.data.data, CREATE_STYLE_OVERRIDE));
    displaySuccess(response.data.messages);
  } catch (err) {
    dispatch(actionType(null, CREATE_STYLE_OVERRIDE));
  }
};

export const updateStyleOverride = (params) => async (dispatch) => {
  try {
    const response = await styleOverrideAPI.styleOverrideUpdate(params);
    displaySuccess(response.data.messages);
  } catch (err) {
    dispatch(actionType(null, UPDATE_STYLE_OVERRIDE));
  }
};

export const deleteStyleOverride = (params) => async (dispatch) => {
  try {
    const response = await styleOverrideAPI.styleOverrideDelete(params);
    dispatch(actionType(response.data.data, DELETE_STYLE_OVERRIDE));
    displaySuccess(response.data.messages);
  } catch (err) {
    dispatch(actionType(null, DELETE_STYLE_OVERRIDE));
  }
};
