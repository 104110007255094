import React, { useEffect } from 'react';
import TableComponent from 'dashboard/src/components/common/Table';
import { Card, CardBody, Button, Row } from 'reactstrap';
import HeaderComponent from 'dashboard/src/components/common/header-component';
import { settingRoutes, siteCenter, replacePathParams } from 'dashboard/src/constants/routes';
import confirm from 'dashboard/src/components/common/confirm';
import CustomButton from 'dashboard/src/components/common/button';
import { preview as buttonPreview } from 'dashboard/src/api/button';
import { get, isEmpty } from 'lodash-es';
import { useAddButtonStyleMutation, useDeleteButtonStyleMutation, useGetButtonStylesQuery } from 'dashboard/src/api/apiSlice';
import { displaySuccess } from '../../../../../../Utils';
import BottomActionToolbar from 'dashboard/src/components/common/toolbar';
import { addRecentItem } from '../../../../../../UtilsTS';
import { useInstance, useSite } from '../../../../hooks';
import withRouter from '../../../../helpers/withRouter';

function Buttons(props) {
  const instance = useInstance();
  const site = useSite();
  const siteId = get(site, 'id', null);
  const instanceId = get(instance, 'id', null);

  const [addButtonStyle, { isSuccess: created }] = useAddButtonStyleMutation();
  const [deleteButtonStyle, { isSuccess: deleted }] = useDeleteButtonStyleMutation();

  const { data: buttonStyles } = useGetButtonStylesQuery(siteId, { skip: isEmpty(siteId) });

  useEffect(() => {
    if (deleted || created) {
      displaySuccess(`${created ? 'Created' : 'Deleted'} successfully.`);
    }
  }, [created, deleted]);

  const onCopyAction = (button) => {
    const sendParams = {
      site_id: siteId,
      name: `Copy of ${button.name}`,
      css: button.css,
      hover_css: button.hover_css,
    };
    addButtonStyle(sendParams);
  };

  const onDeleteAction = async (button) => {
    const result = await confirm({
      title: <b>Confirm!</b>,
      message: 'Are you sure?',
      confirmText: 'Yes',
      confirmColor: 'success',
      cancelColor: 'btn btn-danger',
      cancelText: 'No',
    });
    if (result) {
      deleteButtonStyle({ site_id: siteId, id: button.id });
    }
  };

  const onHandleAdd = async () => {
    addButtonStyle({ site_id: siteId, name: 'New Button' });
  };

  const onEditAction = (button) => {
    const { navigate } = props;
    navigate(replacePathParams(
      siteCenter.editButton,
      [
        { key: 'id', value: button.id },
      ], props
    ));
  };

  if (instanceId && siteId) {
    addRecentItem({
      instance_id: instanceId,
      site_id: siteId,
      type: 'buttons',
    });
  }

  const dataTableColumns = [
    {
      Header: 'Name',
      id: 'name',
      accessor: (button) => button.name,
    },
    {
      Header: 'Preview',
      id: 'preview',
      accessor: (button) => buttonPreview(button, get(site, 'colors', [])),
    },
    {
      Header: 'Action',
      id: 'action',
      accessor: (button) => (
        <>
          <Button
            className="custom-simple-icon"
            color="primary"
            size="xs"
            onClick={() => onEditAction(button)}
          >
            <i className="fal fa-pen" />
          </Button>
          &nbsp;
          <Button
            className="custom-simple-icon"
            color="primary"
            size="xs"
            onClick={() => onCopyAction(button)}
          >
            <i className="fal fa-copy" />
          </Button>
          &nbsp;
          <Button
            className="custom-simple-icon"
            color="danger"
            size="xs"
            onClick={() => onDeleteAction(button)}
          >
            <i className="fal fa-trash" />
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Buttons',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Dashboard',
        backToPath: settingRoutes.dashboard,
        showBreadcrumb: false,
        staticBreadcrumbData: [
          {
            name: 'Dashboard',
            url: replacePathParams(settingRoutes.dashboard, [], props),
          },
          {
            name: 'Site Centre',
            url: replacePathParams(siteCenter.pageList, [], props),
          },
          {
            name: 'Design Options',
            url: replacePathParams(siteCenter.designOption, [], props),
          },
          { name: 'Buttons', url: '' },
        ],
      }}
      />
      <Card className="mb-4">
        <CardBody>
          <Row className="BreakPoint align-items-center">
            <TableComponent columns={dataTableColumns} data={buttonStyles || []} />
          </Row>
        </CardBody>
      </Card>
      <BottomActionToolbar
        component={(
          <CustomButton
            color="info"
            className="add-new-button button-lg fw-bold"
            title="ADD BUTTON STYLE"
            icon="fa fa-plus"
            onClick={() => onHandleAdd()}
          />
        )}
      />
    </>
  );
}

export default withRouter(Buttons);
