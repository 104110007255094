import client from '../../../api';

export default {
  templateIndex: (params) => client.get(`/sites/${params.site_id}/templates?instance_id=${params.instance_id}`),
  templateCreate: (params) => client.post(`/sites/${params.site_id}/templates`, params),
  templateDelete: (params) => client.delete(`/sites/${params.site_id}/templates/${params.id}`),
  templateUpdate: (params) => client.put(`/sites/${params.site_id}/templates/${params.id}`, params),
  templateDuplicate: (params) => client.post(`/sites/${params.site_id}/templates/${params.id}/duplicate`),
  templateGet: (params) => client.get(`/sites/${params.site_id}/templates/${params.id}`),
};
