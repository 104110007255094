import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { useTable, useSortBy } from 'react-table';
import { Table } from 'reactstrap';
const TableComponent = ({ data, columns, className }) => {
    const { rows, prepareRow, headerGroups, getTableProps, getTableBodyProps, } = useTable({
        columns,
        data,
    }, useSortBy);
    const assignReactKeyToChildren = (d) => React.Children.toArray(d);
    return (_jsxs(Table, Object.assign({}, getTableProps(), { className: className, children: [_jsx("thead", { children: assignReactKeyToChildren(headerGroups.map((headerGroup) => (_jsx("tr", Object.assign({}, headerGroup.getHeaderGroupProps(), { children: headerGroup.headers.map((column) => (_jsxs("th", Object.assign({}, column.getHeaderProps(column.getSortByToggleProps()), { children: [column.render('Header'), _jsxs("span", { children: [' ', column.isSorted
                                        ? column.isSortedDesc
                                            ? _jsx("i", { className: "fa fa-chevron-circle-down", "aria-hidden": "true" })
                                            : _jsx("i", { className: "fa fa-chevron-circle-up", "aria-hidden": "true" })
                                        : ''] })] })))) }))))) }), _jsx("tbody", Object.assign({}, getTableBodyProps(), { children: assignReactKeyToChildren(rows.map((row) => {
                    prepareRow(row);
                    return (_jsx("tr", Object.assign({}, row.getRowProps(), { children: row.cells.map((cell) => (_jsx("td", Object.assign({}, cell.getCellProps([{ className: cell.column.className }]), { children: cell.render('Cell') })))) })));
                })) }))] })));
};
export default TableComponent;
