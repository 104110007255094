import CtaAPI from '../../api/cta_report';
import {
  SET_CTA_LOGS, DEL_CTA_LOG, SPAM_CTA_LOG, SINGLE_CTA_LOG,
} from '../actions';

export const setCTALogs = (payload, type, logType) => ({
  type,
  payload,
  logType,
});

export const getCTALogs = (params) => async (dispatch) => {
  try {
    const response = await CtaAPI.ctaLogs(params);
    const { data } = response.data;
    dispatch(setCTALogs(data, SET_CTA_LOGS, params.types[0]));
  } catch (err) {
    dispatch(setCTALogs([], SET_CTA_LOGS, params.types[0]));
  }
};

export const getCTALogById = (params) => async (dispatch) => {
  try {
    const response = await CtaAPI.ctaLogGet(params);
    const { data: { data } } = response.data;
    dispatch(setCTALogs(data, SINGLE_CTA_LOG));
  } catch (err) {
    dispatch(setCTALogs({}, SINGLE_CTA_LOG));
  }
};

export const deleteCTALog = (params, logType) => async (dispatch) => {
  try {
    const response = await CtaAPI.ctaLogDelete(params);
    const { data } = response.data;
    dispatch(setCTALogs(data, DEL_CTA_LOG, logType));
  } catch (err) {
    dispatch(setCTALogs([], DEL_CTA_LOG, logType));
  }
};

export const downloadCTALogAttach = (params) => async (dispatch) => {
  try {
    const response = await CtaAPI.ctaLogAttachment(params);
    const { request: { responseURL } } = response;
    window.location.href = responseURL;
  } catch (err) {
    dispatch(setCTALogs([], DEL_CTA_LOG));
  }
};

export const markSpamCTALog = (params, index, logType) => async (dispatch) => {
  try {
    await CtaAPI.ctaLogSpam(params);
    dispatch(setCTALogs(index, SPAM_CTA_LOG, logType));
  } catch (err) {
    dispatch(setCTALogs([], SPAM_CTA_LOG, logType));
  }
};
