/* eslint-disable no-restricted-properties */
import React, { useState } from 'react';
import {
  Card, CardBody, Button, Modal, ModalHeader, ModalBody, ModalFooter, Col,
} from 'reactstrap';
import { DateTime } from 'luxon';
import DateTimeFormat from '../../components/common/DateTimeFormat';
import { mediaCenterRoutes, replacePathParams } from '../../constants/routes';
import TableComponent from '../../components/common/Table';
// import './index.scss';
import withRouter from '../../helpers/withRouter';
import { capitalizeFirstLetter, formatBytes } from '../../../../Utils';
import { useNavigate } from 'react-router';

const fileMimeType = (mimeType) => mimeType.split('/').pop();

const fileDimension = (item) => {
  if (item.mime_type.split('/')[0] === 'image') {
    return (
      <li>
        <b>Dimensions:</b>
        {` ${item.width}x${item.height}`}
      </li>
    );
  }
  return <li />;
};

const downloadFile = (data) => {
  const link = document.createElement('a');
  link.href = data.download_url;
  link.download = data.filename;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

const infoType = (item) => {
  if (item.is_folder) {
    return (
      <ul className="custom-ul">
        <li>
          <b>Type: </b>
          Folder
        </li>
        <li>
          <b>No. of Items:</b>
          {` ${item.info}`}
        </li>
      </ul>
    );
  }
  return (
    <ul className="custom-ul">
      <li>
        <b>Type: </b>
        {fileMimeType(item.mime_type)}
      </li>
      {fileDimension(item)}
    </ul>
  );
};

const showDownloadButton = (data) => {
  if (data.is_folder) {
    return <></>;
  }
  return (
    <Button
      className="custom-simple-icon"
      color="dark"
      size="xs"
      onClick={() => downloadFile(data)}
    >
      <i className="fal fa-cloud-download-alt text-white" />
    </Button>
  );
};

function Listing(props) {
  const [imagePath, setImagePath] = useState('');
  const [modal, setModal] = useState(false);
  const [isSortedDesc, setSortedDesc] = useState(false);
  const [activeFilter, setActiveFilter] = useState('');
  const {
    data,
    path,
    handleDelete,
    getUpdatedData,
    params: { pageNo },
    onEditMediaItem,
    getSortMediaData,
  } = props;

  const navigate = useNavigate();

  const getFileIcon = (d) => {
    let iconData;
    if (d.is_folder) {
      iconData = <Col><i className="custom-font-s30p fal fa-folder-open" /></Col>;
    } else if (d.mime_type.includes('image')) {
      iconData = (
        <Col onClick={() => toggleModal(d.full_size_url)}>
          <img src={d.full_size_url} alt="" width={100} />
        </Col>
      );
    } else {
      iconData = <Col><i className="custom-font-s30p fal fa-file" /></Col>;
    }
    return iconData;
  };

  const onHandleDeleteMedia = (e) => {
    const { id, is_folder } = e;
    handleDelete(id, is_folder);
  };

  const onHandleFolderClick = (id) => {
    if (path && path.includes('folder')) {
      getUpdatedData(id, pageNo);
    }
    navigate(
      replacePathParams(
        mediaCenterRoutes.mediaCenterFolder,
        [
          { key: 'fileFolderId', value: id },
          { key: 'pageNo', value: pageNo },
        ], props
      ),
    );
  };

  const handleRename = (data) => {
    const { id, is_folder, filename } = data;
    onEditMediaItem({ id, is_folder, filename });
  };

  const toggleModal = (url) => {
    setImagePath(url);
    setModal(!modal);
  };

  const toggle = () => {
    setModal(!modal);
  };

  const onHandleSorting = (orderBy) => {
    setSortedDesc(!isSortedDesc);
    setActiveFilter(orderBy);
    getSortMediaData(orderBy, !isSortedDesc);
  };

  const sortingButton = (name) => (
    <Button
      className="custom-simple-txt-btn fw-bold d-flex align-items-center"
      onClick={() => onHandleSorting(name)}
    >
      {capitalizeFirstLetter(name)}
      {activeFilter === name && (
        <i className={`ms-2 fa fa-chevron-circle-${isSortedDesc ? 'down' : 'up'}`} aria-hidden="true" />
      )}
    </Button>
  );

  const dataTableColumns = () => (
    [
      {
        Header: 'Thumb',
        id: 'thumb',
        disableSortBy: true,
        accessor: (d) => getFileIcon(d),
      },
      {
        Header: sortingButton('name'),
        id: 'name',
        accessor: (d) => (d.is_folder
          ? (
            <Button
              className="custom-simple-txt-btn custom-text-color-blue"
              onClick={() => onHandleFolderClick(d.id)}
            >
              {d.filename}
            </Button>
          ) : d.filename),
      },
      {
        Header: 'Info',
        id: 'info',
        disableSortBy: true,
        accessor: (d) => infoType(d),
      },
      {
        Header: sortingButton('date'),
        id: 'date',
        accessor: (d) => <DateTimeFormat datetime={d.date} localeString={DateTime.DATE_SHORT} />,
      },
      {
        Header: sortingButton('size'),
        id: 'size',
        accessor: (d) => formatBytes(d.length),
      },
      {
        Header: 'Action',
        id: 'action',
        disableSortBy: true,
        accessor: (d) => (
          <>
            <Button className="custom-simple-icon" color="primary" size="xs" onClick={() => handleRename(d)}>
              <i className="fal fa-pen" />
            </Button>
            {' '}
            <Button className="custom-simple-icon" color="danger" size="xs" onClick={() => onHandleDeleteMedia(d)}>
              <i className="fal fa-trash" />
            </Button>
            {' '}
            {showDownloadButton(d)}
          </>
        ),
      },
    ]
  );

  return (
    <>
      <div className="media-listing-outer">
        {data !== null
          ? (
            <Card className="mb-4">
              <CardBody>
                <TableComponent
                  columns={dataTableColumns()}
                  data={data}
                />
              </CardBody>
            </Card>
          )
          : null}
      </div>
      <Modal isOpen={modal} toggle={toggle} autoFocus={false} size="lg">
        <ModalHeader toggle={toggle}>
          <b>Full Size Image View</b>
        </ModalHeader>
        <ModalBody>
          <Col className="img-fullsize-preview">
            <img src={imagePath} alt="" />
          </Col>
        </ModalBody>
        <ModalFooter />
      </Modal>
    </>
  );
}
export default withRouter(Listing);
