import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
} from 'reactstrap';
import UserAPI from '../../../api/user';
import { displaySuccess, displayError } from '../../../../../Utils';
import { get } from 'lodash-es';
import withRouter from '../../../helpers/withRouter';

function SetGmailSignatureModal(props) {
  const {
    user,
    isOpen,
    toggle,
    params: { instanceId },
  } = props;

  const [signatureData, setSignatureData] = useState({});

  useEffect(() => {
    if (user) {
      setSignatureData({ ...user, contact_details_name: 'Mobile' });
    }
  }, [user]);

  const submit = async () => {
    const response = await UserAPI.setGmailSignature(signatureData);
    const { status, data: { messages } } = response;
    if (status === 200) {
      displaySuccess(messages);
    } else {
      displayError(messages);
    }
  };

  let previewUrl = `/api/v1/users/${signatureData.id}/gmail_signature_preview?`;
  Object.keys(signatureData).forEach((k) => { previewUrl += `${k}=${encodeURI(signatureData[k])}&`; });
  previewUrl += `instance_id=${instanceId}`;

  return (
    <Modal isOpen={isOpen} toggle={toggle} autoFocus={false} size="xl">
      <ModalHeader toggle={toggle}>
        <b>
          Set Gmail Signature for
          {' '}
          {user.email}
        </b>
      </ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            <Col>
              <FormGroup>
                <Label>Full Name</Label>
                <Input
                  type="text"
                  value={get(signatureData, 'full_name', '')}
                  onChange={(e) => setSignatureData({ ...signatureData, full_name: e.target.value })}
                />
              </FormGroup>
              <FormGroup>
                <Label>Job Title</Label>
                <Input
                  type="text"
                  value={get(signatureData, 'job_title', '')}
                  onChange={(e) => setSignatureData({ ...signatureData, job_title: e.target.value })}
                />
              </FormGroup>
              <FormGroup>
                <Label>
                  Contact Details Name
                  {' '}
                  <small>
                    (eg:
                    {' '}
                    <b>DDI</b>
                    ,
                    {' '}
                    <b>Mobile</b>
                    )
                  </small>
                </Label>
                <Input
                  type="text"
                  value={get(signatureData, 'contact_details_name', '')}
                  onChange={(e) => setSignatureData({ ...signatureData, contact_details_name: e.target.value })}
                />
              </FormGroup>
              <FormGroup>
                <Label>Contact Details</Label>
                <Input
                  type="text"
                  value={get(signatureData, 'contact_details', '')}
                  onChange={(e) => setSignatureData({ ...signatureData, contact_details: e.target.value })}
                />
              </FormGroup>
            </Col>
            <Col>
              <h2>Preview</h2>
              <hr />
              <iframe title="Gmail Signature Preview" src={previewUrl} style={{ width: '100%', height: '700px' }} />
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button color="danger" onClick={toggle}>
          Cancel
        </Button>
        <Button color="success" onClick={submit}>
          Set Signature
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default withRouter(SetGmailSignatureModal);
