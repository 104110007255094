/* eslint-disable no-param-reassign */
const isValidHex = (hex) => /^#([A-Fa-f0-9]{3,4}){1,2}$/.test(hex);

const getChunksFromString = (st, chunkSize) => st.match(new RegExp(`.{${chunkSize}}`, 'g'));

const convertHexUnitTo256 = (hexStr) => (hexStr.length > 0 ? parseInt(hexStr.repeat(2 / hexStr.length), 16) : 0);

const getAlphafloat = (a, alpha) => {
  if (typeof a !== 'undefined') {
    return a / 255;
  }
  if ((typeof alpha !== 'number') || alpha < 0 || alpha > 1) {
    return 1;
  }
  return alpha;
};

const hexToRGBA = (hex, alpha) => {
  if (!isValidHex(hex)) {
    hex = '#ffffff';
  }
  const chunkSize = Math.floor((hex.length - 1) / 3);
  const hexArr = getChunksFromString(hex.slice(1), chunkSize);
  const [r, g, b, a] = hexArr.map(convertHexUnitTo256);
  const getAlpha = getAlphafloat(a, alpha);
  return {
    r, g, b, a: getAlpha,
  };
};

export default hexToRGBA;
