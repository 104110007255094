import React, { useEffect, useState } from 'react';
import {
  Row, Card, CardTitle, Label, Button, Form, FormGroup, Input
} from 'reactstrap';
import zxcvbn from 'zxcvbn';
import { get } from 'lodash-es';
import { Colxx } from '../../components/common/CustomBootstrap';
import { NavLink } from 'react-router-dom';
import User from '../../api/user';
import { setCookie } from '../../../../Utils';
import IntlMessages from '../../helpers/IntlMessages';
import './index.scss';
import withRouter from '../../helpers/withRouter';
import { createPasswordLabel, validatePasswords } from '../profile/UserProfile';

function NewPassword(props) {
  const [isVerify, setIsVerify] = useState(null);
  const [data, setData] = useState({
    resetToken: null,
    password: null,
    password_confirmation: null,
  });

  const [testedResult, setTestedResult] = useState({});

  useEffect(() => {
    // code to run on component mount
    const checkForToken = async () => {
      const { params: { token } } = props;
      const response = await User.verifyResetToken(token);
      const { data: responseData } = response.data;
      const { verify } = responseData;
      if (verify) {
        setIsVerify(true);
        setData({ resetToken: token });
      } else {
        setIsVerify(false);
      }
    };
    checkForToken();
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();

    if (validatePasswords({ ...data, ...testedResult })) {
      const response = await User.setNewPassword(data);
      const { data: responseData } = response.data;
      setCookie('USER_TOKEN', responseData.token, 9999, '/');
      window.location = '/';
    }
  };

  const onChange = (e) => {
    const { target: { value, name } } = e;
    if (name === 'password') {
      const result = zxcvbn(value);
      setTestedResult(result);
    }
    setData({ ...data, [name]: value });
  };

  const password = get(data, 'password', '');
  const passwordConfirmation = get(data, 'password_confirmation', '');

  const renderNewPasswordForm = () => (
    <Row className="h-100 ChangePassword">
      <Colxx xxs="12" md="10" className="mx-auto my-auto">
        <Card className="auth-card">
          <div className="position-relative image-side" />
          <div className="form-side">
            <Form onSubmit={onSubmit}>
            <NavLink to="/" className="white">
                <span className="logo-single" />
              </NavLink>
              <CardTitle className="mb-4">
                <IntlMessages id="user.set-password" />
              </CardTitle>
              <FormGroup>
                <Label className="form-group has-float-label mb-0">
                  <Input
                    className="form-control"
                    name="password"
                    type="password"
                    value={password}
                    onChange={onChange}
                    autoFocus
                  />
                  <IntlMessages id="user.new-password" />
                </Label>
                {password && (
                  <div className="password-strength-meter">
                    <progress
                      className={
                        `password-strength-meter-progress strength-${createPasswordLabel(testedResult)}`
                      }
                      value={testedResult.score || 1}
                      max="4"
                    />
                    {password && (
                      <span
                        className={`password-strength-meter-label strength-${createPasswordLabel(testedResult)}`}
                      >
                        {createPasswordLabel(testedResult)}
                      </span>
                    )}
                  </div>
                )}
              </FormGroup>
              <FormGroup>
                <Label className="form-group has-float-label mb-4">
                  <Input
                    className="form-control"
                    name="password_confirmation"
                    type="password"
                    value={passwordConfirmation}
                    onChange={onChange}
                  />
                  <IntlMessages id="user.confirm-new-password" />
                </Label>
              </FormGroup>
              <Button
                color="primary"
                className="btn-shadow"
                size="lg"
                type="submit"
                onClick={onSubmit}
              >
                <IntlMessages id="user.set-password" />
              </Button>
            </Form>
          </div>
        </Card>
      </Colxx>
    </Row>

  );
  if (isVerify) {
    return renderNewPasswordForm();
  } if (isVerify === false) {
    return (
      <Row className="h-100">
        <Colxx xxs="12" md="10" className="mx-auto my-auto">
          <Card className="auth-card">
            <h2> Invalid token for reset password</h2>
          </Card>
        </Colxx>
      </Row>
    );
  }

  return (
    <Row className="h-100">
      <Colxx xxs="12" md="10" className="mx-auto my-auto">
        <Card className="auth-card">
          Loading
        </Card>
      </Colxx>
    </Row>
  );
}

export default withRouter(NewPassword);
