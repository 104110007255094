import React from 'react';
import { Button } from 'reactstrap';
import PropTypes from 'prop-types';
import './index.scss';
import withRouter from '../../../helpers/withRouter';
import { displayError } from '../../../../../Utils';
import { useNavigate } from 'react-router';
function SaveAndContinue({
  onSave, onContinue, btnName, addNew, className,
}) {
  const navigate = useNavigate();

  return (
    <div className={`SaveAndContinue ${className}`}>
      <Button
        color="success"
        onClick={onSave}
        type="submit"
        className={`
        ${!addNew ? 'save-btn' : ''}
        button-sm
        fw-bold
        btn
        btn-success
        btn-lg
        common-success-button
      `}
      >
        {btnName}
      </Button>
      {!addNew
      && (
        <Button
          color="success"
          onClick={async () => {
            const result = await onSave();
            if (result !== false) {
              if (typeof onContinue === 'function') {
                onContinue();
              } else if (typeof onContinue === 'string') {
                navigate(onContinue);
              } else {
                displayError(`Invalid onContinue type: ${typeof onContinue}`);
                console.log(onContinue);
              }
            }
          }}
          type="submit"
          className="continue-btn button-xs fw-bold btn btn-success btn-lg common-success-button"
        >
          <i className="fal fa-arrow-right fw-bold" aria-hidden="true" />
        </Button>
      )}
    </div>
  );
}

SaveAndContinue.propTypes = {
  btnName: PropTypes.string,
  className: PropTypes.string,
};

SaveAndContinue.defaultProps = {
  btnName: 'SAVE',
  className: '',
};

export default withRouter(SaveAndContinue);
