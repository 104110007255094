import React from 'react';
import { Label, FormGroup,UncontrolledTooltip } from 'reactstrap';
import MediaSelectBox from './MediaSelectBox';

function MicrodataLogo(props) {
  const { params, setParams } = props;

  return (
    <FormGroup>
      <Label className="has-tooltip" id="microdataLogoHelp">Business Logo</Label>
      <UncontrolledTooltip placement="right" target="microdataLogoHelp" autohide={false} style={{ textAlign: 'left' }}>
        Used in
        {' '}
        <a
          href="https://developers.google.com/search/docs/advanced/structured-data/intro-structured-data"
          target="_blank"
          rel="noreferrer"
        >
          page microdata
        </a>
        .
        <br />
        <br />
        <a
          href="https://developers.google.com/search/docs/advanced/structured-data/logo"
          target="_blank"
          rel="noreferrer"
        >
          More information
        </a>
      </UncontrolledTooltip>
      <MediaSelectBox
        value={params?.microdata_logo_id}
        onchange={(e) => setParams({ ...params, microdata_logo_id: e ? e.value : null })}
      />
    </FormGroup>
  );
}

export default MicrodataLogo;
