import client from '../../../api';

export default {
  pageUrlGet: (pageId) => client.get(`/urls?resource_id=${pageId}&resource_type=Page`),
  pageUrlCreate: (params) => client.post('/urls', params),
  pageUrlSetPrimaryUrl: (params) => client.put('/urls/set_primary_url', params),
  pageUrlUpdatePrefix: (params) => client.put('/urls/update_prefix', params),
  pageUrlUpdate: (params) => client.put(`/urls/${params.id}`, params),
  pageUrlDelete: (params) => client.delete(`/urls/${params}`),
};
