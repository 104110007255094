/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import React from 'react';
import { useTable, useSortBy } from 'react-table';
import { Table } from 'reactstrap';
import { useUser } from '../../hooks';

function CTATable({
  columns, data, subRow,
}) {
  const {
    rows,
    prepareRow,
    headerGroups,
    getTableProps,
    visibleColumns,
    getTableBodyProps,
  } = useTable(
    {
      columns,
      data,
    },
    useSortBy,
  );

  const user = useUser();

  const assignReactKeyToChildren = (d) => React.Children.toArray(d);

  const getColumns = (d) => (!user?.hasFeature('view_spam_ctas') ? d.filter((el) => el.id !== 'Spam' && el.id !== 'Actions') : d);

  const getRowCells = (cells) => (
    !user?.hasFeature('view_spam_ctas') ? cells.filter((el) => el.column.id !== 'Spam' && el.column.id !== 'Actions') : cells
  );

  return (
    <Table {...getTableProps()}>
      <thead>
        {assignReactKeyToChildren(
          headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {getColumns(headerGroup.headers).map((column) => (
                <th {...column.getHeaderProps(column.getSortByToggleProps())} className={column.className}>
                  {column.render('Header')}
                  <span>
                    {' '}
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <i className="fa fa-chevron-circle-down" aria-hidden="true" />
                        : <i className="fa fa-chevron-circle-up" aria-hidden="true" />
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          )),
        )}
      </thead>
      <tbody {...getTableBodyProps()}>
        {assignReactKeyToChildren(
          rows.map((row) => {
            prepareRow(row);

            const subRowEl = typeof subRow === 'function' ? subRow(visibleColumns, row) : <></>;

            return (
              <>
                <tr {...row.getRowProps()} className="align-middle"
                  style={{ borderWidth: '1px 0 0 0', borderColor: '#f3f3f3', borderStyle: 'solid' }}>
                  {getRowCells(row.cells).map((cell) => {
                    let rowSpan;
                    if (typeof cell.column.rowSpan === 'function') {
                      rowSpan = cell.column.rowSpan(cell);
                    } else if (cell.column.rowSpan) {
                      rowSpan = cell.column.rowSpan;
                    } else {
                      rowSpan = 1;
                    }

                    return (
                      <td {...cell.getCellProps()}
                        className={cell.column.className}
                        rowSpan={rowSpan}
                        style={{ border: 'none' }}>
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
                {subRowEl}
              </>
            );
          }),
        )}
      </tbody>
    </Table>
  );
}

export default CTATable;
