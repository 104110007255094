import React, { useState } from 'react';
import PropsTypes from 'prop-types';
import {
  DropdownItem, DropdownMenu, ButtonDropdown, DropdownToggle, Badge,
} from 'reactstrap';
import confirm from '../../../components/common/confirm';
import { displayError } from '../../../../../Utils';
import { useSelector } from 'react-redux';
import withRouter from '../../../helpers/withRouter';
import { useAddNewPageMutation, useDeletePageMutation, useGetSitesQuery } from '../../../api/apiSlice';
import { isEmpty } from 'lodash-es';

function PageOption(props) {
  const {
    instanceId,
    onSetModal,
    page: { node },
  } = props;

  const [isOpen, setIsOpen] = useState(false);

  const { site } = useSelector((state) => state.adminStore.sitesReducer);

  const isTreePage = node.page_type === 'normal' && (node.parent_id || node.id === site.home_page_id);

  const [addNewPage] = useAddNewPageMutation();
  const [deletePage] = useDeletePageMutation();
  const { data: sites } = useGetSitesQuery(instanceId, { skip: isEmpty(instanceId) });

  const sendParams = {
    type: node?.page_type,
    site_id: site?.id,
    instance_id: instanceId,
  };

  const toggleDropDown = () => {
    setIsOpen(!isOpen);
  };

  const addNewNode = async () => {
    await addNewPage({ ...sendParams, parent_id: node?.id });
  };

  const removeNodeHandler = async () => {
    try {
      const result = await confirm({
        title: (<strong>Confirm!</strong>),
        message: 'Are you sure?',
        confirmText: 'Yes',
        confirmColor: 'success',
        cancelColor: 'btn btn-danger',
        cancelText: 'No',
      });
      if (result) {
        await deletePage({ ...sendParams, current_page: node.id, parent_id: isTreePage ? node?.id : null });
      }
    } catch (error) {
      displayError('Error while adding new page');
    }
  };

  const duplicatePage = (page) => {
    onSetModal(page);
  };

  const getSite = sites && sites.find((s) => s.id === site?.id);

  if (!getSite) {
    return <></>;
  }

  return (
    <>
      {node.is_offline && (
        <Badge color="danger" className="me-1">Offline</Badge>
      )}
      {' '}
      {node.page_version.id !== node.published_version_id && (
        <Badge color="warning" className="me-1">Draft</Badge>
      )}
      <ButtonDropdown
        className="ms-1 mb-1 mt-1"
        isOpen={isOpen}
        toggle={toggleDropDown}
      >
        <DropdownToggle caret size="xs" color="info">
          <i className="fal fa-cog" />
        </DropdownToggle>
        <DropdownMenu>
          {isTreePage && (
            <DropdownItem onClick={addNewNode}>
              Add
            </DropdownItem>
          )}
          <DropdownItem onClick={() => duplicatePage(node)}>
            Duplicate
          </DropdownItem>
          {node.id !== site.home_page_id && (
            <DropdownItem onClick={removeNodeHandler}>
              Delete
            </DropdownItem>
          )}
        </DropdownMenu>
      </ButtonDropdown>
    </>
  );
}

PageOption.propTypes = {
  page: PropsTypes.object.isRequired,
  onSetModal: PropsTypes.func.isRequired,
};

export default withRouter(PageOption);
