/* eslint-disable react/no-array-index-key */
import React, { Fragment, useState, useEffect } from 'react';
import { Input, Row, Col, Alert } from 'reactstrap';
import FragmentApi from '../../../../../api/template_fragment';
import { displaySuccess, displayError, setPageTitle } from '../../../../../../../Utils';
import HeaderComponent from '../../../../../components/common/header-component';
import { settingRoutes, replacePathParams, siteCenter } from '../../../../../constants/routes';
import SaveAndContinue from '../../../../../components/common/saveAndContinue';
import BottomActionToolbar from '../../../../../components/common/toolbar';
import withRouter from '../../../../../helpers/withRouter';
import FragmentCodeEditor from '../code-editor';
import { addRecentItem } from '../../../../../../../UtilsTS';

function TemplateFragmentRoot(props) {
  const { params: { siteId, id, instanceId }, currentComponent } = props;
  /* Handle errors with state */
  const [errorVal, setError] = useState('');

  /* Set editor errors */
  const [getEditorError, setEditorError] = useState(null);

  /* Initializing states */
  const [params, setParams] = useState({
    id: null,
    name: '',
    html: '',
    site_id: siteId,
    instance_id: instanceId,
  });
  const paramsTemp = { ...params };

  useEffect(() => {
    if (currentComponent === 'update') {
      const fetchingFragmentData = async () => {
        try {
          const sendParams = {
            id,
            site_id: siteId,
            instance_id: instanceId,
          };
          const response = await FragmentApi.fragmentGet(sendParams);
          if (undefined !== response) {
            const { data } = await response.data;
            if (data) {
              paramsTemp.id = data.id;
              paramsTemp.name = data.name;
              paramsTemp.html = data.html;
              setParams(paramsTemp);
            }
          }
        } catch (error) {
          displayError(`${error?.name}: ${error?.message}`);
        }
      };
      fetchingFragmentData();
      setPageTitle('Update fragment');
    } else {
      setPageTitle('Create fragment');
    }
  }, [instanceId, siteId, id, currentComponent]);

  /* On keypress it accepts only [a-b,A-Z] */
  const onHandleKeyPress = (event) => {
    const inputValue = event.which;
    if (
      (inputValue >= 65 && inputValue <= 90) || (inputValue === 45)
      || (inputValue >= 48 && inputValue <= 57) || (inputValue === 95)
      || (inputValue >= 97 && inputValue <= 122)) {
      return true;
    }
    return event.preventDefault();
  };

  /* On file name change */
  const onFileNameChange = (e) => {
    paramsTemp.name = e.target.value;
    setParams(paramsTemp);
    setError('');
  };

  /* Getting editor data from CodeEditor component */
  const getEditorData = (data) => {
    paramsTemp.html = `${data}`; setParams(paramsTemp);
  };

  /* Validating editor errors */
  const validateEditorError = (error) => {
    const filterError = error.filter((item) => item.type !== 'info');
    if (filterError.length >= 1) {
      setEditorError(filterError);
    } else {
      setError('');
      setEditorError(null);
    }
  };

  /* On creating OR editing template */
  const onHandleSubmit = async () => {
    try {
      if (!params.name) {
        setError(<span>File name should not be blank!</span>);
      } else if (!params.html) {
        setError(<span>Code editor should not be blank!</span>);
      } else if (getEditorError !== null) {
        const getErrors = getEditorError.map(
          (item, idx) => <span key={idx}>{`Line ${item.row + 1} Column  ${item.column}, ${item.text}`}</span>,
        );
        setError(getErrors);
      } else {
        setError('');
        let response;
        if (currentComponent === 'create') {
          response = await FragmentApi.fragmentCreate(params);
        } else {
          response = await FragmentApi.fragmentUpdate(params);
        }
        const { data } = response;
        displaySuccess(`${data.messages}`);
      }
    } catch (errors) {
      displayError(errors);
    }
  };

  /* Set heading according to props value */
  const setHeading = currentComponent === 'update' ? 'Edit Fragment' : 'Add New Fragment';

  addRecentItem({
    instance_id: instanceId,
    type: id ? 'edit-fragment' : 'create-fragment',
    item_id: id,
  });

  const staticBreadcrumbData = [
    { name: 'Dashboard', url: replacePathParams(settingRoutes.dashboard, [], props)},
    {
      name: 'Site Centre',
      url: replacePathParams(siteCenter.pageList, [], props),
    },
    {
      name: 'Design Options',
      url: replacePathParams(siteCenter.designOption, [], props),
    },
    {
      name: 'Template Fragments',
      url: replacePathParams(siteCenter.templateFragmentList, [], props),
    },
    { name: `${params.name || ''}`, url: '' },
  ];
  return (
    <>
      <HeaderComponent
        setPath={{
          addNewPath: '',
          addNewBtnName: '',
          backBtnName: 'Back',
          staticBreadcrumbData,
          showBreadcrumb: false,
          headingName: `${setHeading}`,

        }}
      />
      {errorVal ? <Alert color="danger" className="custom-alert-span">{errorVal}</Alert> : ''}
      <Row>
        <Col className="col-sm-10">
          <Input
            autoFocus
            placeholder="Enter title here"
            value={params.name || ''}
            onKeyPress={onHandleKeyPress}
            onChange={onFileNameChange}
            // onBlur={onBlurAddPrefix}
          />
        </Col>
        <Col className="col-sm-2 text-end">
          <SaveAndContinue
            onSave={onHandleSubmit}
            onContinue={replacePathParams(siteCenter.templateFragmentList, [], props)}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col className="col-sm-12">
          <FragmentCodeEditor
            editorData={getEditorData}
            validateEditor={validateEditorError}
            defaultComponentValue={params.html}
          />
        </Col>
      </Row>
      <BottomActionToolbar
        component={(
          <SaveAndContinue
            onSave={onHandleSubmit}
            onContinue={replacePathParams(siteCenter.templateFragmentList, [], props)}
          />
        )}
      />
    </>
  );
}
export default withRouter(TemplateFragmentRoot);
