import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { get } from 'lodash-es';
import { DateTime } from 'luxon';
import { parsePhoneNumber } from 'react-phone-number-input';
import DateTimeFormat from '../../components/common/DateTimeFormat';
import { secondsToHms, getFlagEmoji } from '../../../../Utils';
import CtaReport from './index';

function CtaLogIncomingCall() {
  const [orderBy, setOrderBy] = useState('created_at');
  const [orderDirection, setOrderDirection] = useState('desc');

  const formatPhoneNumber = (num) => {
    if (num === 'unidentifiable') {
      return num;
    }
    const phoneNumber = parsePhoneNumber(`+${num}`);
    const formattedNumber = phoneNumber[phoneNumber.country === 'NZ' ? 'formatNational' : 'formatInternational']();
    let flag = '';
    if (phoneNumber.country && phoneNumber.country !== 'NZ') {
      flag = <span className="fs-2 me-2">{getFlagEmoji(phoneNumber.country)}</span>;
    }

    return (
      <div className="d-flex align-items-center" style={{ height: '22px' }}>
        {flag}
        {formattedNumber}
      </div>
    );
  };

  const dataTableColumns = [
    {
      Header: (
        <Button
          onClick={() => {
            setOrderBy('created_at');
            setOrderDirection(orderDirection === 'desc' ? 'asc' : 'desc');
          }}
          className="custom-simple-txt-btn fw-bold"
        >
          <span>
            {'Date '}
            {orderBy === 'created_at' ? (
              <i
                className={` ${orderDirection === 'desc' ? 'fa fa-chevron-circle-up' : 'fa fa-chevron-circle-down'}`}
              />
            ) : ''}
          </span>
        </Button>
      ),
      id: 'Date',
      className: 'text-nowrap',
      disableSortBy: true,
      accessor: (d) => (
        <DateTimeFormat datetime={get(d, 'attributes.created_at', '')} localeString={DateTime.DATETIME_SHORT} />
      ),
      isVisible: true,
    },
    {
      Header: (
        <Button
          onClick={() => {
            setOrderBy('recipient');
            setOrderDirection(orderDirection === 'desc' ? 'asc' : 'desc');
          }}
          className="custom-simple-txt-btn fw-bold"
        >
          <span>
            {'Tracking Number '}
            {orderBy === 'recipient' ? (
              <i
                className={`${orderDirection === 'desc' ? 'fa fa-chevron-circle-up' : 'fa fa-chevron-circle-down'}`}
              />
            ) : ''}
          </span>
        </Button>
      ),
      id: 'Tracking',
      className: 'd-none d-lg-table-cell',
      disableSortBy: true,
      accessor: (d) => formatPhoneNumber(get(d, 'attributes.recipient', '')),
    },
    {
      Header: (
        <Button
          onClick={() => {
            setOrderBy('caller');
            setOrderDirection(orderDirection === 'desc' ? 'asc' : 'desc');
          }}
          className="custom-simple-txt-btn fw-bold"
        >
          <span>
            {'Caller '}
            {orderBy === 'caller' ? (
              <i
                className={`${orderDirection === 'desc' ? 'fa fa-chevron-circle-up' : 'fa fa-chevron-circle-down'}`}
              />
            ) : ''}
          </span>
        </Button>
      ),
      id: 'Caller',
      className: 'd-none d-lg-table-cell',
      disableSortBy: true,
      accessor: (d) => formatPhoneNumber(get(d, 'attributes.caller', '')),
    },
    {
      Header: (
        <Button
          onClick={() => {
            setOrderBy('duration');
            setOrderDirection(orderDirection === 'desc' ? 'asc' : 'desc');
          }}
          className="custom-simple-txt-btn fw-bold"
        >
          <span>
            {'Duration '}
            {orderBy === 'duration' ? (
              <i
                className={`${orderDirection === 'desc' ? 'fa fa-chevron-circle-up' : 'fa fa-chevron-circle-down'}`}
              />
            ) : ''}
          </span>
        </Button>
      ),
      id: 'Duration',
      className: 'd-none d-lg-table-cell',
      disableSortBy: true,
      accessor: (d) => secondsToHms(get(d, 'attributes.duration', '')),
    },
  ];

  return (
    <CtaReport
      dataTableColumns={dataTableColumns}
      orderBy={orderBy}
      orderDirection={orderDirection}
      ctaType="CTALogIncomingCall"
      typeName={['call', 'calls']}
    />
  );
}

export default CtaLogIncomingCall;
