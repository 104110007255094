import React, { useEffect, useState } from 'react';
import {
  Col,
  Row,
  Card,
  Form,
  Label,
  Input,
  Button,
  CardBody,
  FormGroup,
} from 'reactstrap';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { get, map, filter } from 'lodash-es';
import HeaderComponent from '../../../components/common/header-component';
import { replacePathParams, settingRoutes, mainRoutes } from '../../../constants/routes';
import { getInstanceOptions, MenuList } from '../../../containers/navs/InstanceSelection';
import CustomSelect from '../../../components/common/custom-select';
import { displayError, getSelectedOption } from '../../../../../Utils';
import UserFeaturePage from 'dashboard/src/components/common/user-feature-page';
import withRouter from '../../../helpers/withRouter';
import { useDuplicateInstanceMutation } from '../../../api/apiSlice';

function DuplicateInstance(props) {
  const { navigate } = props;
  const { siteId, instanceId } = useParams();

  const [params, setParams] = useState({});

  const { instancesList } = useSelector((state) => state.adminStore.instanceReducer);

  const commonPathParams = (path) => replacePathParams(path, [], props);
  const [duplicateInstance, { isSuccess }] = useDuplicateInstanceMutation();

  useEffect(() => {
    if (isSuccess) {
      navigate(replacePathParams(
        settingRoutes.backgroundJobs,
        [
          { key: 'instanceId', value: instanceId },
          { key: 'siteId', value: siteId },
          { key: 'pageNo', value: '1' },
        ],
        props,
      ));
    }
  });

  const staticBreadcrumbData = [
    { name: 'Dashboard', url: commonPathParams(settingRoutes.dashboard) },
    { name: 'Settings', url: commonPathParams(mainRoutes.setting) },
    { name: 'Duplicate Instance', url: '' },
  ];

  const onHandleChangeInstance = (e) => {
    const { value } = e;
    setParams({ ...params, id: value });
  };

  const onHandleSubmit = async () => {
    if (!params.id) {
      displayError('Please select Instance to duplicate.');
    } else if (!params.new_instance_name) {
      displayError('Please enter new instance name.');
    } else if (instancesList.some((i) => i.company_name === params.new_instance_name)) {
      displayError('Instance name already exists.');
    } else {
      await duplicateInstance(params);
    }
  };

  const onHandleChange = (e) => {
    const { name, value } = e.target;
    setParams({ ...params, [name]: value });
  };

  return (
    <div>
      <HeaderComponent setPath={{
        headingName: 'Duplicate Instance',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Back',
        backToPath: '',
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <Card>
        <CardBody>
          <UserFeaturePage feature="duplicate_instance">
            <Form>
              <Row>
                <Col lg={12} md={12}>
                  <FormGroup>
                    <Label for="display_name">
                      Instance to duplicate
                      <span className="text-danger">*</span>
                    </Label>
                    <CustomSelect
                      isSearchable
                      components={{ MenuList }}
                      className="flex-grow-1 me-2"
                      options={map(filter(instancesList, (i) => i.platform_status === 'new'), (i) => ({ label: i.company_name, value: i.id }))}
                      value={getSelectedOption(getInstanceOptions(instancesList), get(params, 'id', ''))}
                      onChange={(e) => onHandleChangeInstance(e)}
                    />
                  </FormGroup>
                </Col>
                <Col lg={12} md={12}>
                  <FormGroup>
                    <Label for="display_name">
                      New Instance name
                      <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      id="new_instance_name"
                      name="new_instance_name"
                      value={get(params, 'new_instance_name', '')}
                      onChange={(e) => onHandleChange(e)}
                    />
                  </FormGroup>
                </Col>
                <Col lg={12}>
                  <FormGroup>
                    <Button
                      color="success"
                      onClick={onHandleSubmit}
                    >
                      <b>Duplicate</b>
                    </Button>
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </UserFeaturePage>
        </CardBody>
      </Card>
    </div>
  );
}

export default withRouter(DuplicateInstance);
