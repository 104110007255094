import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Button,
  Nav,
  Modal,
  TabPane,
  NavItem,
  NavLink,
  ModalBody,
  TabContent,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import withRouter from '../../../../helpers/withRouter';
import CustomSelect from '../../../../components/common/custom-select';
import DuplicateSectionModal from '../DuplicateSectionModal';
import { sectionTypes } from '../../../../schema';
import { refetchPageData, useGetSectionsQuery, useSectionCreateMutation } from '../../../../api/apiSlice';
import SaveAndContinue from '../../../../components/common/saveAndContinue';
import { displayError, updateStylesheet } from '../../../../../../Utils';
import { useUser } from '../../../../hooks';

function AddSection(props) {
  const {
    sector,
    onClose,
    params,
    params: {
      instanceId, siteId, pageType, pageVersionId, pageId,
    },
  } = props;

  const dispatch = useDispatch();
  const [sectionCreate, { isSuccess }] = useSectionCreateMutation();
  const { refetch: refetchSections } = useGetSectionsQuery({ siteId, pageId, pageVersionId });

  const sectorLabel = sector?.label || '';

  const order = props?.order || 0;

  const user = useUser();

  const [type, setSectionType] = useState('');

  const [activeTab, setActiveTab] = useState('1');

  const isEmailPage = pageType === 'email';

  const sectionType = sectionTypes(false, isEmailPage ? 'email' : 'web', user);

  useEffect(() => {
    if (isSuccess) {
      refetchSections();
      updateStylesheet(params);
      refetchPageData();
    }
  }, [isSuccess, dispatch]);

  const onHandleSave = async () => {
    if (type === '') {
      displayError('Please select a section type.');
    } else {
      const sendParams = {
        instance_id: instanceId, order, type: type.value, sector: sectorLabel, page_version_id: pageVersionId,
      };
      const response = await sectionCreate(sendParams);
      if (response) {
        return true;
      }
    }
    return false;
  };

  const onHandleClose = () => {
    onClose();
  };

  return (
    <Modal isOpen autoFocus={false} container={window.modalRef}>
      <ModalHeader><b>Add Section</b></ModalHeader>
      <ModalBody>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classNames({ active: activeTab === '1' })}
              onClick={() => setActiveTab('1')}
            >
              Add Section
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classNames({ active: activeTab === '2' })}
              onClick={() => setActiveTab('2')}
            >
              Duplicate Section
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1" className="mt-4">
            <Row>
              <Col sm="12" className="mb-3">
                <CustomSelect
                  value={type}
                  placeholder="Select Section Type"
                  options={sectionType}
                  onChange={(e) => setSectionType(e)}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2" className="mt-4">
            {/* Most of the time users just want to add a new section, so avoid loading the page structure unless the user goes to the Duplicate tab. */}
            {activeTab === '2' && (
              <DuplicateSectionModal
                params={props?.params}
                getModalBody
                orderNo={order}
                setModal
                modalClose={onHandleClose}
                selectedSectorLabel={sectorLabel}
                duplicateSectionApiResponse={() => {
                  refetchSections();
                  updateStylesheet(params);
                }}
              />
            )}
          </TabPane>
        </TabContent>
        <ModalFooter>
          <Button
            type="submit"
            color="danger"
            onClick={onHandleClose}
            className="fw-bold"
          >
            CANCEL
          </Button>
          {activeTab === '1' && (
            <SaveAndContinue
              onSave={() => onHandleSave()}
              onContinue={onHandleClose}
            />
          )}
        </ModalFooter>
      </ModalBody>
    </Modal>
  );
}
export default withRouter(AddSection);
