import React, { useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';
import { baseURL } from '../../../../api';
import axios from 'axios';
import confirm from '../../components/common/confirm';
import { isEmpty, get } from 'lodash-es';
import { settingRoutes, replacePathParams } from '../../constants/routes';
import withRouter from '../../helpers/withRouter';

let qrInterval;
let statusInterval;

function QrCodeImage(props) {
  const [status, setStatus] = useState(null);

  const reloadQRCode = () => {
    if (document.getElementById('qrImage')) {
      document.getElementById('qrImage').setAttribute('src', `${baseURL}/qrcode/image`);
    } else {
      clearInterval(qrInterval);
      clearInterval(statusInterval);
    }
  };

  useEffect(() => {
    qrInterval = setInterval(reloadQRCode, 500000);
  }, []);

  const checkStatus = async () => {
    const res = await axios.create().get(`${baseURL}/qrcode/status`);
    if (res) {
      setStatus(res.data.data);
    } else {
      setStatus(null);
    }
  };

  useEffect(() => {
    if (isEmpty(status)) {
      statusInterval = setInterval(checkStatus, 2000);
    } else {
      clearInterval(statusInterval);
    }
  }, [status]);

  useEffect(() => {
    (async () => {
      if (status && !status.approved) {
        const { navigate } = props;
        const result = await confirm({
          title: <b>Confirm!</b>,
          message:
  <div>
    <h3>Another device is trying to log into your account</h3>
    <p className="mb-0">
      IP Address:&nbsp;
      {get(status, 'ip', '')}
    </p>
    <p>
      Device:&nbsp;
      {get(status, 'device', '')}
    </p>
    <h3>Do you want to trust this device?</h3>
  </div>,
          confirmText: 'Yes',
          confirmColor: 'success',
          cancelColor: 'btn btn-danger',
          cancelText: 'No',
        });

        if (result) {
          const res = await axios.create().post(
            `${baseURL}/qrcode/approve?nonce=${status.nonce}`,
          );
          if (res.data.data) {
            clearInterval(qrInterval);
            clearInterval(statusInterval);
            navigate(replacePathParams(settingRoutes.dashboard, [], props));
          }
        }
      }
    })();
  }, [status]);

  return (
    <Card>
      <CardBody className="text-center">
        <iframe
          title="Scan me!"
          id="qrImage"
          src={`${baseURL}/qrcode/image`}
          className="border-0 width-500 height-500"
        />
        <h2>Point your device at this screen to scan the QR code.</h2>
      </CardBody>
    </Card>
  );
}

export default withRouter(QrCodeImage);
