import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { get, isEmpty } from 'lodash-es';
import { useDispatch, useSelector } from 'react-redux';
import { connectTenantToInstance, xeroCallBackAction } from 'dashboard/src/redux/settings/xero/action.js';
import withRouter from 'dashboard/src/helpers/withRouter';
import { mainRoutes, replacePathParams, settingRoutes } from 'dashboard/src/constants/routes';
import { Button, Card, CardBody } from 'reactstrap';
import TableComponent from 'dashboard/src/components/common/Table';
import HeaderComponent from 'dashboard/src/components/common/header-component';
import { getInstanceByIdFromListing, getCookie, setCookie } from '../../../../../Utils';

const siteId = getCookie('SITE_ID');
const instanceId = getCookie('INSTANCE_ID');

function CallBackXero(props) {

  const { navigate } = props;
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { instancesList } = useSelector((state) => state.adminStore.instanceReducer);
  const code = searchParams.get('code');
  const state = searchParams.get('state');
  const [xeroCallBack, setXeroCallBack] = useState([]);
  const { xeroConnection } = useSelector((state) => state.adminStore.xeroReducer);

  const instanceData = getInstanceByIdFromListing(state);
  const haveOauth2 = !isEmpty(instanceData) && !isEmpty(instanceData?.oauth2_details?.xero) && xeroConnection;

  const redirectToServices = () => {
    navigate(replacePathParams(settingRoutes.services, [
      { key: 'instanceId', value: instanceId },
      { key: 'siteId', value: siteId },
    ]));
  };

  useEffect(() => {
    setCookie('INSTANCE_ID', state, 9999, '/');
    setCookie('SITE_ID', siteId, 9999, '/');

    if (!isEmpty(instancesList)) {
      if (!isEmpty(code)) {
        const grantType = haveOauth2 ? 'authorization_code' : 'refresh_token';
        const res = dispatch(xeroCallBackAction(
          {code, instance_id: state, grant_type: grantType},
          siteId,
          navigate,
        ));
        if (isEmpty(res)) {
          setXeroCallBack([]);
          redirectToServices();
        } else {
          setXeroCallBack(res);
        }
      }
    }
  }, [searchParams, code, instancesList]);

  const onHandleConnect = async (d) => {
    const res = await dispatch(connectTenantToInstance(d));
    if (res) {
      redirectToServices();
    }
  };

  const dataTableColumns = () => [
    {
      Header: 'Tenant Name',
      id: 'tenantName',
      accessor: (d) => get(d, 'tenantName', ''),
    },
    {
      Header: 'Tenant Type',
      id: 'tenantType',
      accessor: (d) => get(d, 'tenantType', ''),
    },
    {
      Header: 'Select',
      id: 'selectTenant',
      accessor: (d) => <Button onClick={() => onHandleConnect({
        tenant_id: d.tenantId,
        connection_id: d.id,
        instance_id: state,
      })}>Connect</Button>,
    },
  ];

  const staticBreadcrumbData = [
    { name: 'Dashboard', url: replacePathParams(settingRoutes.dashboard, [
      {key: 'siteId', value: siteId},
      {key: 'instanceId', value: state},
    ], props)},
    { name: 'Settings', url: replacePathParams(mainRoutes.setting, [
      {key: 'siteId', value: siteId},
      {key: 'instanceId', value: state},
    ], props)},
    { name: 'Services', url: replacePathParams(settingRoutes.services, [
      { key: 'siteId', value: siteId },
      { key: 'instanceId', value: instanceId },
    ], props)},
    { name: 'Xero', url: ''},
  ];

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Services',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Back',
        backToPath: replacePathParams(settingRoutes.dashboard, [], props),
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <Card>
        <CardBody>
          <TableComponent data={xeroCallBack || []} columns={dataTableColumns()} />
        </CardBody>
      </Card>
    </>
  );
}

export default withRouter(CallBackXero);
