/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Input, Row, Col } from 'reactstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
// import './index.scss';
import RadioButton from '../../../components/common/radioButton';
import CustomSelect, { allOption } from '../../../components/common/custom-select';
import { selectBoxOptions } from '../../../../../Utils';

function DynamicControl(props) {
  const {
    databaseField,
    valueOptions,
    initialState,
    onRangeChange,
    onHandleChange,
    onDateRangeChange,
  } = props;

  const { operator } = initialState;

  const onDefaultChecked = (checkList, checked) => checked.includes(checkList);

  if (databaseField) {
    const arr0 = initialState.value[0];
    const arr1 = initialState.value[1];
    const { attributes: { field_type } } = databaseField;

    switch (field_type) {
    case 'short_text':
      if (operator === 'is blank') {
        return null;
      }
      return (
        <Input
          value={initialState.value || ''}
          type="text"
          name="value"
          placeholder="Value"
          onChange={(e) => onHandleChange(e, field_type)}
          className="custom-input"
        />
      );
    case 'email':
      return (
        <Input
          value={initialState.value || ''}
          type="text"
          name="value"
          placeholder="Value"
          onChange={(e) => onHandleChange(e, field_type)}
          className="custom-input"
        />
      );
    case 'long_text':
      if (operator === 'is blank') {
        return null;
      }
      return (
        <Input
          value={initialState.value || ''}
          type="textarea"
          name="value"
          placeholder="Value"
          onChange={(e) => onHandleChange(e, field_type)}
        />
      );
    case 'numeric':
      if (operator === 'is between') {
        return (
          <Row>
            <Col xs="6">
              <Input
                value={arr0 || ''}
                type="number"
                data-options="true"
                placeholder="From"
                name="0"
                onChange={(e) => onRangeChange(e)}
                className="custom-input"
              />
            </Col>
            <Col xs="6">
              <Input
                value={arr1 || ''}
                type="number"
                data-options="true"
                placeholder="To"
                name="1"
                disabled={!initialState.value}
                onChange={(e) => onRangeChange(e)}
                className="custom-input"
              />
            </Col>
          </Row>
        );
      }
      return (
        <Input
          value={arr0 || ''}
          type="number"
          name="0"
          placeholder="Value"
          onChange={(e) => onRangeChange(e)}
          className="custom-input"
        />
      );
    case 'checkboxes': case 'select_list':
      return (
        <CustomSelect
          isMulti
          allowSelectAll
          allOption={allOption}
          closeMenuOnSelect={false}
          hideSelectedOptions={false}
          options={selectBoxOptions(valueOptions)}
          value={selectBoxOptions(valueOptions).filter(
            (obj) => initialState.value && initialState.value.includes(
              obj.value,
            ),
          )}
          onChange={(e) => onHandleChange(e, field_type)}
        />
      );
    case 'radio_button':
      return (
        <Row>
          <Col size="3">
            <ul className="custom-ul-layout">
              {valueOptions.map((obj, index) => (
                <li key={index}>
                  <RadioButton
                    display="block"
                    title={obj}
                    checked={onDefaultChecked(obj, initialState.value)}
                    onHandleChange={() => onHandleChange({ target: { value: obj } }, field_type)}
                  />
                </li>
              ))}
            </ul>
          </Col>
        </Row>
      );
    case 'date':
      if (operator === 'is between') {
        return (
          <Row>
            <Col xs="5">
              <DatePicker.default
                placeholderText="Select date"
                className={`custom-date-bx ${!operator ? 'allow-disabled' : ''}`}
                onChange={(date) => onDateRangeChange(date, 0)}
                selected={arr0 ? arr0 : null}
                disabled={!operator}
                showYearDropdown
                scrollableYearDropdown
                dropdownMode="scroll"
              />
            </Col>
            <Col xs="2" className="text-center">
              and
            </Col>
            <Col xs="5">
              <DatePicker.default
                placeholderText="Select date"
                className={`custom-date-bx ${!initialState.value ? 'allow-disabled' : ''}`}
                onChange={(date) => onDateRangeChange(date, 1)}
                selected={arr1 ? arr1 : null}
                disabled={!initialState.value}
                showYearDropdown
                scrollableYearDropdown
                dropdownMode="scroll"
              />
            </Col>
          </Row>
        );
      }
      return (
        <Row>
          <Col xs="12">
            <DatePicker.default
              placeholderText="Select date"
              className={`custom-date-bx ${!operator ? 'allow-disabled' : ''}`}
              onChange={(date) => onDateRangeChange(date, '0')}
              selected={undefined !== arr0 ? arr0 : null}
              disabled={!operator}
              showYearDropdown
              scrollableYearDropdown
              dropdownMode="scroll"
            />
          </Col>
        </Row>
      );

    default:
      return (
        <Input
          type="text"
          value={initialState.value || ''}
          name="value"
          className="custom-input"
          placeholder="Value"
          onChange={(e) => onHandleChange(e, field_type)}
        />
      );
    }
  }
  return '';
}

export default DynamicControl;
