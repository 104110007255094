/* eslint-disable react/jsx-props-no-spreading */
import { render } from 'react-dom';
import React from 'react';
import Modal from './Modal';

export default class Confirm {
  constructor(options) {
    this.options = {
      message: 'Are you sure?',
      title: 'Warning!',
      confirmText: 'Ok',
      cancelText: 'Cancel',
      confirmColor: 'primary',
      cancelColor: '',
      object: true,
      ...options,
    };

    this.el = document.createElement('div');
  }

  open() {
    const confirmPromise = new Promise((resolve) => {
      render(
        <Modal
          {...this.options}
          onClose={(result) => {
            resolve(result);
          }}
        />,
        this.el,
      );
    });

    return confirmPromise;
  }
}
