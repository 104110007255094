import client from '../../../api';

const endPoints = {
  reset_password: 'reset_password',
};

export default {
  resetPassword: (params) => client.post(endPoints.reset_password, params),
  verifyResetToken: (token) => client.get(`${endPoints.reset_password}/${token}`),
  setNewPassword: (params) => client.put(`${endPoints.reset_password}/${params.resetToken}`, params),
  setGmailSignature: (params) => client.post(`/users/${params.id}/set_gmail_signature`, params),
  sendPresence: (params, config) => client.post(`/users/${params.id}/presence`, params, config),
};
