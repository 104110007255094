import React from 'react';
import {
  Button,
  Row,
} from 'reactstrap';
import Select from 'react-select';
import { displayError, getSelectedOption, selectColorStyles } from '../../../../../Utils';
import { Colxx } from '../../../components/common/CustomBootstrap';
import { get, isEmpty } from 'lodash-es';
import DynamicControl from './DynamicControl';
import DataFieldSelectBox from './DataFieldSelectBox';
import { useGetDatabaseFieldsQuery } from '../../../api/apiSlice';

const getOperatorOptions = (args) => {
  const optionArray = [];
  args.map((opt) => optionArray.push({
    label: opt,
    value: opt,
  }));
  return optionArray;
};

const MemberSearchField = (props) => {
  const {
    instanceId,
    searchBehavior,
    field,
    index,
    removeSearchField,
    onChange,
  } = props;

  const databaseFieldId = field?.database_field_id;

  const { data } = useGetDatabaseFieldsQuery({ instanceId }, { skip: isEmpty(instanceId) });

  const fieldCollection = data || [];

  const databaseField = fieldCollection?.find((f) => f.id === databaseFieldId);

  const operator = get(field, 'operator', '');
  const value = get(field, 'value', null);

  const onHandleChange = (e, type, key) => {
    if (type === 'checkboxes' || type === 'select_list') {
      onChange(Array.isArray(e) ? e.map((x) => x.value) : [], key);
    } else {
      onChange(e.target.value, key);
    }
  };

  const onRangeChange = (e) => {
    let arr;
    if (value.length > 0) {
      arr = value;
    } else {
      arr = [];
    }
    arr[Number(e.target.name)] = Number(e.target.value);
    if ((arr.length > 1 && arr[1] <= arr[0]) || (arr.length > 1 && arr[1] === 0)) {
      arr = [arr[0], arr[1]];
      displayError('To age should not be blank or not greater than from age!');
    } else if (
      (arr.length === 1 && arr[0] === 0) || (arr[0] === 0 && arr[1] === 0) || (arr[1] === arr[0])) {
      arr = ['', ''];
      displayError(`Age should not be blank and should not equal to from age and should not equal to 0!`);
    } else {
      arr = [arr[0], arr[1]];
    }
    onChange(arr, 'value');
  };

  const onDateRangeChange = (e, type) => {
    let arr;
    if (value.length > 0) {
      arr = value;
    } else {
      arr = [];
    }
    arr[type] = e;

    if (arr.length === 1) {
      arr = [arr[0], null];
    } else if (arr.length >= 2) {
      arr = [arr[0], arr[1]];
    }
    onChange(arr, 'value');
  };

  return (
    <>
      <Row>
        {searchBehavior && searchBehavior === 'custom' && (
          <Colxx xxs="12" md="1" className="mt-2"><b>{(Number(index) + 1)}</b></Colxx>
        )}
        <Colxx xxs="12" md="3" className="mt-2">
          <DataFieldSelectBox
            defaultValue={false}
            instanceId={instanceId}
            placeholder="Select Field"
            selectedId={databaseFieldId}
            onChange={(e) => onChange(e.value, 'database_field_id')}
          />
        </Colxx>
        <Colxx xxs="12" md="3" className="mt-2">
          {!isEmpty(databaseField?.attributes?.operator_options) && (
            <Select
              placeholder="Select Operator"
              options={getOperatorOptions(databaseField.attributes.operator_options)}
              name="operator_name"
              isSearchable={false}
              onChange={(e) => onChange(e.value, 'operator')}
              value={getSelectedOption(getOperatorOptions(databaseField.attributes.operator_options), operator)}
              styles={selectColorStyles}
            />
          )}
        </Colxx>
        <Colxx xxs="12" md="4" className="mt-2">
          {operator && operator !== 'is blank' && (
            <DynamicControl
              onHandleChange={(e, type) => onHandleChange(e, type, 'value')}
              valueOptions={databaseField.attributes.values}
              databaseField={databaseField}
              initialState={field}
              onRangeChange={(e, r) => onRangeChange(e, r)}
              onDateRangeChange={onDateRangeChange}
            />
          )}
        </Colxx>
        <Colxx size="1" className="mt-2">
          <Button
            className="custom-simple-icon"
            color="danger"
            size="xs"
            onClick={removeSearchField}
          >
            <i className="fal fa-minus-circle" />
          </Button>
        </Colxx>
      </Row>
      <hr />
    </>
  );
};

export default MemberSearchField;
