import React from 'react';
import HeaderComponent from 'dashboard/src/components/common/header-component';
import { mainRoutes, replacePathParams, settingRoutes } from 'dashboard/src/constants/routes';
import withRouter from 'dashboard/src/helpers/withRouter';
import { isEmpty, map } from 'lodash-es';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormGroup, Label, Card, CardBody, CardColumns, CardTitle } from 'reactstrap';
import Switch from 'rc-switch';
import { checkXeroConnectivity, disconnectXero } from 'dashboard/src/redux/settings/xero/action';
import XeroAPI from 'dashboard/src/api/xero';
import UserFeaturePage from 'dashboard/src/components/common/user-feature-page';
import { displayError, getInstanceByIdFromListing, getSelectedOption } from '../../../../../Utils';
import CustomSelect from 'dashboard/src/components/common/custom-select';
import { useGetEmailSeriesQuery, useUpdateInstanceMutation } from '../../../api/apiSlice';

function Services(props) {
  const dispatch = useDispatch();
  const { params: { instanceId }} = props;
  const { instancesList } = useSelector((state) => state.adminStore.instanceReducer);
  const instanceData = getInstanceByIdFromListing(instanceId);
  const { xeroConnection } = useSelector((state) => state.adminStore.xeroReducer);
  const haveOauth2 = !isEmpty(instanceData) && !isEmpty(instanceData?.oauth2_details?.xero) && xeroConnection;
  const [updateInstance] = useUpdateInstanceMutation();
  const { data: emailSeries = [] } = useGetEmailSeriesQuery(instanceId, { skip: isEmpty(instanceId) && !isEmpty(haveOauth2) });

  useEffect(() => {
    if (!isEmpty(instancesList)) {
      if (!isEmpty(instanceData) && instanceData?.oauth2_details?.xero) {
        dispatch(checkXeroConnectivity({ instance_id: instanceId }));
      }
    }
  }, [instancesList]);

  const onHandleConnectXero = async () => {
    const response = await XeroAPI.connect({instance_id: instanceId});
    if (!isEmpty(response)) {
      const { data: { data }} = response;
      if (data?.login_url) {
        window.location.href = data.login_url;
      } else {
        displayError('Error getting Xero login URL.');
      }
    }
  };

  const onHandleDisconnectXero = () => {
    dispatch(disconnectXero({ instance_id: instanceId }));
  };

  const staticBreadcrumbData = [
    { name: 'Dashboard', url: replacePathParams(settingRoutes.dashboard, [], props)},
    { name: 'Settings', url: replacePathParams(mainRoutes.setting, [], props)},
    { name: 'Services', url: '' },
  ];

  const emailSeriesOptions = map(emailSeries, (item) => ({label: item.name, value: item.id}));

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Services',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Back',
        backToPath: replacePathParams(settingRoutes.dashboard, [], props),
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <UserFeaturePage feature="connections">
        <CardColumns>
          <Card>
            <CardBody>
              <CardTitle>Xero</CardTitle>
              <FormGroup>
                <Label check>{haveOauth2 ? 'Connected — Click to disconnect' : 'Connect to Xero'}</Label>
                <Switch
                  className="custom-switch custom-switch-primary"
                  checked={haveOauth2}
                  onClick={() => {
                    if (!haveOauth2) {
                      onHandleConnectXero();
                    } else {
                      onHandleDisconnectXero();
                    }
                  }}
                />
              </FormGroup>
              {haveOauth2 && (
                <>
                  <FormGroup>
                    <Label check className="mt-2">Send Review Requests via email</Label>
                    <Switch
                      className="custom-switch custom-switch-primary"
                      checked={instanceData.xero_send_email}
                      onChange={() => {
                        const sendParams = {
                          ...instanceData,
                          instance_id: instanceId,
                          xero_send_email: !instanceData.xero_send_email,
                        };
                        updateInstance(sendParams);
                      }}
                    />
                  </FormGroup>
                  {instanceData.xero_send_email && (
                    <FormGroup>
                      <Label className="mt-2">
                        Review Request Email Series
                        <CustomSelect
                          value={getSelectedOption(emailSeriesOptions, instanceData?.review_request_email_series_id)}
                          options={emailSeriesOptions}
                          onChange={(e) => {
                            const sendParams = {
                              ...instanceData,
                              instance_id: instanceId,
                              review_request_email_series_id: e.value,
                            };
                            updateInstance(sendParams);
                          }}
                        />
                      </Label>
                    </FormGroup>
                  )}
                  <FormGroup>
                    <Label check className="mt-2">Send Review Requests via SMS</Label>
                    <Switch
                      className="custom-switch custom-switch-primary"
                      checked={instanceData.xero_send_sms}
                      onChange={() => {
                        const sendParams = {
                          ...instanceData,
                          instance_id: instanceId,
                          xero_send_sms: !instanceData.xero_send_sms,
                        };
                        updateInstance(sendParams);
                      }}
                    />
                  </FormGroup>
                </>
              )}
            </CardBody>
          </Card>
        </CardColumns>
      </UserFeaturePage>
    </>
  );
}

export default withRouter(Services);
