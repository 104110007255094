import React, { useEffect, useState } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  Label,
  Input,
} from 'reactstrap';
import TableComponent from '../../../components/common/Table';
import { get, map, isEmpty, isEqual } from 'lodash-es';
import { displayError } from '../../../../../Utils';

const disallowMetaTags = ['description', 'viewport', 'robots', 'og:url'];

const parseMetaTag = (str) => {
  let parser = new DOMParser();
  const parseMeta = parser.parseFromString(str, 'text/html');
  return parseMeta.getElementsByTagName('meta');
};

const PageMetaTags = (props) => {
  const { page, setPage } = props;

  const [metaTags, setMetaTags] = useState([]);

  useEffect(() => {
    if (!isEmpty(page) && !isEmpty(page.meta_tags)) {
      const newMetaTags = Object.keys(page.meta_tags).map((e) => ({ name: e, content: page.meta_tags[e] }));
      if (!isEqual(metaTags, newMetaTags)) {
        setMetaTags(newMetaTags);
      }
    }
  }, [page]);

  useEffect(() => {
    setPage({ ...page, meta_tags: Object.fromEntries(map(metaTags, (e) => ([e.name, e.content]))) });
  }, [metaTags]);

  const onAddMetaTag = () => {
    if (!isEmpty(metaTags)) {
      if (metaTags.some((el) => el.name === '' || el.content === '')) {
        displayError('Please enter meta tag name Or content.');
      } else if (metaTags.some((el) => disallowMetaTags.includes(el.name))) {
        displayError('You can not add this meta tag as they are automatically added by the system.');
      } else {
        setMetaTags([
          ...metaTags,
          { name: '', content: '' },
        ]);
      }
    } else {
      setMetaTags([
        ...metaTags,
        { name: '', content: '' },
      ]);
    }
  };

  const onChangeMetaTagsValue = (e, index) => {
    const { value, name } = e.target;
    if (disallowMetaTags.includes(value)) {
      displayError(`You can not add ${value} meta tag as this tag automatically added by the system.`);
    } else {
      const tempTags = [ ...metaTags ];
      tempTags[index][name] = value;
      setMetaTags([ ...tempTags ]);
    }
  };

  const onRemoveMetaTag = (index) => {
    const tempMeta = [...metaTags];
    tempMeta.splice(index, 1);
    setMetaTags(tempMeta);
  };

  const metaTagsColumns = [
    {
      Header: 'Name',
      id: 'name',
      disableSortBy: true,
      accessor: (d, index) => (
        <Input
          autoFocus
          type="text"
          name="name"
          value={get(d, 'name', '')}
          onChange={(e) => onChangeMetaTagsValue(e, index)}
        />
      ),
    },
    {
      Header: 'Content',
      id: 'content',
      disableSortBy: true,
      accessor: (d, index) => (
        <Input
          type="text"
          name="content"
          value={get(d, 'content', '')}
          onChange={(e) => onChangeMetaTagsValue(e, index)}
        />
      ),
    },
    {
      Header: '',
      id: 'action',
      disableSortBy: true,
      accessor: (d, index) => (
        <Button
          className="custom-simple-icon"
          color="danger"
          size="xs"
          onClick={() => onRemoveMetaTag(index)}
        >
          <i className="fa-solid fa-minus" />
        </Button>
      ),
    },
  ];

  const onPaste = (e) => {
    e.preventDefault();

    const getMeta = parseMetaTag(e.clipboardData.getData('text'));

    if (isEmpty(getMeta)) {
      displayError('Could not parse meta tag.');
    } else {
      const key = getMeta[0].name || getMeta[0].getAttribute('property');

      if (disallowMetaTags.includes(key)) {
        displayError(`You can not add ${key} meta tag as this tag automatically added by the system.`);
      } else {
        setMetaTags([
          ...metaTags,
          { name: key, content: getMeta[0].content },
        ]);
      }
    }
  };

  return (
    <Container fluid>
      <Row>
        <Col lg={12}>
          <h2>Meta Tags</h2>
        </Col>
      </Row>
      <Row>
        <Col>
          <TableComponent data={metaTags} columns={metaTagsColumns} />
        </Col>
      </Row>
      <Row>
        <Col>
          <Button
            className="ms-2 common-success-button button-md btn-success"
            color="success"
            onClick={onAddMetaTag}
          >
            <i className="fa-solid fa-plus" />
            {' Add meta tag'}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col lg="12" className="mt-3">
          <Label className="w-100">
            Or, paste the full meta tag here:
            <Input onChange={(e) => e.preventDefault()} onPaste={onPaste} />
          </Label>
        </Col>
      </Row>
    </Container>
  );
};

export default PageMetaTags;
