import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
// import './index.scss';

function EmailPreview(props) {
  const {
    id, instanceId, closeModal,
  } = props;

  return (
    <Modal isOpen={true} size="lg" className="email-preview-modal">
      <ModalBody>
        <iframe
          className="w-100 h-100"
          title={`page-preview${id}`}
          src={`/api/v1/instances/${instanceId}/email_pages/${id}/email_preview`}
        />
      </ModalBody>
      <ModalFooter>
        <div className="text-end">
          <Button onClick={closeModal} color="danger" size="sm">Close</Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default EmailPreview;
