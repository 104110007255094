import React from 'react';
import { Label, FormGroup } from 'reactstrap';
import CustomSelect from '../../../components/common/custom-select';
import { isEmpty, find } from 'lodash-es';
import { useGetOrphanPagesQuery } from '../../../api/apiSlice';

function PopupPage(props) {
  const { params, setParams, siteId, instanceId } = props;

  const { data: orphanPages = [] } = useGetOrphanPagesQuery({ instanceId, siteId }, { skip: isEmpty(instanceId) && isEmpty(siteId) });

  if (typeof params.popup_page_id === 'undefined' || isEmpty(orphanPages)) {
    return <></>;
  }

  const selectedPage = find(orphanPages, (page) => page.id === params.popup_page_id);

  return (
    <FormGroup>
      <Label>Popup Page</Label>
      <CustomSelect
        onChange={(e) => setParams({ ...params, popup_page_id: e.value })}
        value={{ label: selectedPage?.name || 'No Popup', value: selectedPage?.id }}
        options={[{ label: 'No Popup', value: null }, ...orphanPages.map((page) => ({ label: page.name, value: page.id }))]}
      />
    </FormGroup>
  );
}

export default PopupPage;
