import React, { useEffect, useState } from 'react';
import TableComponent from '../../components/common/Table';
import CustomButton from '../../components/common/button';
import { useDispatch, useSelector } from 'react-redux';
import { getAutoReports, runReport } from '../../redux/automated-report/actions';
import { get, map } from 'lodash-es';
import DateTimeFormat from '../../components/common/DateTimeFormat';
import {
  Button, Modal, ModalHeader, ModalBody, ModalFooter, Card, CardBody,
} from 'reactstrap';
import { useParams } from 'react-router';
import { DateTime } from 'luxon';
import HeaderComponent from '../../components/common/header-component';
import { settingRoutes, replacePathParams, mainRoutes } from '../../constants/routes';
import UserFeaturePage from 'dashboard/src/components/common/user-feature-page';
import withRouter from '../../helpers/withRouter';

function AutomatedReport(props) {
  const { instanceId } = useParams();
  const [modal, setModal] = useState(false);
  const [statusData, setStatusData] = useState(null);

  const dispatch = useDispatch();
  const { autoReports } = useSelector((state) => state.adminStore.automatedReportReducer);

  useEffect(() => {
    if (instanceId) {
      dispatch(getAutoReports({ instance_id: instanceId }));
    }
  }, [dispatch]);

  const onHandleRun = (id) => {
    dispatch(runReport({
      job_id: id,
      instance_id: instanceId,
    }));
  };

  const onHandleStatus = (id) => {
    setModal(true);
    const getArr = autoReports.filter((item) => item.id === id);
    setStatusData(getArr[0].status);
  };

  const onHandleToggle = () => {
    setModal(false);
    setStatusData(null);
  };

  const dataTableColumns = () => [
    {
      Header: 'Last run time',
      id: 'last_run_time',
      accessor: (d) => {
        const { last_run_time } = d;
        return last_run_time && <DateTimeFormat datetime={last_run_time} localeString={DateTime.DATETIME_MED} />;
      },
    },
    {
      Header: 'Schedule',
      id: 'schedule',
      accessor: (d) => get(d, 'schedule', ''),
    },
    {
      Header: 'Description',
      id: 'description',
      accessor: (d) => get(d, 'description', ''),
    },
    {
      Header: 'Status',
      id: 'status',
      accessor: (d) => (
        <CustomButton
          size="xs"
          title=""
          color="info"
          icon="fas fa-exclamation-circle"
          className="custom-simple-icon"
          onClick={() => onHandleStatus(d.id)}
        />
      ),
    },
    {
      Header: 'Actions',
      id: 'actions',
      accessor: (d) => (
        <CustomButton
          size="xs"
          title="Run"
          color="success"
          className="custom-simple-icon"
          onClick={() => onHandleRun(d.id)}
        />
      ),
    },
  ];

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    {
      name: 'Settings',
      url: replacePathParams(mainRoutes.setting, [], props),
    },
    { name: 'Automated Reports', url: '' },
  ];

  return (
    <>
      <HeaderComponent setPath={{
        headingName: 'Automated Reports',
        addNewBtnName: '',
        addNewPath: '',
        backBtnName: 'Back',
        showBreadcrumb: false,
        staticBreadcrumbData,
      }}
      />
      <Card>
        <CardBody>
          <UserFeaturePage feature="reports">
            <h2>Automated Reports</h2>
            <TableComponent
              columns={dataTableColumns()}
              data={autoReports}
            />
            <Modal isOpen={modal} toggle={onHandleToggle}>
              <ModalHeader toggle={onHandleToggle}>Errors</ModalHeader>
              <ModalBody>
                {map(statusData, (element, index) => (
                  <code
                    key={index}
                    className="d-block mb-2"
                  >
                    {element}
                  </code>
                ))}
              </ModalBody>
              <ModalFooter>
                <Button color="danger" size="sm" onClick={onHandleToggle}>Cancel</Button>
              </ModalFooter>
            </Modal>
          </UserFeaturePage>
        </CardBody>
      </Card>
    </>
  );
}
export default withRouter(AutomatedReport);
