import {
  SET_SITE,
} from '../../../actions';

export const actionType = (payload, type) => ({
  type,
  payload,
});

export const setSite = (payload) => ({
  type: SET_SITE,
  payload,
});
