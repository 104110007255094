/* eslint-disable no-param-reassign */
import React, { useEffect, useState } from 'react';
import { Row, Col, Label, FormGroup } from 'reactstrap';
import { cloneDeep, isEmpty } from 'lodash-es';
import withRouter from '../../../../helpers/withRouter';
import { useGetSectionQuery, useSectionUpdateMutation } from '../../../../api/apiSlice';
import AdvancedSettings from '../../../../components/common/advancedSettings';
import Switch from 'rc-switch';
import { updateStylesheet } from '../../../../../../Utils';

function ReviewList(props) {
  const {
    section: { id: sectionId },
    isEmailPage,
    submitCounter,
    params: { instanceId, siteId },
    postSave,
    refetchPageData,
  } = props;

  const [tempSection, setTempSection] = useState(null);

  const { data: section } = useGetSectionQuery({ instanceId, sectionId });

  const [sectionUpdate] = useSectionUpdateMutation();

  useEffect(() => {
    if (section && tempSection === null) {
      setTempSection({ ...tempSection, ...cloneDeep(section) });
    }
  }, [section]);

  const onSave = async () => {
    const res = await sectionUpdate({
      ...tempSection,
      instance_id: instanceId,
    });
    if (!isEmpty(res)) {
      refetchPageData();
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (submitCounter) {
      (async () => {
        await onSave();
        postSave();
        updateStylesheet({ siteId, pageId: tempSection?.page_id, pageVersionId: tempSection?.page_version_id });
      })();
    }
  }, [submitCounter]);

  if (isEmpty(tempSection)) {
    return <></>;
  }

  return (
    <>
      <AdvancedSettings
        siteId={siteId}
        instanceId={instanceId}
        isEmailPage={isEmailPage}
        section={tempSection}
        setSection={setTempSection}
        handleSaveBreakpoint={(e) => setTempSection({ ...tempSection, break_point: e })}
        onHandleSectionIsOffline={() => setTempSection({ ...tempSection, is_offline: !tempSection?.is_offline })}
        onChangeInitialVisibility={() => setTempSection({ ...tempSection, initial_visibility: !tempSection?.initial_visibility })}
        onHandleChangeVisibility={(e) => setTempSection({ ...tempSection, visible_to: e.value })}
      />
      <Row className="mt-4">
        <Col>
          <FormGroup>
            <Label>Include average box at top of review list</Label>
            <Switch
              className="custom-switch custom-switch-primary"
              checked={tempSection.include_average}
              onChange={(e) => setTempSection({ ...tempSection, include_average: e })}
            />
          </FormGroup>
        </Col>
      </Row>
    </>
  );
}

export default withRouter(ReviewList);
