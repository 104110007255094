import client from '../../../api';

export default {
  textBlockIsOffline: (params) => client.put(
    `/instances/${params.instance_id}/sections/${params.section_id}/text_blocks/${params.text_block_id}/update_online_offline`,
    params,
  ),
  textBlockDelete: (params) => client.delete(
    `/instances/${params.instance_id}/sections/${params.section_id}/text_blocks/${params.id}`,
    params,
  ),
};
