import React from 'react';
import { Label, Input } from 'reactstrap';
import PropTypes from 'prop-types';

function CheckBox(props) {
  const { title, display } = props;

  return (
    <Label className={`me-2 user-select-none ${display}`}>
      <Input {...props} type="checkbox" />
      {` ${title}`}
    </Label>
  );
}

CheckBox.propTypes = {
  disabled: PropTypes.bool,
  display: PropTypes.string,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

CheckBox.defaultProps = {
  disabled: false,
  display: '',
};

export default CheckBox;
