import React, { useState, useEffect, Fragment } from 'react';
import {
  Card, Tooltip, Input, Row, Col, Button,
} from 'reactstrap';
import PageUrlAPI from '../../../api/page_url';
import { displaySuccess, displayError } from '../../../../../Utils';
import HeaderComponent from '../../../components/common/header-component';
import {
  settingRoutes, siteCenter, replacePathParams, getPageEditUrl,
} from '../../../constants/routes';
import Switch from 'rc-switch';
import withRouter from '../../../helpers/withRouter';

function PageUrl(props) {
  const { params } = props;
  const [getUrlName, setUrlName] = useState('');
  const [getUrlData, setUrlData] = useState([]);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [editThisId, setThisUrlId] = useState('');
  const [getNewUrl, setNewUrl] = useState({ id: '', url: '', primary_url_id: '' });

  useEffect(() => {
    const urlsListing = async () => {
      const getPageId = params.pageId;
      const response = await PageUrlAPI.pageUrlGet(getPageId);
      if (undefined !== response) {
        const { data } = response.data;
        setUrlData(data);
      }
    };
    urlsListing();
  }, []);

  /* On change url name */
  const onChangeUrl = (e) => {
    const { value } = e.target;
    setUrlName(value);
  };

  const sendParams = {
    resource_id: params.pageId,
    resource_type: 'Page',
    url: getUrlName,
    primary_url_id: '',
    prefix: false,
  };

  /* On add new url */
  const onAddNewUrl = async () => {
    try {
      if (sendParams.url === '') {
        displayError('Please enter URL!');
      } else {
        const response = await PageUrlAPI.pageUrlCreate(sendParams);
        if (undefined !== response) {
          const { data } = response;
          const tempData = [...getUrlData, data.data];
          setUrlData(tempData);
          setUrlName('');
          setThisUrlId('');
          displaySuccess(data.messages);
        }
      }
    } catch (error) {
      displayError(`${error?.name}: ${error?.message}`);
    }
  };

  /* On make primary click */
  const onMakePrimary = async (urlId) => {
    try {
      sendParams.primary_url_id = urlId;
      const response = await PageUrlAPI.pageUrlSetPrimaryUrl(sendParams);
      if (undefined !== response) {
        const { data } = response;
        setUrlData(data.data);
      }
    } catch (error) {
      displayError(`${error?.name}: ${error?.message}`);
    }
  };

  /* On delete url */
  const onHandleDeleteUrl = async (e) => {
    try {
      const response = await PageUrlAPI.pageUrlDelete(e);
      if (undefined !== response) {
        const { data } = response;
        const updatedData = getUrlData.filter((items) => items.id !== e);
        setUrlData(updatedData);
        displaySuccess(data.messages);
      }
    } catch (error) {
      displayError(`${error?.name}: ${error?.message}`);
    }
  };

  /* On handle update url click */
  const onHandleUpdateUrl = (e) => {
    setThisUrlId(e);
  };

  /* On handle submit update url */
  const onHandleSubmitUpdateUrl = async () => {
    const payload = {
      ...getNewUrl,
      resource_type: 'Page',
      resource_id: params.pageId,
    };
    try {
      const response = await PageUrlAPI.pageUrlUpdate(payload);
      if (undefined !== response) {
        const { data } = response;
        setThisUrlId('');
        setUrlData(data.data);
        displaySuccess(data.messages);
      }
    } catch (error) {
      displayError(`${error?.name}: ${error?.message}`);
    }
  };

  /* On update url name */
  const onChangUrlName = (e, index, id) => {
    const { value } = e.target;
    const updatedValue = [...getUrlData];
    updatedValue[index].url = value;
    setNewUrl({ id, url: value, primary_url_id: id });
    setUrlData(updatedValue);
  };

  /* On cancel updating url name */
  const onCancelUpdate = () => {
    setThisUrlId('');
  };

  /* Render URL name || text box */
  const renderTextBox = (data, index) => {
    if (editThisId === data.id) {
      return (
        <Input
          className="custom-input-add-border"
          value={data.url || ''}
          onChange={(e) => onChangUrlName(e, index, data.id)}
          disabled={false}
        />
      );
    }
    return `${data.url}`;
  };

  /* Render primary button */
  const renderPrimaryButton = (data) => {
    if (data.id === data.primary_url_id) {
      return (
        <Button
          className="custom-simple-icon"
          color="success"
          size="xs"
          onClick={() => onMakePrimary(data.id)}
          disabled
        >
          <i className="fal fa-check-circle" />
        </Button>
      );
    }
    return (
      <Button
        className="custom-simple-icon"
        color="info"
        size="xs"
        onClick={() => onMakePrimary(data.id)}
      >
        <i className="fal fa-check-circle" />
      </Button>
    );
  };

  /* Render update button on edit button click */
  const renderUpdateButton = (id) => {
    if (editThisId === id) {
      return (
        <Button
          className="custom-simple-icon"
          color="success"
          size="xs"
          onClick={() => onHandleSubmitUpdateUrl()}
        >
          <b>Update</b>
        </Button>
      );
    }
    return (
      <Button
        className="custom-simple-icon"
        color="info"
        size="xs"
        onClick={() => onHandleUpdateUrl(id)}
      >
        <i className="fal fa-pen" />
      </Button>
    );
  };

  /* Render delete button on edit button click */
  const renderDeleteButton = (data) => {
    if (editThisId === data.id) {
      return (
        <Button className="custom-simple-icon" color="danger" size="xs" onClick={onCancelUpdate}><b>Cancel</b></Button>
      );
    }
    return (
      <Button
        className="custom-simple-icon"
        color="danger"
        size="xs"
        onClick={() => onHandleDeleteUrl(data.id)}
        disabled={data.id === data.primary_url_id}
      >
        <i className="fal fa-trash" />
      </Button>
    );
  };

  /* Rendering url listing */
  const renderUrlListing = () => getUrlData.map(((items, index) => (
    <Row className="mb-4" key={items.id}>
      <Col className="col-sm-6">{renderTextBox(items, index)}</Col>
      <Col className="col-sm-3 text-end">{renderPrimaryButton(items)}</Col>
      <Col className="col-sm-1">{renderUpdateButton(items.id)}</Col>
      <Col className="col-sm-2">{renderDeleteButton(items)}</Col>
    </Row>
  )));

  /* On enable and disable prefix */
  const onPrefixEnable = async (e) => {
    try {
      sendParams.prefix = e;
      const response = await PageUrlAPI.pageUrlUpdatePrefix(sendParams);
      if (undefined !== response) {
        const { data } = response;
        setUrlData(data.data);
        displaySuccess(data.messages);
      }
    } catch (error) {
      displayError(`${error?.name}: ${error?.message}`);
    }
  };

  /* Toggle switch */
  const toggleMe = () => {
    setTooltipOpen((prevState) => !prevState);
  };

  const onHandleEnter = (event) => {
    if (event.which === 13) {
      onAddNewUrl();
    }
  };

  const editPageVersionUrl = getPageEditUrl(params.pageVersionId, params.pageId, 'web', props);
  const siteCenterUrl = replacePathParams(siteCenter.pageList, [], props);

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    { name: 'Site Centre', url: siteCenterUrl },
    { name: 'Page', url: editPageVersionUrl },
    { name: 'URL', url: '' },
  ];

  return (
    <>
      <HeaderComponent
        setPath={{
          headingName: 'Page URLs',
          addNewBtnName: '',
          addNewPath: '',
          backBtnName: 'Back',
          backToPath: editPageVersionUrl,
          showBreadcrumb: false,
          staticBreadcrumbData,
        }}
      />
      <Card body>
        <Row>
          <Col className="col-sm-10">
            <Input
              autoFocus
              type="text"
              value={getUrlName || ''}
              placeholder="URL"
              onChange={onChangeUrl}
              onKeyPress={(e) => onHandleEnter(e)}
            />
          </Col>
          <Col className="col-sm-1">
            <Button color="info" className="custom-simple-icon" onClick={onAddNewUrl}>
              <i className="fal fa-plus-circle" />
              {' '}
              <b>Add</b>
            </Button>
          </Col>
          <Col className="col-sm-1 d-flex align-items-center justify-content-center">
            <div className="d-none d-md-inline-block align-middle me-3">
              <Switch
                id="Tooltip-Switch"
                className="custom-switch custom-switch-primary"
                checked={undefined !== getUrlData[0] ? getUrlData[0].prefix : false}
                onChange={onPrefixEnable}
              />
              <Tooltip placement="top" isOpen={tooltipOpen} target="Tooltip-Switch" toggle={toggleMe}>
                Prefix
              </Tooltip>
            </div>
          </Col>
        </Row>
        {getUrlData.length > 0
          ? (
            <div className="mt-3 ">
              <hr />
              <Row className="mb-4">
                <Col className="col-sm-6"><b>Name</b></Col>
                <Col className="col-sm-3 text-end"><b>Make Primary</b></Col>
                <Col className="col-sm-1"><b>Edit</b></Col>
                <Col className="col-sm-2"><b>Delete</b></Col>
              </Row>
              {renderUrlListing()}
            </div>
          )
          : ''}
      </Card>
    </>
  );
}
export default withRouter(PageUrl);
