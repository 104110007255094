/* eslint-disable no-param-reassign */
import React, { Fragment, createRef, useEffect, useRef, useState } from 'react';
import SortableTree from 'react-sortable-tree';
import {
  Card,
  CardTitle,
  CardBody,
  Input,
  InputGroup,
  InputGroupText,
  Label,
  Tooltip,
} from 'reactstrap';
// import 'react-sortable-tree/style.css';
import Page from '../../../api/page';
import ProgressJobAPI from '../../../api/import_members';
import { useSelector } from 'react-redux';
import { displayError, displaySuccess, generateURL } from '../../../../../Utils';
import { getPageEditUrl } from '../../../constants/routes';
import confirm from '../../../components/common/confirm';
import { getSiteTree } from '../../../components/common/PageHierarchy';
import CustomSelect from '../../../components/common/custom-select';
import { cloneDeep, isEmpty, map } from 'lodash-es';
import BottomActionToolbar from '../../../components/common/toolbar';
import CustomButton from '../../../components/common/button';
import { CircularProgressbar } from 'react-circular-progressbar';
import withRouter from '../../../helpers/withRouter';
import CustomNodeContentRenderer from './CustomNodeContentRenderer';
import PageOption from './PageOption';
import DuplicatePageModal from './DuplicatePageModal';
import { filterTemplateOption } from '../PageEdit/PageEdit';
import { formatGroupLabel, getDefaultTemplate, getTemplateOptions } from '../PageEdit/PageEditForm';
import { useNavigate } from 'react-router';
import {
  useAddNewPageMutation,
  useDuplicatePageMutation,
  useGetMasterPagesQuery,
  useGetOrphanPagesQuery,
  useGetPagesQuery,
  useGetTemplatesQuery,
} from '../../../api/apiSlice';

let progressInterval;

const canDrop = (object) => {
  if (object.nextParent === null) {
    return false;
  }
  return true;
};

const getTop = (obj) => {
  let top = 0;
  if (obj.offsetParent) {
    do {
      top += obj.offsetTop;
      // eslint-disable-next-line no-cond-assign
    } while (obj = obj.offsetParent);
    return top;
  }
  return undefined;
};

function SiteTree(props) {
  const { params: { instanceId, siteId } } = props;

  const [modal, setModal] = useState(false);
  const [pageToDuplicate, setPageToDuplicate] = useState(null);
  const [bulkEditData, setBulkEditData] = useState({
    site_id: props.params.siteId,
    instance_id: props.params.instanceId,
    data: {},
  });
  const [changedPageTitle, setChangedPageTitle] = useState([]);
  const [bulkErrors, setBulkErrors] = useState([]);
  const [progressJobId, setProgressJobId] = useState(null);
  const [percentage, setPercentage] = useState(null);
  const [progressObj, setProgressObj] = useState(null);
  const [tooltipId, setTooltipId] = useState(null);
  const [pageList, setPageList] = useState([]);
  const [orphanPages, setOrphanPages] = useState([]);
  const [masterPages, setMasterPages] = useState([]);

  const navigate = useNavigate();
  const { site } = useSelector((state) => state.adminStore.sitesReducer);

  const myRefs = useRef([]);

  const { data: pages, refetch: refetchPages } = useGetPagesQuery(
    { instanceId, siteId }, { skip: isEmpty(instanceId) && isEmpty(siteId) }
  );
  const { data: orphans, refetch: refetchOrphanPages } = useGetOrphanPagesQuery(
    { instanceId, siteId }, { skip: isEmpty(instanceId) && isEmpty(siteId) }
  );
  const { data: masters, refetch: refetchMasterPages } = useGetMasterPagesQuery(
    { instanceId, siteId }, { skip: isEmpty(instanceId) && isEmpty(siteId) }
  );
  const { data: templates, refetch: refetchTemplates } = useGetTemplatesQuery(
    { instanceId, siteId }, { skip: isEmpty(instanceId) && isEmpty(siteId) }
  );

  const [addNewPage] = useAddNewPageMutation();
  const [duplicatePage] = useDuplicatePageMutation();

  useEffect(() => {
    if (pages) {
      setPageList(cloneDeep(pages));
    }
    if (orphans) {
      setOrphanPages(cloneDeep(orphans));
    }
    if (masters) {
      setMasterPages(cloneDeep(masters));
    }
  }, [pages, orphans, masters]);

  const loadPageTreeData = () => {
    refetchPages();
    refetchOrphanPages();
    refetchMasterPages();
    refetchTemplates();
  };

  const getProgressJob = async () => {
    try {
      if (!isEmpty(progressJobId)) {
        const apiParams = {
          job_id: progressJobId,
          instance_id: instanceId,
        };
        const res = await ProgressJobAPI.progressJob(apiParams, { disableSpinner: true });
        if (res) {
          const { data: { data } } = res;
          if ('job' in data) {
            setProgressJobId(null);
            setProgressObj(data.obj);
            setPercentage(0);
            loadPageTreeData();
          } else {
            setPercentage(data.percentage);
          }
        } else {
          setProgressJobId(null);
          setPercentage(0);
        }
      }
    } catch (error) {
      if (error) {
        displayError(`${error?.name}: ${error?.message}`);
      }
    }
  };

  const onScroll = () => {
    const s = window.scrollY;

    myRefs.current.forEach((ref) => {
      const thead = ref[1].current;
      if (!thead) {
        return;
      }
      if (typeof thead.dataset.originaltop === 'undefined') {
        const top = getTop(thead);
        thead.dataset.originaltop = top;
      }
      const top = parseInt(thead.dataset.originaltop, 10);

      // 100px is roughly the height of the header.
      if (s > top - 100) {
        thead.style.top = `${(0 - (top - 100)) + s}px`;
      } else {
        thead.style.top = '0px';
      }
    });
  };

  useEffect(() => {
    progressInterval = setInterval(getProgressJob, 2000);

    window.addEventListener('scroll', onScroll);

    return () => {
      clearInterval(progressInterval);
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const handleTreeOnChange = (treeData) => {
    setPageData('pageList', treeData);
  };

  const nodePositionUpdate = async ({ node, nextParentNode }) => {
    try {
      const result = await confirm({
        title: <b>Confirm!</b>,
        message: 'Do you want to move this page Or duplicate it?',
        confirmText: 'Duplicate',
        confirmColor: 'success',
        cancelColor: 'btn btn-info',
        cancelText: 'Move',
      });

      if (result) {
        const sendParams = {
          id: node.id,
          site_id: siteId,
          instance_id: instanceId,
          parent_id: nextParentNode.id,
          copy_child_pages: true,
        };
        const res = await duplicatePage(sendParams);
        if (res) {
          const { data: { messages } } = res;
          displaySuccess(messages);
          loadPageTreeData();
        }
      } else {
        const data = getSiteTree([nextParentNode]);
        const params = {
          pages: data,
          site_id: siteId,
          home_page: data[0].id,
          instance_id: instanceId,
        };
        const response = await Page.pageUpdate(params, node.id);
        if (response) {
          displaySuccess('Position updated successfully');
        }
      }
    } catch (error) {
      if (error) {
        displayError(`${error?.name}: ${error?.message}`);
      }
    }
  };

  const getStateObj = (rowType) => {
    let stateName;
    let stateData;

    switch (rowType) {
      case 'orphanPages':
        stateName = 'orphanPages';
        stateData = orphanPages;
        break;
      case 'masterPages':
        stateName = 'masterPages';
        stateData = masterPages;
        break;
      default:
        stateName = 'pageList';
        stateData = pageList;
        break;
    }
    return { stateName, stateData };
  };

  const setPageData = (pageType, data) => {
    switch (pageType) {
      case 'orphanPages':
        setOrphanPages(data);
        break;
      case 'masterPages':
        setMasterPages(data);
        break;
      default: setPageList(data);
        break;
    }
  };

  const editPage = (page) => {
    const { id } = page.page_version;
    navigate(getPageEditUrl(id, page.id, 'web', props));
  };

  const handleExpandCollaspe = async (node) => {
    try {
      const params = {
        expanded: !node.expanded,
        site_id: siteId,
        instance_id: instanceId,
      };
      const response = await Page.pageUpdate(params, node.id);
      if (undefined !== response) {
        await response.data;
      }
    } catch (error) {
      if (error) {
        displayError(`${error?.name}: ${error?.message}`);
      }
    }
  };

  const onSetModal = async (page) => {
    if (page?.page_type === 'master') {

      const sendParams = {
        id: page.id,
        site_id: siteId,
        instance_id: instanceId,
        copy_child_pages: false,
      };

      const res = await duplicatePage(sendParams);
      if (res) {
        const { data: { messages } } = res;
        loadPageTreeData();
        displaySuccess(messages);
      }
    } else {
      setModal(!modal);
      setPageToDuplicate(page);
    }
  };

  const onCloseModal = () => {
    setModal(!modal);
  };

  const onSuccessDuplicatePage = () => {
    loadPageTreeData();
  };

  const getPageVersionObj = (arr, id, value, keyName) => {
    arr.forEach((element, index) => {
      if (('page_version' in element && element.page_version.id === id) || (element.id === id)) {
        switch (keyName) {
          case 'publish_draft_version':
            arr[index].page_version.is_published = value;
            break;
          case 'template_id':
            arr[index].page_version.template_id = value;
            arr[index].page_version.master_page_id = null; // Can't have both a template and a master page
            break;
          case 'master_page_id':
            arr[index].page_version.master_page_id = value;
            arr[index].page_version.template_id = null; // Can't have both a template and a master page
            break;
          case 'name':
            arr[index].page_version.name = value;
            break;
          case 'page_title':
            arr[index].page_version.page_title = value;
            break;
          default:
            arr[index][keyName] = value;
            break;
        }
      }

      if ('children' in element) {
        getPageVersionObj(element.children, id, value, keyName);
      }
    });
    return arr;
  };

  const onHandleChangeBulkEdit = (e, actionType, page, rowType) => {
    const tempBulkEditData = { ...bulkEditData };
    const { stateName, stateData } = getStateObj(rowType);
    tempBulkEditData.data[page.id] ||= {};
    tempBulkEditData.data[page.id][actionType] = e.target.value;
    const res = getPageVersionObj(stateData, page.page_version.id, e.target.value, actionType);
    setPageData(stateName, res);
    setBulkEditData(tempBulkEditData);
  };

  const regenerateURL = (page, rowType) => {
    const pageTitle = page.page_version.page_title;
    const url = generateURL(pageTitle);
    onHandleChangeBulkEdit({ target: { value: url } }, 'url', page, rowType);
  };

  const generateNodeProps = (rowInfo, rowType) => {
    let masterPages = [];
    if (rowInfo.node.page_type !== 'master' && masterPages) {
      masterPages = map(masterPages, (option) => ({ value: option.id, label: option.name, type: 'master' }));
    }

    return {
      onHandleChangeBulkEdit: (e, actionType, page, paramName) => onHandleChangeBulkEdit(
        e,
        actionType,
        page,
        paramName,
        rowType,
      ),
      bulkErrors,
      masterPages,
      buttons: [
        <PageOption
          page={rowInfo}
          instanceId={instanceId}
          onSetModal={onSetModal}
        />,
      ],
      onClick: (e) => {
        const targetButton = e.target;
        const buttonType = targetButton.getAttribute('type') === 'button';
        const expandBtn = targetButton.getAttribute('class') === 'rst__expandButton';
        const collapseBtn = targetButton.getAttribute('class') === 'rst__collapseButton';
        const rowTitle = targetButton.getAttribute('class').match('rst__rowTitle');
        const rowLabel = targetButton.getAttribute('class').match('rst__rowLabel');
        const rowContents = targetButton.getAttribute('class').match('rst__rowContents');
        if (buttonType && (expandBtn || collapseBtn)) {
          handleExpandCollaspe(rowInfo.node);
        } else if (rowTitle || rowLabel || rowContents) {
          editPage(rowInfo.node);
        }
      },
    };
  };

  const onHandleSubmit = async () => {
    const sendParams = { ...bulkEditData };

    try {
      const result = await confirm({
        title: <b>Confirm!</b>,
        message: 'Are you sure you want to apply these changes?',
        confirmText: 'Yes',
        confirmColor: 'success',
        cancelColor: 'btn btn-danger',
        cancelText: 'No',
      });

      if (result) {
        if (bulkEditData.type === 'delete_page') {
          sendParams.data = map(bulkEditData.data, ({ delete_page, ...rest }) => rest);
        }
        const bulkEditResponse = await Page.pageBulkEdit(sendParams);
        if (undefined !== bulkEditResponse) {
          const { data: { data } } = bulkEditResponse;
          if (bulkEditData.type === 'publish_draft_version') {
            setProgressJobId(data.job_id);
          } else if (data.errors && data.errors.length > 0) {
            setBulkErrors(data.errors);
          } else {
            setBulkErrors([]);
            loadPageTreeData();
          }
        }
      }
    } catch (error) {
      if (error) {
        displayError(`${error?.name}: ${error?.message}`);
      }
    }
  };

  const getErrorMessage = (id) => {
    // eslint-disable-next-line eqeqeq
    return bulkErrors.filter((ele) => ele.id == id);
  };

  const onHandleTooltip = (e, id) => {
    const val = e.type === 'mouseover' ? id : null;
    setTooltipId(val);
  };

  const bulkEditing = (pages, rowType) => {

    if (!myRefs.current.find((i) => i[0] === rowType)) {
      myRefs.current.push([rowType, createRef()]);
    }

    // Figure out the maximum tree depth so we can add padding to the bulk editing container
    let depth = 0;
    if (site && pages[0] && pages[0].id === site.home_page_id) {
      const getDepth = (nodes, thisDepth) => {
        let maxDepth = thisDepth;
        nodes.forEach((childNode) => {
          if (!childNode.expanded) {
            return;
          }
          if ('children' in childNode && childNode.children.length > 0) {
            const childDepth = getDepth(childNode.children, thisDepth + 1);
            if (childDepth > maxDepth) {
              maxDepth = childDepth;
            }
          }
        });
        return maxDepth;
      };
      depth = getDepth(pages[0].children, 1);
    }

    const bulkEditingRows = (data) => map(data, (node) => {
      const isHomePage = site && node.id === site.home_page_id;
      const selectedTemplate = node.page_version.template_id
        ? node.page_version.template_id
        : node.page_version.master_page_id;

      let masterPageOptions = [];

      if (node.page_type !== 'master' && masterPages) {
        masterPageOptions = map(masterPages, (option) => ({ value: option.id, label: option.name, type: 'master' }));
      }

      const getWebTypeTemplates = filterTemplateOption(templates, 'web');

      const templateOptions = map(getWebTypeTemplates, (template) => (
        { value: template.id, label: template.name, type: 'template' }
      ));

      return (
        <Fragment key={node.id}>
          <tr>
            <td className="bulk-editing-name">
              {!isEmpty(getErrorMessage(node.page_version.id)) && (
                <span
                  className="text-danger"
                >
                  {getErrorMessage(node.page_version.id)[0].error}
                </span>
              )}
              <Input
                value={node.page_version.name || ''}
                onChange={(e) => onHandleChangeBulkEdit(e, 'name', node, rowType)}
              />
            </td>

            <td className="bulk-editing-page_title">
              {!isEmpty(getErrorMessage(node.page_version.id)) && (
                <span
                  className="text-danger"
                >
                  {getErrorMessage(node.page_version.id)[0].error}
                </span>
              )}
              {node.page_type !== 'master' && (
                <InputGroup>
                  <Input
                    value={node.page_version.page_title || ''}
                    onChange={(e) => {
                      onHandleChangeBulkEdit(
                        e,
                        'page_title',
                        node,
                        rowType,
                      );

                      changedPageTitle.push(node.id);
                      setChangedPageTitle(changedPageTitle);

                      // this.setState({ changedPageTitle });
                    }}
                  />
                  {!isHomePage && changedPageTitle.indexOf(node.id) > -1 && (
                    <>
                      <InputGroupText
                        id={`regenerateURL_${node.id}`}
                        style={{ cursor: 'pointer' }}
                        onClick={() => regenerateURL(node, rowType)}
                        onMouseOver={(e) => onHandleTooltip(e, node.id)}
                        onMouseOut={(e) => onHandleTooltip(e, node.id)}
                      >
                        <i className="fal fa-arrow-right" aria-hidden="true" />

                      </InputGroupText>
                      <Tooltip placement="top" isOpen={node.id === tooltipId} target={`regenerateURL_${node.id}`}>
                        Regenerate URL from page title
                      </Tooltip>
                    </>
                  )}
                </InputGroup>
              )}
            </td>

            <td className="bulk-editing-url">
              {!isEmpty(getErrorMessage(node.id)) && (
                <span
                  className="text-danger"
                >
                  {getErrorMessage(node.id)[0].error}
                </span>
              )}
              /
              {isHomePage ? (
                <div />
              ) : (
                <Input
                  style={{ display: 'inline' }}
                  value={node.url || ''}
                  onChange={(e) => onHandleChangeBulkEdit(e, 'url', node, rowType)}
                />
              )}
            </td>

            <td className="bulk-editing-set_template_master_page">
              {!isEmpty(getErrorMessage(node.page_version.id)) && (
                <span
                  className="text-danger"
                >
                  {getErrorMessage(node.page_version.id)[0].error}
                </span>
              )}
              <CustomSelect
                value={getDefaultTemplate(selectedTemplate, masterPageOptions, templateOptions)}
                placeholder="Please select template"
                onChange={(e) => onHandleChangeBulkEdit(
                  { target: { value: e.value } },
                  e.type === 'master' ? 'master_page_id' : 'template_id',
                  node,
                  rowType,
                )}
                options={getTemplateOptions('web', masterPageOptions, templateOptions)}
                formatGroupLabel={formatGroupLabel}
              />
            </td>

            <td className="bulk-editing-publish_draft_version">
              {!isEmpty(getErrorMessage(node.page_version.id)) && (
                <span
                  className="text-danger"
                >
                  {getErrorMessage(node.page_version.id)[0].error}
                </span>
              )}
              {node.page_version.id !== node.published_version_id && (
                <Input
                  type="checkbox"
                  checked={node.page_version.is_published}
                  onChange={(e) => onHandleChangeBulkEdit(
                    { target: { value: e.target.checked } },
                    'publish_draft_version',
                    node,
                    rowType,
                  )}
                />
              )}
            </td>

            <td className="bulk-editing-set_online_offline">
              {!isEmpty(getErrorMessage(node.id)) && (
                <span
                  className="text-danger"
                >
                  {getErrorMessage(node.id)[0].error}
                </span>
              )}
              <Input
                type="checkbox"
                checked={!node.is_offline}
                onChange={(e) => onHandleChangeBulkEdit(
                  { target: { value: !e.target.checked } },
                  'is_offline',
                  node,
                  rowType,
                )}
              />
            </td>

            <td className="bulk-editing-set_in_nav">
              {!isEmpty(getErrorMessage(node.id)) && (
                <span
                  className="text-danger"
                >
                  {getErrorMessage(node.id)[0].error}
                </span>
              )}
              <Input
                type="checkbox"
                checked={node.is_in_nav}
                onChange={(e) => onHandleChangeBulkEdit(
                  { target: { value: e.target.checked } },
                  'is_in_nav',
                  node,
                  rowType,
                )}
              />
            </td>

            <td className="bulk-editing-set_in_sitemap">
              {!isEmpty(getErrorMessage(node.id)) && (
                <span
                  className="text-danger"
                >
                  {getErrorMessage(node.id)[0].error}
                </span>
              )}
              {node.page_type !== 'master' && (
                <Input
                  type="checkbox"
                  checked={node.is_in_sitemap}
                  onChange={(e) => onHandleChangeBulkEdit(
                    { target: { value: e.target.checked } },
                    'is_in_sitemap',
                    node,
                    rowType,
                  )}
                />
              )}
            </td>

            <td className="bulk-editing-set_indexable">
              {!isEmpty(getErrorMessage(node.id)) && (
                <span
                  className="text-danger"
                >
                  {getErrorMessage(node.id)[0].error}
                </span>
              )}
              {node.page_type !== 'master' && (
                <Input
                  type="checkbox"
                  checked={node.is_indexable}
                  onChange={(e) => onHandleChangeBulkEdit(
                    { target: { value: e.target.checked } },
                    'is_indexable',
                    node,
                    rowType,
                  )}
                />
              )}
            </td>

            {site && site.enable_instant_contact_form && (
              <td className="bulk-editing-instant_contact_form">
                {!isEmpty(getErrorMessage(node.id)) && (
                  <span
                    className="text-danger"
                  >
                    {getErrorMessage(node.id)[0].error}
                  </span>
                )}
                {node.page_type !== 'master' && (
                  <Input
                    type="checkbox"
                    checked={node.instant_contact_form}
                    onChange={(e) => onHandleChangeBulkEdit(
                      { target: { value: e.target.checked } },
                      'instant_contact_form',
                      node,
                      rowType,
                    )}
                  />
                )}
              </td>
            )}

            <td className="bulk-editing-back_to_top">
              {!isEmpty(getErrorMessage(node.id)) && (
                <span
                  className="text-danger"
                >
                  {getErrorMessage(node.id)[0].error}
                </span>
              )}
              {node.page_type !== 'master' && (
                <Input
                  type="checkbox"
                  checked={node.back_to_top}
                  onChange={(e) => onHandleChangeBulkEdit(
                    { target: { value: e.target.checked } },
                    'back_to_top',
                    node,
                    rowType,
                  )}
                />
              )}
            </td>

            <td className="bulk-editing-display_review_widget">
              {!isEmpty(getErrorMessage(node.page_version.id)) && (
                <span
                  className="text-danger"
                >
                  {getErrorMessage(node.page_version.id)[0].error}
                </span>
              )}
              <Label className="bulk-editing-display_review_widget">
                <Input
                  type="checkbox"
                  checked={node.display_review_widget || false}
                  onChange={() => onHandleChangeBulkEdit(
                    { target: { value: !node.display_review_widget } },
                    'display_review_widget',
                    node,
                    rowType,
                  )}
                />
              </Label>
            </td>

            <td className="bulk-editing-delete_page">
              {!isEmpty(getErrorMessage(node.page_version.id)) && (
                <span
                  className="text-danger"
                >
                  {getErrorMessage(node.page_version.id)[0].error}
                </span>
              )}
              <Label className="bulk-editing-delete_page">
                <Input
                  type="checkbox"
                  checked={node.delete_page || false}
                  onChange={() => onHandleChangeBulkEdit(
                    { target: { value: !node.delete_page } },
                    'delete_page',
                    node,
                    rowType,
                  )}
                />
              </Label>
            </td>
          </tr>
          {'children' in node && node.expanded && bulkEditingRows(node.children)}
        </Fragment>
      );
    });

    return (
      <div className={`bulk-editing-container bulk-editing-padder-${depth}`}>
        <table className="bulk-editing">
          <thead ref={myRefs.current.find((i) => i[0] === rowType)[1]}>
            <tr>
              <th>Page Name</th>
              <th>Page Title</th>
              <th>Page URL</th>
              <th>Template/Master Page</th>
              <th>Publish Draft Version?</th>
              <th>Online</th>
              <th>In Navigation</th>
              <th>In Sitemap</th>
              <th>Indexable</th>
              {site && site.enable_instant_contact_form && (
                <th>Instant Contact Form</th>
              )}
              <th>Back-To-Top Button</th>
              <th>Review Widget</th>
              <th>Delete Page</th>
            </tr>
          </thead>
          <tbody>
            {bulkEditingRows(pages)}
          </tbody>
        </table>
      </div>
    );
  };

  const onHandleAddNewPage = async (type) => {
    const sendParams = { instance_id: instanceId, site_id: siteId, type };
    await addNewPage(sendParams);
  };

  if (!pageList || !orphanPages || !masterPages) {
    return <></>;
  }

  return (
    <>
      {!isEmpty(progressJobId) && (
        <Card className="mb-4">
          <CardBody className="text-center">
            <CardTitle>
              Publish Draft Version Job ID:
              <b>{` ${progressJobId}`}</b>
            </CardTitle>
            <div className="button-sm d-inline-block">
              <CircularProgressbar
                value={isEmpty(progressObj) ? percentage && percentage.toFixed(0) : 100}
                text={`${isEmpty(progressObj) ? percentage && percentage.toFixed(0) : 100}%`}
              />
            </div>
          </CardBody>
        </Card>
      )}
      <Card className="mb-4">
        <CardBody>
          <CardTitle>Site Tree</CardTitle>
          <SortableTree
            treeData={pageList}
            isVirtualized={false}
            canDrop={canDrop}
            onChange={handleTreeOnChange}
            onMoveNode={nodePositionUpdate}
            nodeContentRenderer={CustomNodeContentRenderer}
            // toggleChildrenVisibility={toggleChildrenVisibility}
            generateNodeProps={(e) => generateNodeProps(e, 'pageList')}
          />
          {bulkEditing(pageList, 'pageList')}
        </CardBody>
      </Card>

      <Card className="mb-4">
        <CardBody>
          <CardTitle>Orphan Pages</CardTitle>
          <SortableTree
            canDrag={false}
            treeData={orphanPages}
            isVirtualized={false}
            className="no-structure"
            scaffoldBlockPxWidth={10}
            onChange={(e) => e.preventDefault()}
            nodeContentRenderer={CustomNodeContentRenderer}
            generateNodeProps={(e) => generateNodeProps(e, 'orphanPages')}
          />
          {bulkEditing(orphanPages, 'orphanPages')}
          <div className="text-center mt-4">
            <CustomButton
              color="success"
              className="common-success-button button-md fw-bold"
              title="Add Orphan Page"
              onClick={() => onHandleAddNewPage('normal')}
            />
          </div>
        </CardBody>
      </Card>

      <Card className="mb-4">
        <CardBody>
          <CardTitle>Master Pages</CardTitle>
          <SortableTree
            canDrag={false}
            treeData={masterPages}
            isVirtualized={false}
            className="no-structure"
            scaffoldBlockPxWidth={10}
            onChange={(e) => e.preventDefault()}
            nodeContentRenderer={CustomNodeContentRenderer}
            generateNodeProps={(e) => generateNodeProps(e, 'masterPages')}
          />
          {bulkEditing(masterPages, 'masterPages')}
          <div className="text-center mt-4">
            <CustomButton
              color="success"
              className="common-success-button button-md fw-bold"
              title="Add Master Page"
              onClick={() => onHandleAddNewPage('master')}
            />
          </div>
        </CardBody>
      </Card>

      {modal && (
        <DuplicatePageModal
          setModal={setModal}
          page={pageToDuplicate}
          siteId={siteId}
          toggle={onCloseModal}
          parentId={pageToDuplicate?.parent_id}
          onSuccessDuplicatePage={onSuccessDuplicatePage}
        />
      )}
      {!isEmpty(bulkEditData.data) && (
        <BottomActionToolbar
          component={(
            <CustomButton
              color="success"
              className="common-success-button button-md fw-bold"
              title="SAVE"
              onClick={onHandleSubmit}
            />
          )}
        />
      )}
    </>
  );
}
export default withRouter(SiteTree);
