import React, { useEffect } from 'react';
import { Button, Row, Col } from 'reactstrap';
import withRouter from '../../helpers/withRouter';
import { setPageTitle } from '../../../../Utils';
import { addRecentItem } from 'UtilsTS';
import HeaderComponent from '../../components/common/header-component';
import { dataCenter, settingRoutes, replacePathParams } from '../../constants/routes';
import { useInstance } from '../../hooks';

function DataCenterHome(props) {
  const { navigate } = props;

  const instance = useInstance();

  useEffect(() => {
    setPageTitle('Settings');
  });

  if (instance) {
    addRecentItem({
      instance_id: instance.id,
      type: 'data-center',
    });
  }

  const redirectToListing = (path) => {
    navigate(replacePathParams(path, [], props));
  };

  const staticBreadcrumbData = [
    {
      name: 'Dashboard',
      url: replacePathParams(settingRoutes.dashboard, [], props),
    },
    { name: 'Data Centre', url: '' },
  ];

  return (
    <>
      <HeaderComponent
        setPath={{
          addNewPath: '',
          addNewBtnName: '',
          staticBreadcrumbData,
          showBreadcrumb: false,
          backBtnName: 'Dashboard',
          headingName: 'Data Centre',
          backToPath: replacePathParams(settingRoutes.dashboard, [], props),
        }}
      />
      <Row className="settings-menu-outer">
        <Col className="col-xs-12 col-sm-6 col-md-6 col-lg-3 mb-4">
          <Button onClick={() => redirectToListing(dataCenter.addMember)}>
            <i className="fal fa-user" aria-hidden="true" />
            <p>Add Member</p>
          </Button>
        </Col>
        <Col className="col-xs-12 col-sm-6 col-md-6 col-lg-3 mb-4">
          <Button onClick={() => redirectToListing(dataCenter.memberSearch)}>
            <i className="fal fa-users" aria-hidden="true" />
            <p>Member Search</p>
          </Button>
        </Col>
        <Col className="col-xs-12 col-sm-6 col-md-6 col-lg-3 mb-4">
          <Button onClick={() => redirectToListing(dataCenter.savedSearch)}>
            <i className="fal fa-search" aria-hidden="true" />
            <p>Saved Searches</p>
          </Button>
        </Col>
        <Col className="col-xs-12 col-sm-6 col-md-6 col-lg-3 mb-4">
          <Button onClick={() => redirectToListing(dataCenter.databaseFields)}>
            <i className="fal fa-database" aria-hidden="true" />
            <p>Database Fields</p>
          </Button>
        </Col>
        <Col className="col-xs-12 col-sm-6 col-md-6 col-lg-3 mb-4">
          <Button onClick={() => redirectToListing(dataCenter.importMembers)}>
            <i className="fal fa-file-import" aria-hidden="true" />
            <p>Import Members</p>
          </Button>
        </Col>
      </Row>
    </>
  );
}

export default withRouter(DataCenterHome);
