import client from '../../../api';

export default {
  ctaLogs: (params) => client.get(`/instances/${params.instance_id}/cta_logs`, { params }),
  ctaLogDelete: (params) => client.delete(`/instances/${params.instance_id}/cta_logs/${params.id}`),
  ctaLogAttachment: (params) => client.get(
    `/instances/${params.instance_id}/cta_logs/download_form_attachment`,
    { params },
  ),
  ctaLogSpam: (params) => client.post(`/instances/${params.instance_id}/cta_logs/${params.id}/approve`, params),
  ctaLogGet: (params) => client.get(`/instances/${params.instance_id}/cta_logs/${params.cta_log_id}`, { params }),
};
